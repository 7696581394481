import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import { drawMesh } from "./drawMesh";
export const runDetector = async (video, canvas) => {
  const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
  const detectorConfig = {
    runtime: "tfjs",
  };
  const detector = await faceLandmarksDetection.createDetector(
    model,
    detectorConfig
  );
  const analyzeReaction = (face) => {
    const keypoints = face.keypoints;
console.log(keypoints);
    // Mouth
    const mouthLeft = keypoints[61];
    const mouthRight = keypoints[291];
    const mouthTop = keypoints[13];
    const mouthBottom = keypoints[14];
    const mouthWidth = Math.abs(mouthRight.x - mouthLeft.x);
    const mouthHeight = Math.abs(mouthTop.y - mouthBottom.y);

    // Eyebrows
    const leftEyebrowUpper = keypoints[70];
    const leftEyebrowLower = keypoints[105];
    const rightEyebrowUpper = keypoints[300];
    const rightEyebrowLower = keypoints[334];
    const eyebrowHeightLeft = Math.abs(leftEyebrowLower.y - leftEyebrowUpper.y);
    const eyebrowHeightRight = Math.abs(rightEyebrowLower.y - rightEyebrowUpper.y);

    // Eyes
    const leftEyeUpper = keypoints[159];
    const leftEyeLower = keypoints[145];
    const eyeOpennessLeft = Math.abs(leftEyeLower.y - leftEyeUpper.y);

    // Reaction detection
    if (mouthHeight > mouthWidth * 0.5 && eyebrowHeightLeft > 15 && eyebrowHeightRight > 15) {
      return "Surprised"; // Mouth open, eyebrows raised
    } else if (mouthWidth > mouthHeight * 2 && eyebrowHeightLeft > 10 && eyebrowHeightRight > 10) {
      return "Happy"; // Wide smile with relaxed eyebrows
    } else if (mouthTop.y > mouthBottom.y && eyeOpennessLeft < 5) {
      return "Sad"; // Mouth corners down, eyes less open
    } else if (eyebrowHeightLeft < 5 && eyebrowHeightRight < 5 && mouthHeight < 5) {
      return "Angry"; // Eyebrows close together, mouth nearly closed
    } else {
      return "Neutral"; // Default reaction
    }
  };


  // const detect = async (net) => {

  //   const estimationConfig = { flipHorizontal: false };
  //   const faces = await net.estimateFaces(video, estimationConfig);

  //   const ctx = canvas.getContext("2d");
  //   requestAnimationFrame(() => drawMesh(faces[0], ctx));
  //   detect(detector);


  // };


  const detect = async (net) => {
    if (video.readyState === 4) {
      const estimationConfig = { flipHorizontal: false };
      const faces = await net.estimateFaces(video, estimationConfig);

      if (faces.length > 0) {
        const reaction = analyzeReaction(faces[0]);
        console.log("Reaction:", reaction);

        // Optional: Draw reaction on canvas
        const ctx = canvas.getContext("2d");
        // ctx.fillStyle = "white";
        // ctx.font = "20px Arial";
        // ctx.fillText(`Reaction: ${reaction}`, 10, 30);

        if (faces.length > 0) {
          const reaction = analyzeReaction(faces[0]);
          ctx.fillStyle = "black";
          ctx.font = "30px Arial"; // Adjusted font size
          ctx.textAlign = "left";
          
          const textWidth = ctx.measureText(`Reaction: ${reaction}`).width;
          ctx.clearRect(10, 30, textWidth, 30);
          ctx.fillText(`Reaction: ${reaction}`, 10, 50);

        
        } else {
          ctx.fillStyle = "black";
          ctx.font = "30px Arial";
          ctx.textAlign = "left";
          ctx.fillText("No face detected", 10, 50);
        }

      }
    }
    requestAnimationFrame(() => detect(net));
  };
  detect(detector);
};



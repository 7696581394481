// import React, { useEffect, useRef } from "react";
// import * as tf from "@tensorflow/tfjs";
// import Webcam from "react-webcam";

// const Fashmash = () => {
// //   const webcamRef = useRef<Webcam>(null);
// //   const canvasRef = useRef<HTMLCanvasElement>(null);

//   const detect = async (net: any) => {
//     if (
//       webcamRef.current?.video &&
//       webcamRef.current.video.readyState === 4 // Ensure webcam is fully initialized
//     ) {
//       const videoElement = webcamRef.current?.video;

//       if (!(videoElement instanceof HTMLVideoElement)) {
//         console.error("The video element is invalid.");
//         return;
//       }

//       console.log("Video object ready. Type:", videoElement?.constructor?.name);
//       console.log("Video properties:", {
//         videoWidth: videoElement?.videoWidth,
//         videoHeight: videoElement?.videoHeight,
//         readyState: videoElement?.readyState,
//       });

//       try {
//         const tensor = tf.browser.fromPixels(videoElement);

//         console.log("Tensor created:", tensor);

//         if (!tensor) {
//           console.error("Tensor is invalid.");
//           return;
//         }

//         const predictions = await net.estimateFaces({ input: tensor });
//         console.log("Predictions:", predictions);

//         const canvas = canvasRef.current;
//         if (canvas) {
//           const ctx = canvas.getContext("2d");
//           if (ctx) {
//             ctx.clearRect(0, 0, canvas.width, canvas.height);
//             predictions.forEach((prediction: any) => {
//               const [x, y, width, height] = prediction.boundingBox;

//               ctx.beginPath();
//               ctx.rect(x, y, width, height);
//               ctx.strokeStyle = "red";
//               ctx.lineWidth = 2;
//               ctx.stroke();
//             });
//           }
//         }

//         tensor.dispose(); // Clean up tensor to avoid memory leaks
//       } catch (error) {
//         console.error("Error running TensorFlow processing.", error);
//       }
//     } else {
//       console.error("Webcam or video not ready.");
//     }
//   };

//   useEffect(() => {
//     const loadModelAndStartDetection = async () => {
//       try {
//         const net = await tf.loadGraphModel(
//           "https://cdn.jsdelivr.net/npm/@tensorflow-models/facemesh"
//         );

//         setInterval(() => detect(net), 100);
//       } catch (error) {
//         console.error("Error loading TensorFlow model:", error);
//       }
//     };

//     loadModelAndStartDetection();
//   }, []);

//   return (
//     <div>
//       {/* Webcam element */}
//       <Webcam
//         ref={webcamRef}
//         style={{
//           position: "absolute",
//           zIndex: 1,
//         }}
//         audio={false}
//       />

//       {/* Canvas for rendering detections */}
//       <canvas
//         ref={canvasRef}
//         style={{
//           position: "absolute",
//           zIndex: 2,
//         }}
//       />
//     </div>
//   );
// };

// export default Fashmash;
import React, { useRef, useState } from "react";
import "@tensorflow/tfjs";
// Register WebGL backend.
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import Webcam from "react-webcam";
import { runDetector } from "./detector";

const inputResolution = {
  width: 800,
  height: 600,
};
const videoConstraints = {
  width: inputResolution.width,
  height: inputResolution.height,
  facingMode: "user",
};
function AIFace() {
  const canvasRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [reaction, setReaction] = useState<string>('');

  const handleVideoLoad = (videoNode) => {
    const video = videoNode.target;
    if (video.readyState !== 4) return;
    if (loaded) return;
    runDetector(video, canvasRef.current);
    setLoaded(true);
  };
  return (
    <div>
      <Webcam
        width={inputResolution.width}
        height={inputResolution.height}
        style={{  position: "absolute" }}
        videoConstraints={videoConstraints}
        onLoadedData={handleVideoLoad}
      />
      <canvas
        ref={canvasRef}
        width={inputResolution.width}
        height={inputResolution.height}
        style={{ position: "absolute" }}
      />
      {loaded ? <></> : <header>Loading...</header>}
    </div>
  );
}

export default AIFace;

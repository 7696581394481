//import { TabPanelProps } from "@mui/lab";
import { Box, Button, Card, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import apiService from "src/services/api-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faM, faMinus, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import { evaluate } from "mathjs";
import { FaPencil } from "react-icons/fa6";
import SearchIcon from '@mui/icons-material/Search';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Data {
    id: number;
    empid: number;
    empno: string;
    title: string;
    name: string;
    firstname: string;
    middlename: string;
    lastname: string;
    fhname: string;
    dob: Date;
    gender: string;
    ismarital: number;
    Pmobile: string;
    Pemail: string;
    Omobile: string;
    Oemail: string;
    add1: string;
    add2: string;
    add3: string;
    add4: string;
    add5: string;
    cadd1: string;
    cadd2: string;
    cadd3: string;
    cadd4: string;
    cadd5: string;
    img: string;
    isactive: number;
    iscurremp: number;
    isdriverlicense: number;
    isdriverlicenseno: string;
    dowillingtravel: string;
    isphysicaldisability: number;
    aremajorsick: string;
    howhearjob: string;
    isrefer: number;
    referid: number;
    refername: string;
    referadd1: string;
    referadd2: string;
    totalexp: number;
    compid: number;
    plantid: number;
    designid: number;
    deptid: number;
    doj: Date;
    shiftid: number;
    workcatgid: number;
    readerid: string;
    revdate: Date;
    salconfigid: number;
    T: string;
    totalsalary: string;
    education: Education[];
    experience: Experience[];
    languageprof: LanguageProf[];
    family: Family[];
    salaryDetails: SalaryDet[];
}
interface City {
    id: number;
    sname: string;
    name: string;
}

interface Dist {
    id: number;
    name: string;
    stateid: number;
}

interface State {
    id: number;
    name: string;
}

interface Qualification {
    id: number;
    name: string;
}

interface Education {
    id: number;
    empid: number;
    qualid: number;
    dueyear: string;
    institute: string;
    edyearfrom: string;
    edyearto: string;
    totalmarks: string;
    totalper: string;
    documents: string;
}

interface Experience {
    id: number;
    empid: number;
    company: string;
    design: string;
    exyearto: string;
    exyearfrom: string;
    natureofwork: string;
    documents: string;
}

interface LanguageProf {
    id: number;
    empid: number;
    lanid: number;
    isread: number;
    iswrite: number;
    isspeak: number;
    documents: string;
}

interface Language {
    id: number;
    name: string;
}

interface Family {
    empid: number;
    name: string;
    relation: string;
    age: number;
    workingstatus: string;
}

interface Employee {
    id: number;
    name: string;
}

interface Company {
    id: number;
    name: string;
}

interface Plant {
    id: number;
    name: string;
}

interface Designation {
    id: number;
    name: string;
}

interface Department {
    id: number;
    name: string;
}

interface WorkCat {
    id: number;
    name: string;
}

interface Shift {
    id: number;
    name: string;
}

interface SalaryConfig {
    id: number;
    name: string;
}

interface SalaryDet {
    empid: number;
    configid: number;
    type: string;
    headid: number;
    value: number;
    formula: string;
    name: string;
    T: string;
    earnings: Earnings[];
    deductions: Deductions[];
    amount: number;
}

interface Earnings {
    id: number;
    name: string;
    amount: string;
}

interface Deductions {
    id: number;
    name: string;
    amount: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const EmployeeRegister: React.FC = () => {
    const [value, setValue] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentEntry, setCurrentEntry] = useState(null);
    const [city, setCity] = useState<City[]>([]);
    const [dist, setDist] = useState<Dist[]>([]);
    const [dist1, setDist1] = useState<Dist[]>([]);
    const [states1, setStates1] = useState<State[]>([]);
    const [states, setStates] = useState<State[]>([]);
    const [qualification, setQualification] = useState<Qualification[]>([]);
    const [language, setLanguage] = useState<Language[]>([]);
    const [employee, setEmployee] = useState<Employee[]>([]);
    const [company, setCompany] = useState<Company[]>([]);
    const [plant, setPlant] = useState<Plant[]>([]);
    const [designation, setDesignation] = useState<Designation[]>([]);
    const [department, setDepartment] = useState<Department[]>([]);
    const [workcat, setworkcat] = useState<WorkCat[]>([]);
    const [shift, setshift] = useState<Shift[]>([]);
    const [salaryconfig, setSalaryConfig] = useState<SalaryConfig[]>([]);
    const [rows1, setRows1] = useState([{}]); // Ensure it's not undefined.
    const [rows2, setRows2] = useState([{}]);
    const [rows3, setRows3] = useState([{}]);
    const [rows4, setRows4] = useState([{}]);
    const [isExpanded1edu, setIsExpanded1edu] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);
    const [isExpanded4, setIsExpanded4] = useState(false);
    const [selectedEarnings, setSelectedEarnings] = useState<any[]>([]); // To track selected earnings rows
    const [selectedDeductions, setSelectedDeductions] = useState<any[]>([]); // To track selected deductions rows
    const [selectedRowdet, setSelectedRowdet] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<Data[]>([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isMobileValid1, setIsMobileValid1] = useState(true);
    const [isEmailValid1, setIsEmailValid1] = useState(true);
    const [ispincodevalid1, setIspincodevalid1] = useState(true);
    const [isMobileValid2, setIsMobileValid2] = useState(true);
    const [isEmailValid2, setIsEmailValid2] = useState(true);
    const [ispincodevalid2, setIspincodevalid2] = useState(true);

   
    const handleAddNewClick = () => {
        setFormData({}); // Reset the form for new employee
        setIsFormVisible(true); // Show the form
    };

    const handleBackToList = () => {
        setIsFormVisible(false);
        setIspincodevalid2(true);
        setIsMobileValid1(true);
        setIsEmailValid1(true);
        setErrors(null);
        setIspincodevalid2(true);
        setIsMobileValid2(true);
        setIsEmailValid2(true);
        setErrors(null);

    };

    const handleEditClick = (employee) => {
        setFormData(employee); // Populate the form with the selected employee's data
        setIsFormVisible(true); // Show the form
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const togglePanel1 = () => {
        setIsExpanded1edu(prevState => !prevState);
    };

    const togglePanel2 = () => {
        setIsExpanded2(prevState => !prevState);
    };

    const togglePanel3 = () => {
        setIsExpanded3(prevState => !prevState);
    };

    const togglePanel4 = () => {
        setIsExpanded4(prevState => !prevState);
    };


    const [formData, setFormData] = useState<any>({
        id: 0,
        empid: 0,
        empno: '',
        title: '',
        name: '',
        firstname: '',
        middlename: '',
        lastname: '',
        fhname: '',
        dob: null,
        gender: '',
        ismarital: null,
        Pmobile: '',
        Pemail: '',
        Omobile: '',
        Oemail: '',
        add1: '',
        add2: '',
        add3: '',
        add4: '',
        add5: '',
        cadd1: '',
        cadd2: '',
        cadd3: '',
        cadd4: '',
        cadd5: '',
        img: '',
        isactive: 1,
        iscurremp: null,
        isdriverlicense: null,
        isdriverlicenseno: '',
        dowillingtravel: '',
        isphysicaldisability: null,
        aremajorsick: '',
        howhearjob: '',
        isrefer: null,
        referid: null,
        refername: '',
        referadd1: '',
        referadd2: '',
        totalexp: null,
        compid: 0,
        plantid: 0,
        designid: 0,
        deptid: 0,
        doj: null,
        shiftid: 0,
        workcatgid: 0,
        readerid: '',
        education: [
            {
                empid: 0,
                qualid: 0,
                dueyear: '',
                institute: '',
                edyearfrom: '',
                edyearto: '',
                totalmarks: '',
                totalper: '',
                documents: ''
            }
        ],
        experience: [
            {
                empid: 0,
                company: '',
                design: '',
                exyearto: '',
                exyearfrom: '',
                natureofwork: '',
                documents: ''
            }
        ],
        languageprof: [
            {
                empid: 0,
                lanid: 0,
                isread: '',
                isspeak: '',
                iswrite: '',
                documents: ''
            }
        ],
        family: [
            {
                empid: 0,
                name: '',
                relation: '',
                age: '',
                workingstatus: '',
            }
        ],
        salaryDetails: [{}]
    })

    const [salarydetData, setSalaryDetData] = useState<any[]>([]); // Initialize as an empty array


    const handleChange = (event: SyntheticEvent, newValue: number) => {
        // if (!validateForm()) {
        //   console.log("validattttt");
        //   return;
        // } else {
        //   console.log("NEXTTTTTTTTT");
        setValue(newValue);
        //}
    }

    useEffect(() => {
        apiService.getEmp(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err);
        });
    }, [rowsPerPage, page, formData, search]); // Empty array ensures this only runs once

    useEffect(() => {
        if (formData.id != null) {
            apiService.getEducation(Number(formData.id))
                .then(response => {
                    console.log("Fetched edu list data:", response.data);

                    // Update only the education field in formData
                    setFormData(prevFormData => ({
                        ...prevFormData, // Spread the existing formData
                        education: response.data // Update the education field with fetched data
                    }));
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });
        }

        if (formData.id != null) {
            apiService.getExperience(Number(formData.id))
                .then(response => {
                    console.log("Fetched edu list data:", response.data);

                    // Update only the education field in formData
                    setFormData(prevFormData => ({
                        ...prevFormData, // Spread the existing formData
                        experience: response.data // Update the education field with fetched data
                    }));
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });
        }

        if (formData.id != null) {
            apiService.getLanguageProf(Number(formData.id))
                .then(response => {
                    console.log("Fetched edu list data:", response.data);

                    // Update only the education field in formData
                    setFormData(prevFormData => ({
                        ...prevFormData, // Spread the existing formData
                        languageprof: response.data // Update the education field with fetched data
                    }));
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });
        }

        if (formData.id != null) {
            apiService.getFamily(Number(formData.id))
                .then(response => {
                    console.log("Fetched edu list data:", response.data);

                    // Update only the education field in formData
                    setFormData(prevFormData => ({
                        ...prevFormData, // Spread the existing formData
                        family: response.data // Update the education field with fetched data
                    }));
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });
        }

        if (formData.id != null) {
            apiService.getsalaryamount(Number(formData.id))
                .then(response => {
                    console.log("Fetched edu list data:", response.data);

                    // Update only the education field in formData
                    setFormData(prevFormData => ({
                        ...prevFormData, // Spread the existing formData
                        salaryDetails: response.data // Update the education field with fetched data
                    }));
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });
        }

        apiService.getDropdown("mas_state")
            .then(response => {
                setStates(response.data);
                setStates1(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("hrms_mas_qualification")
            .then(response => {
                setQualification(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("hrms_mas_language")
            .then(response => {
                setLanguage(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("mas_emp")
            .then(response => {
                setEmployee(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("mas_company")
            .then(response => {
                setCompany(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("mas_dept")
            .then(response => {
                setDepartment(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("mas_design")
            .then(response => {
                setDesignation(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("hrms_mas_shift")
            .then(response => {
                setshift(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("hrms_mas_workcatg")
            .then(response => {
                setworkcat(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

        apiService.getDropdown("hrms_ent_salaryconfig")
            .then(response => {
                setSalaryConfig(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });
    }, [formData.id])


    useEffect(() => {
        if (formData.img) {
            setSelectedImage(formData.img); // Set the stored image URL
        }

        if (currentEntry) {
            setSelectedImage(currentEntry.imageUrl || null); // Set new image or clear
        }
    }, [formData.img, currentEntry])

    const isInsert = !formData?.id;
    const validateForm = (isInsert: boolean) => {
        const requiredFields = [
            'firstname'
        ];

        let valid = true;
        const newErrors: { [key: string]: string } = {};

        requiredFields.forEach((field) => {
            // Only validate all required fields during insert, or validate only if the field is being updated
            if (isInsert || formData[field] !== undefined) {
                console.log(field, formData[field], "validate");
                if (field === "isactive") {
                    if (formData[field] === '') {
                        newErrors[field] = `${field} is isactive`;
                        valid = false;
                    }
                }
                else {
                    if (!formData[field]) {
                        newErrors[field] = `${field} is required`;
                        valid = false;
                    }
                }

            }
        });

        setErrors(newErrors);
        return valid;
    };

    const validateOMobile = (Omobile: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const omobileRegex = /^[0-9]{10}$/;
        return omobileRegex.test(Omobile);
    };
    const validateOEmail = (Oemail: string) => {
        const oemailRegex = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io|co.in)$/;
        return oemailRegex.test(Oemail);
    };

    const validatePMobile = (Pmobile: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const pmobileRegex = /^[0-9]{10}$/;
        return pmobileRegex.test(Pmobile);
    };
    const validatePEmail = (Pemail: string) => {
        const pemailRegex = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io|co.in)$/;
        return pemailRegex.test(Pemail);
    };
    const validatePincode1 = (add5: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const pincodeRegex1 = /^[0-9]{6}$/;
        return pincodeRegex1.test(add5);
    };
    const validatePincode2 = (cadd5: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const pincodeRegex2 = /^[0-9]{6}$/;
        return pincodeRegex2.test(cadd5);
    };

    const handleCalculateSal = async (e: React.FormEvent) => {
        try {
            // Fetch salary details from the API
            const response = await apiService.getSalaryDet(formData.salconfigid);
            const salaryDetails = Array.isArray(response.data) ? response.data : [];

            // Initialize constants and preloaded values
            const snameMap: Record<string, number> = {
                T: formData.T || 0, // Base salary value
                TWD: 30, // Total Working Days (preload)
                WD: 30,  // Working Days (preload)
                FDBASIC: formData.fdbasic || 0, // FDBASIC value
            };

            const substituteMap: Record<string, string> = {
                BPF: "FDBASIC", // Replace BPF with FDBASIC
                TPF: "T", // Replace TPF with T
            };

            // Process salary details row by row
            const processedRows = salaryDetails.map((detail, index) => {
                const { formula, sname, value } = detail;
                let calculatedValue = 0;

                // Handle formula-based calculation
                if (formula) {
                    try {
                        let evaluatedFormula = formula;

                        // Substitute aliases with their respective values
                        Object.keys(substituteMap).forEach((alias) => {
                            const regex = new RegExp(`\\b${alias}\\b`, "g");
                            evaluatedFormula = evaluatedFormula.replace(regex, substituteMap[alias]);
                        });

                        // Replace snameMap values in the formula
                        Object.keys(snameMap).forEach((key) => {
                            const regex = new RegExp(`\\b${key}\\b`, "g");
                            evaluatedFormula = evaluatedFormula.replace(regex, snameMap[key].toString());
                        });

                        // Evaluate the formula safely
                        calculatedValue = eval(evaluatedFormula);

                        // Ensure the result is valid
                        if (isNaN(calculatedValue)) {
                            console.error(`Invalid formula for row ${index}: "${formula}" resulted in NaN.`);
                            calculatedValue = 0;
                        }
                    } catch (err) {
                        console.error(`Error in formula evaluation for row ${index}:`, err);
                        calculatedValue = 0;
                    }
                } else {
                    calculatedValue = value || 0; // Use existing value if no formula
                }

                // Update the calculated value in the snameMap
                if (sname) {
                    snameMap[sname] = calculatedValue;
                }

                if (calculatedValue < 0) {
                    calculatedValue = 0;
                }

                return { ...detail, value: calculatedValue };
            });

            // Check for 'T >= 15000' and update 'PF'
            if (snameMap["T"] >= 15000) {
                console.log("T >= 15000: Setting PF to 1800");
                snameMap["PF"] = 1800;

                // Update or add PF in the processed rows
                const pfRowIndex = processedRows.findIndex((row) => row.sname === "PF");
                if (pfRowIndex !== -1) {
                    // Update existing PF row
                    processedRows[pfRowIndex].value = 1800;
                } else {
                    // Add new PF row if missing
                    processedRows.push({
                        headid: "PF",
                        sname: "PF",
                        value: 1800,
                    });
                }
            } else {
                console.log("T < 15000: PF remains unchanged.");
            }

            // Finalize state update
            setSalaryDetData(processedRows);
            console.log("Processed Salary Details:", processedRows);

            // Update the formData state with the calculated salary details
            setFormData((prevFormData) => ({
                ...prevFormData,
                salaryDetails: processedRows,
            }));

        } catch (err) {
            console.error("Error in handleCalculateSal:", err);
        }
    };


    // Fetch districts only if state IDs are available
    useEffect(() => {
        const cadd3ToFind = formData?.cadd3;
        const selectedOption1 = states.find(option => option.name === cadd3ToFind);
        const stateid = selectedOption1 ? selectedOption1.id : null;

        const nameToFind = formData?.add3;
        const selectedOption = states1.find(option => option.name === nameToFind);
        const stateid1 = selectedOption ? selectedOption.id : null;

        const plantToFind = formData?.compid;
        const selectedComp = company.find(option => option.id === plantToFind);
        const compid = selectedComp ? selectedComp.id : null;

        if (stateid !== null) {
            apiService.getDropdown("mas_district")
                .then(response => {
                    const filteredData = response.data.filter(item => item.stateid === stateid);
                    setDist(filteredData);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }

        if (stateid1 !== null) {
            apiService.getDropdown("mas_district")
                .then(response => {
                    const filteredData1 = response.data.filter(item => item.stateid === stateid1);
                    setDist1(filteredData1);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }

        if (compid !== null) {
            apiService.getDropdown("mas_plant")
                .then(response => {
                    const filteredplantData = response.data.filter(item => item.compid === compid);
                    setPlant(filteredplantData);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    }, [formData, states, states1]);

    const handleSearch = () => {
        apiService.getEmp(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err);
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log("Save button clicked");
        if (!validateForm(isInsert)) {
            toast.error('Form is invalid. Please fill all required fields.');
            return;
        }

        try {
            const empid = formData.empid; // Assuming empid is part of formData
            const revdate = formData.revdate; // Assuming revdate is part of formData
            const salconfigid = formData.salconfigid; // Assuming salconfigid is part of salaryData

            // Calculate total salary from selected earnings and deductions
            // const totalEarnings = selectedEarnings
            //     .reduce((total, item) => total + (item.value || 0), 0); // Sum only selected earnings

            // const totalDeductions = selectedDeductions
            //     .reduce((total, item) => total + (item.value || 0), 0); // Sum only selected deductions

            // const totalsalary = totalEarnings - totalDeductions; // Calculate net salary

            // Combine all data into one object for submission, include only selected earnings and deductions
            const salaryDetails = [
                ...selectedEarnings,
                ...selectedDeductions,
            ].map((detail: any) => ({
                headid: detail.headid,
                amount: detail.value || 0, // Store value as amount
            }));

            const dataToSubmit = formData;
            console.log("dataToSubmit", dataToSubmit);
            const response = await apiService.postemployeeregisterdata(dataToSubmit);
            console.log("Form submitted successfully:", response.data);
            toast.success("Employee registered successfully");
            setIsFormVisible(false);
            window.scrollTo(0, 0);

        } catch (error: any) {
            // Log different types of errors
            if (error.response) {
                console.error("Error response:", error.response);
                console.error("Error data:", error.response.data);
                console.error("Error status:", error.response.status);
                console.error("Error headers:", error.response.headers);
            } else if (error.request) {
                console.error("Error request:", error.request);
            } else {
                console.error("Error message:", error.message);
            }
            console.error("Error config:", error.config);
        }
    };


    const handleMoveToPermanent = () => {
        setFormData({
            ...formData,
            add1: formData.cadd1,
            add2: formData.cadd2,
            add3: formData.cadd3,
            add4: formData.cadd4,
            add5: formData.cadd5
        });
    };

    const handleBasicInfoChanges = (event: any) => {
        const { name, value } = event.target;

        if (name === 'Omobile') {
            setIsMobileValid1(validateOMobile(value));
        }

        if (name == 'Oemail') {
            setIsEmailValid1(validateOEmail(value));
        }

        if (name === 'add5') {
            setIspincodevalid1(validatePincode1(value));
        }
        if (name === 'Pmobile') {
            setIsMobileValid2(validatePMobile(value));
        }

        if (name == 'Pemail') {
            setIsEmailValid2(validatePEmail(value));
        }

        if (name === 'cadd5') {
            setIspincodevalid2(validatePincode2(value));
        }

        setFormData((prev) => ({
            ...prev,
            [name]: value, // Update the field by name
            ...(name === "firstname" && { name: value }), // Also update `name` if `firstname` is modified
        }));

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: value ? '' : `${name} is required`, // Reset or keep error based on value
            });
        }
    };

    const handleNestedInputChanges = (
        section: 'education' | 'experience' | 'family' | 'languageprof',
        index: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value, type, checked } = event.target as HTMLInputElement; // Assert event.target as HTMLInputElement

        // For checkboxes, set value to 1 for checked, 0 for unchecked
        const newValue = type === 'checkbox' ? (checked ? '1' : '0') : value;

        setFormData((prevFormData) => {
            const updatedSection = [...prevFormData[section]]; // Copy the specific array
            updatedSection[index] = {
                ...updatedSection[index],
                [name]: newValue, // Update the field dynamically
            };
            return { ...prevFormData, [section]: updatedSection }; // Update the section in formData
        });
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        if (name === "img" && event.target.files) {
            const file = event.target.files[0];
            const formdata = new FormData();
            formdata.append('file', file);

            if (file) {
                const imageUrl = URL.createObjectURL(file);
                setSelectedImage(imageUrl);
            }

            apiService.uploadFiles(formdata)
                .then(response => {
                    const url = response.data; // Assuming response.data contains the file URL
                    // Update the formData with the file URL
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        img: url,
                    }));
                    currentEntry.imageUrl = url;
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    };

    const handleDocs1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        if (name === "documents" && event.target.files) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            apiService.uploadFiles(formData)
                .then(response => {
                    const url = response.data; // Assuming response.data contains the file URL

                    // Update the formData with the file URL
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        education: prevFormData.education.map((edu, index) =>
                            index === 0 ? { ...edu, documents: url } : edu // Assuming you want to update the first education entry
                        )
                    }));
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    };

    const handleDocs2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        if (name === "documents" && event.target.files) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            apiService.uploadFiles(formData)
                .then(response => {
                    const url = response.data; // Assuming response.data contains the file URL

                    // Update the formData with the file URL
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        experience: prevFormData.experience.map((exp, index) =>
                            index === 0 ? { ...exp, documents: url } : exp // Assuming you want to update the first education entry
                        )
                    }));
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    };

    const handleDocs3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        if (name === "documents" && event.target.files) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            apiService.uploadFiles(formData)
                .then(response => {
                    const url = response.data; // Assuming response.data contains the file URL

                    // Update the formData with the file URL
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        languageprof: prevFormData.languageprof.map((lang, index) =>
                            index === 0 ? { ...lang, documents: url } : lang // Assuming you want to update the first education entry
                        )
                    }));
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    };

    const addRow1 = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            education: prevFormData.education ? [...prevFormData.education, { qualid: '', dueyear: '', institute: '', edyearfrom: '', edyearto: '', totalmarks: '', totalper: '' }] : [{ qualid: '', dueyear: '', institute: '', edyearfrom: '', edyearto: '', totalmarks: '', totalper: '' }],
        }));
    };


    const addRow2 = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            experience: prevFormData.experience ? [...prevFormData.experience, {
                company: '',
                design: '',
                exyearto: '',
                exyearfrom: '',
                natureofwork: '',
                documents: ''
            }] : [{
                company: '',
                design: '',
                exyearto: '',
                exyearfrom: '',
                natureofwork: '',
                documents: ''
            }],
        }));
    };

    const addRow3 = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            languageprof: prevFormData.languageprof ? [...prevFormData.languageprof,
            {
                lanid: 0,
                isread: '',
                isspeak: '',
                iswrite: '',
                documents: ''
            }] : [
                {
                    lanid: 0,
                    isread: '',
                    isspeak: '',
                    iswrite: '',
                    documents: ''
                }],
        }));
    };

    const addRow4 = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            family: prevFormData.family ? [...prevFormData.family,
            {
                empid: 0,
                name: '',
                relation: '',
                age: '',
                workingstatus: '',
            }] : [
                {
                    empid: 0,
                    name: '',
                    relation: '',
                    age: '',
                    workingstatus: '',
                }],
        }));
    };

    const deleteRow1 = (index: number) => {
        setFormData((prevFormData) => {
            const updatedEducation = prevFormData.education.filter((_, i) => i !== index);
            return { ...prevFormData, education: updatedEducation };
        });
    };


    const deleteRow2 = (index) => {
        setFormData((prevFormData) => {
            const updatedExperience = prevFormData.experience.filter((_, i) => i !== index);
            return { ...prevFormData, experience: updatedExperience };
        });
    };

    const deleteRow3 = (index) => {
        setFormData((prevFormData) => {
            const updatedLanguageProf = prevFormData.languageprof.filter((_, i) => i !== index);
            return { ...prevFormData, languageprof: updatedLanguageProf };
        });
    };

    const deleteRow4 = (index) => {
        setFormData((prevFormData) => {
            const updatedFamily = prevFormData.family.filter((_, i) => i !== index);
            return { ...prevFormData, family: updatedFamily };
        });
    };
    
    const handleEarningsChange = (event: React.ChangeEvent<HTMLInputElement>, detail: { headid: string }) => {
        if (event.target.checked) {
            // Add to selected if not already present
            setSelectedEarnings((prev) => [...prev, detail]);
        } else {
            // Remove from selected if unchecked
            setSelectedEarnings((prev) => prev.filter((item) => item.headid !== detail.headid));
        }
    };
    
    // const handleEarningsChange = (event: React.ChangeEvent<HTMLInputElement>, detail: any) => {
    //     if (event.target.checked) {
    //         setSelectedEarnings((prev) => [...prev, detail]); // Add to selected
    //     } else {
    //         setSelectedEarnings((prev) => prev.filter((item) => item.headid !== detail.headid)); // Remove from selected
    //     }
    // };
  
    const handleDeductionsChange = (event: React.ChangeEvent<HTMLInputElement>, detail: any) => {
        if (event.target.checked) {
            setSelectedDeductions((prev) => [...prev, detail]); // Add to selected
        } else {
            setSelectedDeductions((prev) => prev.filter((item) => item.headid !== detail.headid)); // Remove from selected
        }
    };

    const handleAmountChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        detail: any
    ) => {
        const newValue = parseFloat(event.target.value) || 0;

        // Update the salary details with the manually edited value
        const updatedSalaryDetails = salarydetData.map((item) =>
            item.headid === detail.headid ? { ...item, value: newValue, manual: true } : item
        );

        const valueMap: Record<string, number> = {};
        const manualOverride: Record<string, boolean> = {};

        // Populate valueMap and manualOverride
        updatedSalaryDetails.forEach((row) => {
            valueMap[row.sname] = row.value || 0; // Use current values
            manualOverride[row.sname] = !!row.manual; // Track manually edited fields
        });

        const substituteMap: Record<string, string> = {
            BPF: "FDBASIC",
            TPF: "T",
        };

        // Recalculate dependent fields
        const reprocessedRows = updatedSalaryDetails.map((row) => {
            if (row.formula && !manualOverride[row.sname]) {
                try {
                    let evaluatedFormula = row.formula;

                    // Substitute aliases in the formula
                    Object.keys(substituteMap).forEach((alias) => {
                        evaluatedFormula = evaluatedFormula.replace(
                            new RegExp(`\\b${alias}\\b`, "g"),
                            substituteMap[alias]
                        );
                    });

                    // Replace field values in the formula
                    Object.keys(valueMap).forEach((key) => {
                        evaluatedFormula = evaluatedFormula.replace(
                            new RegExp(`\\b${key}\\b`, "g"),
                            valueMap[key].toString()
                        );
                    });

                    const calculatedValue = eval(evaluatedFormula);

                    // Update valueMap for dependent calculations
                    if (!isNaN(calculatedValue)) {
                        valueMap[row.sname] = calculatedValue;
                        return { ...row, value: calculatedValue, manual: false }; // Mark as calculated
                    }
                } catch (error) {
                    console.error(`Error evaluating formula for ${row.sname}:`, error);
                }
            }

            // If T >= 15000, update PF to 1800
            if (valueMap["T"] >= 15000 && row.sname === "PF" && !manualOverride[row.sname]) {
                console.log("T >= 15000: Updating PF to 1800 due to manual input");
                valueMap["PF"] = 1800; // Update PF to 1800

                return { ...row, value: 1800, manual: false }; // Mark PF as calculated
            }

            // Return the row with its current value if no formula or manual edit
            return row;
        });

        // Update state with recalculated rows
        setSalaryDetData(reprocessedRows);
    };

    // Calculate total earnings and total deductions
    const totalEarnings = salarydetData
        .filter((detail: any) => detail.type === 'e') // Filter rows where type is 'e' (earnings)
        .reduce((total, item) => total + (item.value || 0), 0); // Sum values of earnings

    const totalDeductions = salarydetData
        .filter((detail: any) => detail.type === 'd') // Filter rows where type is 'd' (deductions)
        .reduce((total, item) => total + (item.value || 0), 0); // Sum values of deductions

    const totalsalary = totalEarnings - totalDeductions; // Calculate Net Pay


    return (
        <>
            <Helmet>
                <title>Employee Registration</title>
            </Helmet>
            <PageTitleWrapper>
                <PageTitle
                    heading="Employee Registration"
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        {isFormVisible ? (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        // alignItems: 'flex-end',
                                        mt: -3.5, // Add some margin-bottom for spacing
                                        marginTop: '-60px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <Button variant="contained" color="primary" onClick={handleBackToList}>
                                        Back
                                    </Button></Box>
                                <Card>
                                    <CardContent>
                                        <Box sx={{ width: '100%' }}>
                                            <Tabs
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                textColor="primary"
                                                indicatorColor="primary"
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="basic tabs example"
                                                sx={{
                                                    '& .MuiTab-root': {
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: 'blue', // Changes background color on hover
                                                            color: '#fff',
                                                        },
                                                    },
                                                    '& .Mui-selected': {
                                                        backgroundColor: 'Blue', // Background color for selected tab
                                                        color: '#fff', // Keep the font color unchanged
                                                    },
                                                }}
                                            >
                                                <Tab label="Basic Information" {...a11yProps(0)} />
                                                <Tab label="Additional Information" {...a11yProps(1)} />
                                                <Tab label="Work Details" {...a11yProps(2)} />
                                                <Tab label="Salary Details" {...a11yProps(3)} />
                                            </Tabs>
                                            <TabPanel value={value} index={0}>
                                                {/* <h2>{formData.empno ? 'Edit Employee' : 'Add New Employee'}</h2> */}
                                                <form>
                                                    <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Card>
                                                                <Divider />
                                                                <CardContent>
                                                                    <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '24ch' } }} noValidate autoComplete="off">
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={8}>
                                                                                {formData?.empno && (
                                                                                    <TextField
                                                                                        id="empno"
                                                                                        name="empno"
                                                                                        value={formData?.empno}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Employee No"
                                                                                        fullWidth
                                                                                    />
                                                                                )}
                                                                                <Box display="flex" alignItems="center" style={{ marginTop: '10px' }}>
                                                                                    <TextField
                                                                                        select
                                                                                        id="title"
                                                                                        name="title"
                                                                                        value={formData?.title}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Title"
                                                                                        sx={{ width: '20px' }}>
                                                                                        <MenuItem value="Mr">Mr.</MenuItem>
                                                                                        <MenuItem value="Mrs">Mrs.</MenuItem>
                                                                                        <MenuItem value="Ms">Ms.</MenuItem>
                                                                                        <MenuItem value="Miss">Miss</MenuItem>
                                                                                        <MenuItem value="Dr">Dr.</MenuItem>
                                                                                    </TextField>

                                                                                    <TextField
                                                                                        required
                                                                                        id="firstname"
                                                                                        name="firstname"
                                                                                        value={formData?.firstname}
                                                                                        onChange={(event) => {
                                                                                            handleBasicInfoChanges(event);
                                                                                            setFormData((prev) => ({
                                                                                                ...prev,
                                                                                                name: event.target.value, // Update `name` with the same value as `firstname`
                                                                                            }));
                                                                                        }}
                                                                                        label="First Name"
                                                                                        error={!!errors?.firstname} // Highlight the field if there's an error
                                                                                        helperText={errors?.firstname ? "First Name is required" : ""} // Display error message if present
                                                                                    />

                                                                                    <TextField
                                                                                        id="middlename"
                                                                                        name="middlename"
                                                                                        value={formData?.middlename}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Middle Name" />

                                                                                    <TextField
                                                                                        id="lastname"
                                                                                        name="lastname"
                                                                                        value={formData?.lastname}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Last Name" />
                                                                                </Box>
                                                                                <Box display="flex" alignItems="center" style={{ marginTop: '10px' }}>
                                                                                    <TextField
                                                                                        id="fhname"
                                                                                        name="fhname"
                                                                                        value={formData?.fhname}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Father's/Husband's Name"
                                                                                        fullWidth />
                                                                                    <TextField
                                                                                        select
                                                                                        id="gender"
                                                                                        name="gender"
                                                                                        value={formData?.gender}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Gender"
                                                                                        fullWidth>
                                                                                        <MenuItem value="Male">Male</MenuItem>
                                                                                        <MenuItem value="Female">Female</MenuItem>
                                                                                    </TextField>
                                                                                </Box>
                                                                                <Box display="flex" alignItems="center" style={{ marginTop: '10px' }}>
                                                                                    <TextField
                                                                                        id="dob"
                                                                                        name="dob"
                                                                                        value={formData?.dob}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        type="date"
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        label="Date of Birth" />
                                                                                    <TextField
                                                                                        select
                                                                                        id="ismarital"
                                                                                        name="ismarital"
                                                                                        value={formData?.ismarital}
                                                                                        onChange={handleBasicInfoChanges}
                                                                                        label="Marital Status"
                                                                                        fullWidth>
                                                                                        <MenuItem value="1">Married</MenuItem>
                                                                                        <MenuItem value="0">Unmarried</MenuItem>
                                                                                    </TextField>
                                                                                </Box>
                                                                                <TextField
                                                                                    id="Pmobile"
                                                                                    name="Pmobile"
                                                                                    value={formData?.Pmobile}
                                                                                    onChange={handleBasicInfoChanges}
                                                                                    label="Personal Mobile No"
                                                                                    fullWidth
                                                                                    style={{ marginTop: '20px' }}
                                                                                    error={!isMobileValid2}
                                                                                    helperText={!isMobileValid2 ? 'Invalid Mobile No' : ''}
                                                                                    onInput={(e) => {
                                                                                        const input = e.target as HTMLInputElement;
                                                                                        input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                                    }} />
                                                                                <TextField
                                                                                    id="Pemail"
                                                                                    name="Pemail"
                                                                                    value={formData?.Pemail}
                                                                                    onChange={handleBasicInfoChanges}
                                                                                    label="Personal Email ID"
                                                                                    fullWidth
                                                                                    style={{ marginTop: '20px' }}
                                                                                    error={!isEmailValid2}
                                                                                    helperText={!isEmailValid2 ? 'Invalid Email ID' : ''} />
                                                                            </Grid>
                                                                            <label htmlFor="img" style={{ display: "block", cursor: "pointer" }}>
                                                                                <Box
                                                                                    sx={{
                                                                                        width: 150,
                                                                                        height: 150,
                                                                                        border: "1px solid #ccc",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        position: "relative",
                                                                                        overflow: "hidden",
                                                                                        bgcolor: selectedImage ? "transparent" : "#f0f0f0",
                                                                                        marginTop: "90px",
                                                                                        marginLeft: "90px",
                                                                                    }}
                                                                                >
                                                                                    {selectedImage ? (
                                                                                        <img
                                                                                            src={selectedImage}
                                                                                            alt="Employee"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                height: "100%",
                                                                                                objectFit: "cover",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <>
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                color="textSecondary"
                                                                                                style={{ marginTop: "35px", fontSize: "11px" }}
                                                                                            >
                                                                                                Upload Photo
                                                                                            </Typography>
                                                                                            <IconButton
                                                                                                component="span"
                                                                                                sx={{
                                                                                                    position: "absolute",
                                                                                                    top: "42%",
                                                                                                    left: "50%",
                                                                                                    transform: "translate(-50%, -50%)",
                                                                                                    color: "#777",
                                                                                                    bgcolor: "transparent",
                                                                                                    "&:hover": {
                                                                                                        bgcolor: "rgba(0, 0, 0, 0.4)",
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                <CameraAltIcon fontSize="medium" />
                                                                                            </IconButton>
                                                                                        </>
                                                                                    )}
                                                                                </Box>
                                                                                <input
                                                                                    type="file"
                                                                                    name="img"
                                                                                    id="img"
                                                                                    onChange={handleFileChange}
                                                                                    accept="image/*"
                                                                                    style={{ display: "none" }}
                                                                                />
                                                                            </label>
                                                                        </Grid>
                                                                    </Box>
                                                                </CardContent>
                                                            </Card>
                                                            <br />
                                                            <Card>
                                                                <Divider />
                                                                <CardContent>
                                                                    <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '24ch' } }} noValidate autoComplete="off">
                                                                        <Typography variant="h6" style={{ marginLeft: '12px' }}><b>Current Address</b></Typography>
                                                                        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                                                                            <TextField
                                                                                id="cadd1"
                                                                                label="Flat No/Street Name"
                                                                                name="cadd1"
                                                                                value={formData?.cadd1}
                                                                                onChange={handleBasicInfoChanges}
                                                                                sx={{ width: '200px' }}
                                                                            />
                                                                            <TextField
                                                                                id="cadd2"
                                                                                label="City"
                                                                                name="cadd2"
                                                                                value={formData?.cadd2}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                sx={{ width: '200px' }}
                                                                            />
                                                                            <TextField
                                                                                select
                                                                                id="cadd3"
                                                                                label="State"
                                                                                name="cadd3"
                                                                                value={formData?.cadd3}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                sx={{ width: '200px' }}
                                                                            >
                                                                                {states.map((option) => (
                                                                                    <MenuItem key={option.id} value={option.name}>
                                                                                        {option.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <TextField
                                                                                select
                                                                                id="cadd4"
                                                                                label="District"
                                                                                name="cadd4"
                                                                                value={formData?.cadd4}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                sx={{ width: '200px' }}
                                                                            >
                                                                                {dist.map((option) => (
                                                                                    <MenuItem key={option.id} value={option.name}>
                                                                                        {option.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <TextField
                                                                                id="cadd5"
                                                                                label="Pincode"
                                                                                name="cadd5"
                                                                                value={formData?.cadd5}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 6 }}
                                                                                sx={{ width: '200px' }}
                                                                                error={!ispincodevalid2}
                                                                                helperText={!ispincodevalid2 ? 'Invalid Pin Code' : ''}
                                                                                onInput={(e) => {
                                                                                    const input = e.target as HTMLInputElement;
                                                                                    input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                                }}
                                                                            />
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                onClick={handleMoveToPermanent}
                                                                                sx={{ width: 'auto', height: '35px', alignSelf: 'center', mt: 1 }}
                                                                            >
                                                                                Copy to Permanent Address
                                                                            </Button>
                                                                        </Box>
                                                                        <Divider sx={{ marginTop: '14px', marginBottom: '-12px' }} />
                                                                        <Typography variant="h6" mt={3} style={{ marginLeft: '12px' }}><b>Permanent Address</b></Typography>
                                                                        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                                                                            <TextField
                                                                                id="add1"
                                                                                label="Flat No/Street Name"
                                                                                name="add1"
                                                                                value={formData?.add1}
                                                                                onChange={handleBasicInfoChanges}
                                                                                sx={{ width: '200px' }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            />
                                                                            <TextField
                                                                                id="add2"
                                                                                label="City"
                                                                                name="add2"
                                                                                value={formData?.add2}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                sx={{ width: '200px' }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            />
                                                                            <TextField
                                                                                select
                                                                                id="add3"
                                                                                label="State"
                                                                                name="add3"
                                                                                value={formData?.add3 || ''}

                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                sx={{ width: '200px' }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            >
                                                                                {states1.map((option) => (
                                                                                    <MenuItem key={option.id} value={option.name}>
                                                                                        {option.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <TextField
                                                                                select
                                                                                id="add4"
                                                                                label="District"
                                                                                name="add4"
                                                                                value={formData?.add4 || dist1[0]?.name || ''}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                sx={{ width: '200px' }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            >
                                                                                {dist1.map((option) => (
                                                                                    <MenuItem key={option.id} value={option.name}>
                                                                                        {option.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <TextField
                                                                                id="add5"
                                                                                label="Pincode"
                                                                                name="add5"
                                                                                value={formData?.add5}
                                                                                onChange={handleBasicInfoChanges}
                                                                                inputProps={{ maxLength: 6 }}
                                                                                sx={{ width: '200px' }}
                                                                                error={!ispincodevalid1}
                                                                                helperText={!ispincodevalid1 ? 'Invalid Pin Code' : ''}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                onInput={(e) => {
                                                                                    const input = e.target as HTMLInputElement;
                                                                                    input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '-10px' }}>
                                                            <Typography variant="h6" style={{ margin: '10px' }}><b>EDUCATION</b></Typography>
                                                            <IconButton onClick={togglePanel1} style={{ right: '90px', position: 'absolute', display: 'flex', justifyContent: 'flex-end' }}>
                                                                <FontAwesomeIcon icon={isExpanded1edu ? faMinus : faPlus} />
                                                            </IconButton>
                                                        </div>

                                                        {isExpanded1edu && (
                                                            <>
                                                                <br />
                                                                <table style={{ width: '100%' }}>
                                                                    <thead style={{ backgroundColor: '#D3D3D3' }}>
                                                                        <tr>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Certificate / Degree</th>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Duration of the studies</th>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>School / Board/ University</th>
                                                                            <th colSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Year</th>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Total marks</th>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>%</th>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Documents</th>
                                                                            <th rowSpan={2} style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Action</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>From</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>To</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formData?.education?.map((row, index) => (
                                                                            <tr key={index}>
                                                                                {/* Certificate / Degree */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        select
                                                                                        id="qualid"
                                                                                        name="qualid"
                                                                                        value={row?.qualid || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        inputProps={{ maxLength: 50 }}
                                                                                        sx={{ width: '170px' }}
                                                                                    >
                                                                                        {qualification.map((option) => (
                                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                                {option.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </td>
                                                                                {/* Duration of Studies */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="dueyear"
                                                                                        value={row?.dueyear || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '80px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {/* School / Board / University */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="institute"
                                                                                        value={row?.institute || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '170px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {/* Year From */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="edyearfrom"
                                                                                        value={row?.edyearfrom || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {/* Year To */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="edyearto"
                                                                                        value={row?.edyearto || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {/* Total Marks */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="totalmarks"
                                                                                        value={row?.totalmarks || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '70px'
                                                                                        }}
                                                                                        onInput={(e) => {
                                                                                            const input = e.target as HTMLInputElement;
                                                                                            input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {/* Percentage */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="totalper"
                                                                                        value={row?.totalper || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('education', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '70px'
                                                                                        }}
                                                                                        onInput={(e) => {
                                                                                            const input = e.target as HTMLInputElement;
                                                                                            input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {/* Documents */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <input
                                                                                        type="file"
                                                                                        name="documents"
                                                                                        id="documents"
                                                                                        onChange={handleDocs1Change}
                                                                                    />
                                                                                </td>
                                                                                {/* Action - Delete Row */}
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <IconButton
                                                                                        color="secondary"
                                                                                        onClick={() => deleteRow1(index)}
                                                                                        style={{ fontSize: '20px' }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                                    </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px' }}>
                                                                    <div style={{ position: 'absolute' }}>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addRow1}
                                                                            style={{ backgroundColor: '#6495ED', color: 'white' }}
                                                                        >
                                                                            + Add More
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                            </>
                                                        )}

                                                    </CardContent>
                                                </Card>
                                                <br />
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '-10px' }}>
                                                            <Typography variant="h6" style={{ margin: '10px' }}><b>EXPERIENCE</b></Typography>
                                                            <IconButton onClick={togglePanel2} style={{ right: '90px', position: 'absolute', display: 'flex', justifyContent: 'flex-end' }}>
                                                                <FontAwesomeIcon icon={isExpanded2 ? faMinus : faPlus} />
                                                            </IconButton>
                                                        </div>
                                                        {isExpanded2 && (
                                                            <>
                                                                <br />
                                                                <table>
                                                                    <thead style={{ backgroundColor: '#D3D3D3' }}>
                                                                        <tr>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Post</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Employer</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>From</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>To</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Nature of work</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Documents</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formData?.experience?.map((row, index) => (
                                                                            <tr key={index}>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="design"
                                                                                        value={row.design || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('experience', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        // type="text"
                                                                                        className="textbox-style"
                                                                                        name="company"
                                                                                        value={row.company || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('experience', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="exyearfrom"
                                                                                        value={row.exyearfrom || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('experience', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="exyearto"
                                                                                        value={row.exyearto || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('experience', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <TextField
                                                                                        //type="text"
                                                                                        className="textbox-style"
                                                                                        name="natureofwork"
                                                                                        value={row.natureofwork || ''}
                                                                                        onChange={(e) => handleNestedInputChanges('experience', index, e)}
                                                                                        sx={{
                                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                                border: 'none',
                                                                                            }, width: '100px'
                                                                                        }}
                                                                                    />
                                                                                </td>

                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <input
                                                                                        type="file"
                                                                                        name="documents"
                                                                                        id="documents"
                                                                                        onChange={handleDocs2Change}
                                                                                    />
                                                                                    {/* Display the URL as a clickable link */}
                                                                                    {/* {ExperienceData.documents && (
                                                                                <a href={ExperienceData.documents} target="_blank" rel="noopener noreferrer">
                                                                                    View File
                                                                                </a>
                                                                            )}*/}</td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}> <IconButton
                                                                                    color="secondary"
                                                                                    onClick={() => deleteRow2(index)}
                                                                                    style={{ fontSize: '20px' }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                                </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <TextField
                                                                    id="totalexp"
                                                                    name="totalexp"
                                                                    value={formData?.totalexp}
                                                                    onChange={handleBasicInfoChanges}
                                                                    style={{ marginTop: '12px' }}
                                                                    label="Total Experience"
                                                                />
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px' }}>
                                                                    <div style={{ position: 'absolute' }}>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addRow2}
                                                                            style={{ backgroundColor: '#6495ED', color: 'white' }}
                                                                        >
                                                                            + Add More
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                            </>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                                <br />
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '-10px' }}>
                                                            <Typography variant="h6" style={{ margin: '10px' }}><b>LANGUAGE PROFICIENCY</b></Typography>
                                                            <IconButton onClick={togglePanel3} style={{ marginRight: '16px' }}>
                                                                <FontAwesomeIcon icon={isExpanded3 ? faMinus : faPlus} />
                                                            </IconButton>
                                                        </div>

                                                        {isExpanded3 && (
                                                            <>
                                                                <br />
                                                                <table>
                                                                    <thead style={{ backgroundColor: '#D3D3D3' }}>
                                                                        <tr>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Language</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Reading</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Writing</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Speaking</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Documents</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formData?.languageprof?.map((row, index) => (
                                                                            <tr key={index}>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><TextField
                                                                                    select
                                                                                    //id="lanid"
                                                                                    name="lanid"
                                                                                    value={row.lanid || ''}
                                                                                    onChange={(e) => handleNestedInputChanges('languageprof', index, e)}
                                                                                    inputProps={{ maxLength: 50 }}
                                                                                    sx={{ width: '200px' }}
                                                                                >
                                                                                    {language.map((option) => (
                                                                                        <MenuItem key={option.id} value={option.id}>
                                                                                            {option.name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><input type="checkbox" className="styled-checkbox" checked={!!(row?.isread && row.isread === '1')} name="isread" onChange={(e) => handleNestedInputChanges('languageprof', index, e)} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><input type="checkbox" className="styled-checkbox" checked={row?.iswrite === '1'} name="iswrite" onChange={(e) => handleNestedInputChanges('languageprof', index, e)} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><input type="checkbox" className="styled-checkbox" checked={row?.isspeak === '1'} name="isspeak" onChange={(e) => handleNestedInputChanges('languageprof', index, e)} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}>
                                                                                    <input
                                                                                        type="file"
                                                                                        name="documents"
                                                                                        id="documents"
                                                                                        onChange={handleDocs3Change}
                                                                                    />
                                                                                    {/* Display the URL as a clickable link */}
                                                                                    {/* {LanguageProfData.documents && (
                                                                                <a href={LanguageProfData.documents} target="_blank" rel="noopener noreferrer">
                                                                                    View File
                                                                                </a>
                                                                            )} */}</td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}> <IconButton
                                                                                    color="secondary"
                                                                                    onClick={() => deleteRow3(index)}
                                                                                    style={{ fontSize: '20px' }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                                </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px', position: 'sticky' }}>
                                                                    <button type="button" onClick={addRow3} style={{ backgroundColor: '#6495ED', color: 'white' }}> + Add More</button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                                <br />
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '-10px' }}>
                                                            <Typography variant="h6" style={{ margin: '10px' }}><b>FAMILY DETAILS</b></Typography>
                                                            <IconButton onClick={togglePanel4} style={{ marginRight: '16px' }}>
                                                                <FontAwesomeIcon icon={isExpanded4 ? faMinus : faPlus} />
                                                            </IconButton>
                                                        </div>
                                                        {isExpanded4 && (
                                                            <>
                                                                <br />
                                                                <table>
                                                                    <thead style={{ backgroundColor: '#D3D3D3' }}>
                                                                        <tr>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Relation</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Family Members</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Age</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Working status</th>
                                                                            <th style={{ textAlign: 'center', backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formData?.family?.map((row, index) => (
                                                                            <tr key={index}>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><TextField name="relation" value={row.relation || ''} onChange={(e) => handleNestedInputChanges('family', index, e)} sx={{
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none',
                                                                                    },
                                                                                }} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><TextField name="name" value={row.name || ''} onChange={(e) => handleNestedInputChanges('family', index, e)} sx={{
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none',
                                                                                    },
                                                                                }} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><TextField name="age" value={row.age || ''} onChange={(e) => handleNestedInputChanges('family', index, e)} sx={{
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none',
                                                                                    }, width: '50px'
                                                                                }}
                                                                                    onInput={(e) => {
                                                                                        const input = e.target as HTMLInputElement;
                                                                                        input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                                    }} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}><TextField name="workingstatus" value={row.workingstatus || ''} onChange={(e) => handleNestedInputChanges('family', index, e)} sx={{
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none',
                                                                                    },
                                                                                }} /></td>
                                                                                <td style={{ textAlign: 'center', border: '1px solid grey' }}> <IconButton
                                                                                    color="secondary"
                                                                                    onClick={() => deleteRow4(index)}
                                                                                    style={{ fontSize: '20px' }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                                </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px' }}>
                                                                    <button type="button" onClick={addRow4} style={{ backgroundColor: '#6495ED', color: 'white' }}> + Add More</button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                                <br />
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '-10px' }}>
                                                            <Typography variant="h6" style={{ margin: '10px' }}><b>OTHER DETAILS</b></Typography>
                                                        </div>
                                                        <br />
                                                        <ol>
                                                            {/** Wrapping everything in a container to create two fixed columns **/}
                                                            <Box display="grid" gridTemplateColumns="400px auto" gap="27px">
                                                                <li style={{ fontSize: '15px' }}>Are you currently employed?</li>
                                                                <RadioGroup
                                                                    name="iscurremp"
                                                                    value={formData?.iscurremp}
                                                                    onChange={handleBasicInfoChanges}
                                                                    row
                                                                >
                                                                    <FormControlLabel name="iscurremp" value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel name="iscurremp" value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>

                                                                <li style={{ fontSize: '15px' }}>Do you have a valid driver's license and Own vehicle?</li>
                                                                <RadioGroup
                                                                    name="isdriverlicense"
                                                                    value={formData?.isdriverlicense}
                                                                    onChange={handleBasicInfoChanges}
                                                                    row
                                                                >
                                                                    <FormControlLabel name="isdriverlicense" value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel name="isdriverlicense" value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>

                                                                <li style={{ fontSize: '15px' }}>Driving License Number & Where Taken</li>
                                                                <TextField
                                                                    id="isdriverlicenseno"
                                                                    name="isdriverlicenseno"
                                                                    style={{ marginRight: '20px' }}
                                                                    value={formData?.isdriverlicenseno}
                                                                    onChange={handleBasicInfoChanges}
                                                                />

                                                                <li style={{ fontSize: '15px' }}>Do you willing travel official thrusts the country?</li>
                                                                <TextField
                                                                    name="dowillingtravel"
                                                                    id="dowillingtravel"
                                                                    style={{ marginRight: '20px' }}
                                                                    value={formData?.dowillingtravel}
                                                                    onChange={handleBasicInfoChanges}
                                                                />

                                                                <li style={{ fontSize: '15px' }}>Are you suffering from any physical disability?</li>
                                                                <RadioGroup
                                                                    name="isphysicaldisability"
                                                                    value={formData?.isphysicaldisability}
                                                                    onChange={handleBasicInfoChanges}
                                                                    row
                                                                >
                                                                    <FormControlLabel name="isphysicaldisability" value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel name="isphysicaldisability" value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>

                                                                <li style={{ fontSize: '15px' }}>Are you having any other major sickness?</li>
                                                                <TextField
                                                                    id="aremajorsick"
                                                                    name="aremajorsick"
                                                                    style={{ marginRight: '20px' }}
                                                                    value={formData?.aremajorsick}
                                                                    onChange={handleBasicInfoChanges}
                                                                />

                                                                <li style={{ fontSize: '15px' }}>How did you hear about this job?</li>
                                                                <TextField
                                                                    id="howhearjob"
                                                                    name="howhearjob"
                                                                    style={{ marginRight: '20px' }}
                                                                    value={formData?.howhearjob}
                                                                    onChange={handleBasicInfoChanges}
                                                                />

                                                                <li style={{ fontSize: '15px' }}>Were you referred by our employee?</li>
                                                                <RadioGroup
                                                                    id="isrefer"
                                                                    name="isrefer"
                                                                    value={formData?.isrefer}
                                                                    onChange={handleBasicInfoChanges}
                                                                    row
                                                                >
                                                                    <FormControlLabel name="isrefer" value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel name="isrefer" value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>

                                                                {formData.isrefer == 1 && (
                                                                    <>
                                                                        <li style={{ fontSize: '15px' }}>His / Her name and Designation</li>
                                                                        <TextField
                                                                            select
                                                                            name="refername"
                                                                            style={{ marginRight: '20px' }}
                                                                            value={formData?.refername}
                                                                            onChange={handleBasicInfoChanges}
                                                                        >
                                                                            {employee.map((option) => (
                                                                                <MenuItem key={option.id} value={option.name}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>

                                                                        <li style={{ fontSize: '15px', marginLeft: '10px' }}>Give names and address of at least two references</li>
                                                                        <Box>
                                                                            <TextField
                                                                                id="referadd1"
                                                                                name="referadd1"
                                                                                style={{ marginBottom: '10px', width: '95%' }}
                                                                                value={formData?.referadd1}
                                                                                onChange={handleBasicInfoChanges}
                                                                                placeholder="Reference 1"
                                                                            />
                                                                            <TextField
                                                                                id="referadd2"
                                                                                name="referadd2"
                                                                                style={{ marginBottom: '10px', width: '95%' }}
                                                                                value={formData?.referadd2}
                                                                                onChange={handleBasicInfoChanges}
                                                                                placeholder="Reference 2"
                                                                            />

                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </ol>
                                                    </CardContent>
                                                </Card>
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    id="doj"
                                                                    name="doj"
                                                                    value={formData?.doj}
                                                                    onChange={handleBasicInfoChanges}
                                                                    type="date"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label="Date of Joining"
                                                                    fullWidth
                                                                    style={{ marginTop: '7px' }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Company"
                                                                    name="compid"
                                                                    fullWidth
                                                                    value={formData?.compid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {company.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Plant"
                                                                    name="plantid"
                                                                    fullWidth
                                                                    value={formData?.plantid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {plant.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Department"
                                                                    name="deptid"
                                                                    fullWidth
                                                                    value={formData?.deptid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {department.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Designation"
                                                                    name="designid"
                                                                    fullWidth
                                                                    value={formData?.designid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {designation.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    id="Omobile"
                                                                    name="Omobile"
                                                                    value={formData?.Omobile}
                                                                    onChange={handleBasicInfoChanges}
                                                                    label="Official Mobile No"
                                                                    fullWidth
                                                                    style={{ marginTop: '7px' }}
                                                                    error={!isMobileValid1}
                                                                    helperText={!isMobileValid1 ? 'Invalid Mobile No' : ''}
                                                                    onInput={(e) => {
                                                                        const input = e.target as HTMLInputElement;
                                                                        input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    id="Oemail"
                                                                    name="Oemail"
                                                                    value={formData?.Oemail}
                                                                    onChange={handleBasicInfoChanges}
                                                                    label="Official Email ID"
                                                                    fullWidth
                                                                    style={{ marginTop: '7px' }}
                                                                    error={!isEmailValid1}
                                                                    helperText={!isEmailValid1 ? 'Invalid Email ID' : ''}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                {formData?.empno && (
                                                                    <TextField
                                                                        id="empno"
                                                                        name="empno"
                                                                        value={formData?.empno}
                                                                        onChange={handleBasicInfoChanges}
                                                                        label="Reader ID"
                                                                        fullWidth
                                                                        style={{ marginTop: '7px' }}
                                                                    />
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} style={{ marginTop: '2px' }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Category"
                                                                    name="workcatgid"
                                                                    fullWidth
                                                                    value={formData?.workcatgid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {workcat.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Shift"
                                                                    name="shiftid"
                                                                    fullWidth
                                                                    value={formData?.shiftid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {shift.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </TabPanel>
                                            <TabPanel value={value} index={3}>
                                                <Card>
                                                    <Divider />
                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    select
                                                                    margin="dense"
                                                                    label="Salary Configuration"
                                                                    name="salconfigid"
                                                                    fullWidth
                                                                    value={formData?.salconfigid}
                                                                    onChange={handleBasicInfoChanges}
                                                                >
                                                                    {salaryconfig.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    id="revdate"
                                                                    name="revdate"
                                                                    value={formData?.revdate}
                                                                    onChange={handleBasicInfoChanges}
                                                                    type="date"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label="Revise Date"
                                                                    fullWidth
                                                                    style={{ marginTop: '7px' }} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    margin="dense"
                                                                    label="Salary"
                                                                    id="T"
                                                                    name="T"
                                                                    value={formData?.T}
                                                                    onChange={handleBasicInfoChanges}
                                                                    fullWidth
                                                                    onInput={(e) => {
                                                                        const input = e.target as HTMLInputElement;
                                                                        input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} justifyContent="flex-end" style={{ marginTop: 13 }}>
                                                                <Button onClick={handleCalculateSal} variant="contained" style={{ backgroundColor: '#ffa500' }}>
                                                                    Calculate
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        {Array.isArray(salarydetData) && salarydetData.length > 0 ? (
                                                            <Grid container spacing={3}>
                                                                {/* Earnings Table */}
                                                                {salarydetData.some((detail: any) => detail.type === 'e') && (
                                                                    <Grid item xs={6}> {/* Use xs={6} for 50% width */}
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell style={{ backgroundColor: '#D3D3D3', border: '1px solid grey' }}></TableCell>
                                                                                    <TableCell style={{ backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Earnings</TableCell>
                                                                                    <TableCell style={{ backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Amount</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {salarydetData
                                                                                    .filter((detail: any) => detail.type === 'e') // Filter rows where type is 'e'
                                                                                    .map((detail: any) => (
                                                                                        <TableRow key={detail.headid}>
                                                                                            <TableCell style={{ border: '1px solid grey' }}>
                                                                                                {/* <Checkbox
                                                                                                    checked={selectedEarnings.some((item) => item.headid === detail.headid)}// Determine if checked
                                                                                                    onChange={(e) => handleEarningsChange(e, detail)} // Handle checkbox change
                                                                                                /> */}
                                                                                                <Checkbox
                                                                                                    checked={selectedEarnings.some((item) => item.headid === detail.headid)}
                                                                                                    onChange={(e) => handleEarningsChange(e, detail)}
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell style={{ border: '1px solid grey' }}>{detail.name}</TableCell>
                                                                                            <TableCell style={{ border: '1px solid grey' }}>
                                                                                                <TextField
                                                                                                    type="text" // Not type="number" to keep it editable as text
                                                                                                    name="value"
                                                                                                    value={detail.value || ''} // Ensure this is bound to the state correctly
                                                                                                    onChange={(e) => handleAmountChange(e, detail)} // Handle value change
                                                                                                    fullWidth
                                                                                                    sx={{
                                                                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                                                                            border: 'none',
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                        <p>Total Earnings: {totalEarnings}</p>
                                                                    </Grid>
                                                                )}
                                                                {/* Deductions Table */}
                                                                {salarydetData.some((detail: any) => detail.type === 'd') && (
                                                                    <Grid item xs={6}> {/* Use xs={6} for 50% width */}
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow >
                                                                                    <TableCell style={{ backgroundColor: '#D3D3D3', border: '1px solid grey' }}></TableCell>
                                                                                    <TableCell style={{ backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Deductions</TableCell>
                                                                                    <TableCell style={{ backgroundColor: '#D3D3D3', border: '1px solid grey' }}>Amount</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {salarydetData
                                                                                    .filter((detail: any) => detail.type === 'd') // Filter rows where type is 'd'
                                                                                    .map((detail: any) => (
                                                                                        <TableRow key={detail.headid}>
                                                                                            <TableCell style={{ border: '1px solid grey' }}>
                                                                                                <Checkbox
                                                                                                    checked={selectedDeductions.some((item) => item.headid === detail.headid)} // Determine if checked
                                                                                                    onChange={(e) => handleDeductionsChange(e, detail)} // Handle checkbox change
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell style={{ border: '1px solid grey' }}>{detail.name}</TableCell>
                                                                                            <TableCell style={{ border: '1px solid grey' }}>
                                                                                                <TextField
                                                                                                    type="text" // Not type="number" to keep it editable as text
                                                                                                    name="value"
                                                                                                    value={detail.value || ''} // Ensure this is bound to the state correctly
                                                                                                    onChange={(e) => handleAmountChange(e, detail)} // Handle value change
                                                                                                    fullWidth
                                                                                                    sx={{
                                                                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                                                                            border: 'none',
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                        <p>Total Deductions: {totalDeductions}</p>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12} style={{ marginTop: '-30px' }}>
                                                                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem' }}>
                                                                        Net Pay: {totalsalary}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <p style={{ textAlign: 'center' }}>No salary details available</p>
                                                        )}
                                                        <Grid container item xs={12} justifyContent="flex-end" style={{ marginTop: 16 }}>
                                                            <Button onClick={handleSubmit} variant="contained" color="primary">
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </TabPanel>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center', // Align vertically in case of height differences
                                        gap: 2, // Add space between TextField and Button
                                        marginTop: '-15px', // Retain your margin adjustments
                                        marginBottom: '5px',
                                    }}
                                >
                                    <TextField
                                        id="search"
                                        name="search"
                                        value={search}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                                        margin="dense"
                                        placeholder="Search"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ width: 'auto' }}
                                    />
                                    <Button variant="contained" color="primary" onClick={handleAddNewClick}>
                                        Add New
                                    </Button>
                                </Box>

                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>S.No</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>Employee No</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Company</TableCell>
                                                    <TableCell>Department</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Action</TableCell>
                                                    {/* Add more headers as necessary */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.slice().map((row, index) => {
                                                    const compItem = company.find(option => option.id === row.compid);
                                                    const deptItem = department.find(option => option.id === row.deptid);

                                                    return (
                                                        <TableRow key={row.id}>
                                                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                            <TableCell>
                                                                <img
                                                                    src={row.img || 'https://image.shutterstock.com/image-vector/vector-flat-illustration-avatar-user-260nw-2503308093.jpg'}
                                                                    //alt="Employee"
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                        objectFit: 'cover',
                                                                        borderRadius: '50%',
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{row.empno}</TableCell>
                                                            <TableCell>{row.firstname}</TableCell>
                                                            <TableCell>{compItem ? compItem.name : ' '}</TableCell>
                                                            <TableCell>{deptItem ? deptItem.name : ' '}</TableCell>
                                                            <TableCell style={{ color: row.isactive == 1 ? "green" : 'red' }}>
                                                                {row.isactive == 1 ? "Active" : "In-Active"}
                                                            </TableCell>
                                                            <TableCell>
                                                                <span onClick={() => handleEditClick(row)}>
                                                                    <FaPencil />
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>

                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        count={data.length === 0 ? 0 : data[0]["count"]}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25]}
                                    />
                                </Paper>
                            </>
                        )}
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </Grid>
                </Grid>
            </Container >
            <Footer />
        </>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}


export default EmployeeRegister;
import React, { useState } from 'react';
import * as qna from '@tensorflow-models/qna';
import '@tensorflow/tfjs';

const QuestionAnswering: React.FC = () => {
  const [model, setModel] = useState<qna.QuestionAndAnswer | null>(null);
  const [inputText, setInputText] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [answers, setAnswers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Load the model
  const loadModel = async () => {
    setLoading(true);
    try {
      const loadedModel = await qna.load();
      setModel(loadedModel);
      console.log('Model loaded successfully!');
    } catch (error) {
      console.error('Error loading model:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle answering a question
  const handleAnswer = async () => {
    if (!model) {
      alert('Please load the model first.');
      return;
    }

    if (!inputText || !question) {
      alert('Please provide both context and a question.');
      return;
    }

    try {
      const predictions = await model.findAnswers(question, inputText);
      console.log('Predictions:', predictions);
      if (predictions.length > 0) {
        setAnswers(predictions.map((pred) => pred.text));
      } else {
        setAnswers(['No answer found.']);
      }
    } catch (error) {
      console.error('Error finding answers:', error);
      setAnswers(['Error occurred while answering the question.']);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h1>Natural Language Q&A</h1>
      <button onClick={loadModel} disabled={loading || !!model}>
        {model ? 'Model Loaded' : loading ? 'Loading...' : 'Load Model'}
      </button>
      <div style={{ marginTop: '20px' }}>
        <textarea
          rows={5}
          placeholder="Enter context"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <input
          type="text"
          placeholder="Ask a question "
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <button onClick={handleAnswer} disabled={!model}>
          Get Answer
        </button>
      </div>
      <div style={{ marginTop: '20px' }}>
        <h2>Answers:</h2>
        <ul>
          {answers.map((answer, index) => (
            <li key={index}>{answer}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QuestionAnswering;

import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { ToastContainer } from "react-toastify";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  MenuItem,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  TablePagination,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { useState, SyntheticEvent, useEffect, ChangeEvent, FormEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { number, string } from 'prop-types';
import apiService from 'src/services/api-service';
import axios from 'axios';
import { Attachment, Login } from '@mui/icons-material';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useNavigate } from 'react-router';
import { log } from 'console';




interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Data {
  TableRows: [
    {
      description: string,
      quantity: number,
      idenqdet: number,
      EssentialTableRows: [
        {
          requirements: string,
          essdatid: number,
          compliance: string,
          esattach: File
        }
      ],
    }
  ],
  updcheck: boolean;
  finYearId: number;
  lenessrow: number;
  empId: string;
  enqID: number;
  typeID: number;
  newEx: string;
  modeId: number;
  divId: number;
  rfxEnqNo: string;
  dueOn: Date;
  statusId: string;
  desp: string;
  basicValue: number;
  ccperson: string;
  cdesign: string;
  cmobile: string;
  cmail: string;
  tcperson: string;
  tdesign: string;
  tmobile: number;
  tmail: string;
  bankName: string;
  branchname: string;
  accNo: string;
  ifsc: string;
  repeatenqid: number;
  isExcus: boolean;
  excusid: number;
  sname: string;
  code: string;
  name: string;
  city: string | null;
  ceo: string;
  md: string;
  mp: string;
  caddr1: string | null;
  caddr2: string | null;
  caddr3: string | null;
  caddr4: string | null;
  caddr5: string | null;
  caddr6: number | null;
  caddr7: string | null;
  faddr1: string | null;
  faddr2: string | null;
  faddr3: string | null;
  faddr4: string | null;
  faddr5: string | null;
  faddr6: number | null;
  faddr7: string | null;
  panno: string;
  tinno: string;
  email: string;
  omobile: string;
  pmobile: number;
  gstno: string;
  nbus: string;
  ncomp: string;
  datestart: Date;
  cpname: string;
  cpdesign: string;
  cpmobile: number;
  cpmail: string;
  annualturnover: string;
  buildOR: string;
  followtype: number;
  followdesc: string;
  isqul: boolean;
  nextfollow: Date;
  status: string;
  priority: string;
  nfteamid: string;
  nfempid: string;
  attch: string;
  divsname: string;
  divname: string;

}

interface Type {
  id: number;
  name: string;
}
interface Mode {
  id: number;
  name: string;
}
interface Division {
  id: number;
  name: string;
}
interface DrawingCategory {
  id: number;
  name: string;
}

interface ReqType {
  id: number;
  name: string;
}

interface TEST {
  id: number;
  name: string;
  type: string;
  reqtypeid: number;
  isactive: number;
}
interface City {
  id: number;
  sname: string;
  name: string;
}

interface Dist {
  id: number;
  name: string;
  stateid: number;
}

interface State {
  id: number;
  name: string;
}
interface Status {
  id: number;
  name: string;
  islead: number;
}
interface Followtype {
  id: number;
  name: string;
}
interface EssDta {
  id: number;
  name: string;
  compliance: String,
  isattach: 1;
  reqtypeid: number;
}



interface RowData {
  id: number;
  code: string;
  name: string;
  city: string;
  mobile: string;
  email: string;
  ccperson: string;
  isMajor: boolean;
  enqno: string;
  enqdate: string;
  status: string;
}

interface RowData1 {
  id: number;
  EnquiryId: number;
  CustomerName: string;
  EnquiryNumber: string;
  EnquiryDate: string;
  StatusName: string;
}

interface Priority {
  id: number;
  name: string;
  isactive: number;
}



// type TabValue = 0 | 1 | 2;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const AddEnquiry: React.FC = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [customerType, setCustomerType] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [data, setData] = useState<Data[]>([]);
  const [type, setType] = useState<Type[]>([]);
  const [mode, setMode] = useState<Mode[]>([]);
  const [city, setCity] = useState<City[]>([]);
  const [dist, setDist] = useState<Dist[]>([]);
  const [dist1, setDist1] = useState<Dist[]>([]);

  const [states1, setStates1] = useState<State[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [status, setStatus] = useState<Status[]>([]);
  const [follow, setFollow] = useState<Followtype[]>([]);
  const [division, setDivision] = useState<Division[]>([]);
  const [drawing, setDrawing] = useState<DrawingCategory[]>([]);
  const [reqType, setReqtype] = useState<ReqType[]>([]);
  const [test, setTest] = useState<TEST[]>([]);
  const [rowdata, setRowdata] = useState<RowData[]>([]);
  const [rowdata1, setRowdata1] = useState<RowData1[]>([]);
  const [priority, setPriority] = useState<Priority[]>([]);
  const [essdata, setEssdata] = useState<EssDta[]>([]);
  const [essDataValue, setEssDataValue] = useState<EssDta[]>([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowdet, setSelectedRowdet] = useState(null);
  // const [data, setData] = useState<RowData[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputValue, setInputValue] = useState('');
  const [isPMobileValid, setIsPMobileValid] = useState(true);
  // const [isCMobileValid, setIsCMobileValid] = useState(true);
  const [isTMobileValid, setIsTMobileValid] = useState(true);
  const [isCAMobileValid, setIsCAMobileValid] = useState(true);
  const [isFAMobileValid, setIsFAMobileValid] = useState(true);
  const [search, setSearch] = useState('');
  const [search1, setSearch1] = useState('');

  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCEmailValid, setIsCEmailValid] = useState(true);
  const [isTEmailValid, setIsTEmailValid] = useState(true);
  const [isCAEmailValid, setIsCAEmailValid] = useState(true);
  const [isFAEmailValid, setIsFAEmailValid] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab1, setSelectedTab1] = useState(2);
  const [selectedTab2, setSelectedTab2] = useState(0);
  const [formData, setFormData] = useState<any>({
    tableRows: [
      {
        description: '',
        quantity: '0',
        idenqdet: 0,
        eqipposition: "",
        printcolor: "",
        asreqfoam: "",
        foam: "",
        inputno: "",
        drawcatgid: 0,
        drawreqon: null,
        multiattach: [
          {
            filename: "",
            url: "",
            index:0
          }
        ],
        essentialTableRows: [
          {
            requirements: 0,
            essdatid: 0,
            compliance: '',
            esattach: '',
          }
        ]
      }
    ],
    testaccess: [
      {
        testaccessid: null,
        remarks: ""
      }
    ],
    updcheck: true,
    finYearId: 0,
    lenessrow: 0,
    empId: localStorage.getItem('empid'),
    typeID: 0,
    newEx: '',
    modeId: 0,
    divId: 0,
    rfxEnqNo: '',
    dueOn: null,
    statusId: '',
    desp: '',
    basicValue: 0,
    ccperson: '',
    cdesign: '',
    cmobile: '',
    cmail: '',
    tcperson: '',
    tdesign: '',
    tmobile: '',
    tmail: '',
    bankName: '',
    branchname: '',
    accNo: '',
    ifsc: '',
    repeatenqid: 0,
    isExcus: true,
    excusid: 0,
    sname: '',
    code: '',
    name: '',
    city: 0,
    ceo: '',
    md: '',
    mp: '',
    caddr1: '',
    caddr2: '',
    caddr3: '',
    caddr4: '',
    caddr5: '',
    caddr6: '',
    caddr7: '',
    faddr1: '',
    faddr2: '',
    faddr3: '',
    faddr4: '',
    faddr5: '',
    faddr6: '',
    faddr7: '',
    panno: '',
    tinno: '',
    email: '',
    omobile: '',
    pmobile: '',
    gstno: '',
    nbus: '',
    ncomp: '',
    datestart: new Date(),
    cpname: '',
    cpdesign: '',
    cpmobile: '',
    cpmail: '',
    annualturnover: '',
    buildOR: '',
    followtype: 0,
    followdesc: '',
    isqul: true,
    nextfollow: new Date(),
    status: '',
    priority: 'Cold',
    nfteamid: '',
    nfempid: '',
    attch: string,
    divsname: '',
    divname: '',
    reqtypeid: 2,
  });

  useEffect(() => {


    apiService.getDropdown("crm_mas_type").then(response => {
      setType(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("crm_mas_mode").then(response => {
      setMode(response.data);
    }).catch((err) => {
      console.log("err", err)
    });



    apiService.getDropdown("crm_mas_status").then(response => {
      setStatus(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("crm_mas_followtype").then(response => {
      setFollow(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("crm_mas_priority").then(response => {
      setPriority(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("crm_mas_essdata").then(response => {
      setEssdata(response.data);

      setEssDataValue(response.data?.map((data) => {
        return {
          requirements: data?.name,
          essdatid: data?.id,
          compliance: ''
        }
      }))
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("mas_city").then(response => {
      setCity(response.data);
    }).catch((err) => {
      console.log("err", err)
    });



    apiService.getDropdown("mas_state").then(response => {
      setStates(response.data);
      setStates1(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("crm_mas_dsncatg").then(response => {
      setDrawing(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("crm_mas_reqtype").then(response => {
      setReqtype(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("dsn_mas_testaccess").then(response => {
      setTest(response.data);
  

    }).catch((err) => {
      console.log("err", err)
    });


    const fetchData = async () => {
      try {

        const response = await apiService.postExcustomer(rowsPerPage, page + 1, search1, formData.typeID);

        setRowdata(response.data);
        successToast(response?.data?.message)

      } catch (error) {

        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [formData, page, rowsPerPage, search1]);



  useEffect(() => {
    const caddr4ToFind = formData?.caddr4;
    const selectedOption1 = states.find(option => option.name === caddr4ToFind);
    const stateid = selectedOption1 ? selectedOption1.id : null;

    const nameToFind = formData?.faddr4;
    const selectedOption = states1.find(option => option.name === nameToFind);
    const stateid1 = selectedOption ? selectedOption.id : null;

    // Fetch districts only if state IDs are available
    if (stateid !== null) {
      apiService.getDropdown("mas_district")
        .then(response => {
          const filteredData = response.data.filter(item => item.stateid === stateid);
          setDist(filteredData);
        })
        .catch(err => {
          console.log("err", err);
        });
    }

    if (stateid1 !== null) {
      apiService.getDropdown("mas_district")
        .then(response => {
          const filteredData1 = response.data.filter(item => item.stateid === stateid1);
          setDist1(filteredData1);
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  }, [formData, states, states1]);

  useEffect(() => {
    apiService.getEnquiry(search)
      .then(response => {
        setRowdata1(response.data);

      })
      .catch(err => console.error('Error fetching enquiry data:', err)); // Corrected error message
  }, [search]);

  useEffect(() => {

    if (formData?.excusid) {
      const desiredCusid = formData?.excusid;



      apiService.getDropdown("crm_mas_cusdivision")
        .then(response => {
          const filteredData = response.data.filter(item => item.cusid === desiredCusid);
          setDivision(filteredData);  // Set the filtered data


        })
        .catch(err => {
          console.log("Error:", err);
        });
    } else {
      console.log("No row  cusid is undefined");
    }
  }, [formData]);



  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const handleSearchChange1 = (event) => {
    setSearch1(event.target.value);

  };


  const handleEssential = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // const validateForm = () => {
  //   const requiredFields = [
  //     'typeID', 'newEx', 'name', 'modeId', 'gstno', 'panno', 'email', 'pmobile',
  //     'ccperson', 'cdesign', 'cmobile', 'cmail', 'tcperson', 'tdesign', 'tmobile', 'tmail'

  //   ];
  //   let valid = true;
  //   const newErrors: { [key: string]: string } = {};

  //   requiredFields.forEach((field) => {
  //     if (!formData[field]) {
  //       newErrors[field] = `${field} is required`;
  //       valid = false;
  //     }
  //   });



  //   if (formData.newEx === 'Ex' || formData.newEx === 'Repeat') {
  //     if (!formData.divId) {
  //       newErrors.divId = 'Division is required';
  //       valid = false;
  //     }
  //   } else {
  //     if (!formData.divname) {
  //       newErrors.divname = 'Division name is required';
  //       valid = false;
  //     }
  //   }
  //   // Email specific validation
  //   if (formData.pmobile && !isCMobileValid(formData.pmobile)) {
  //     newErrors.pmobile = 'Please enter valid Mobile Number';
  //     valid = false;
  //   }
  //   if (formData.email && !isEmailValid(formData.email)) {
  //     newErrors.email = 'Invalid email id';
  //     valid = false;
  //   }
  //   if (formData.cmobile && !isCMobileValid(formData.cmobile)) {
  //     newErrors.cmobile = 'Please enter valid Mobile Number';
  //     valid = false;
  //   }
  //   if (formData.cmail && !isEmailValid(formData.cmail)) {
  //     newErrors.cmail = 'Invalid email id';
  //     valid = false;
  //   }
  //   if (formData.tmobile && !isCMobileValid(formData.tmobile)) {
  //     newErrors.tmobile = 'Invalid mobile number';
  //     valid = false;
  //   }
  //   if (formData.tmail && !isEmailValid(formData.cmail)) {
  //     newErrors.cmail = 'Invalid email id';
  //     valid = false;
  //   }

  //   setErrors(newErrors);
  //   return valid;
  // };

  const validateForm = () => {
    const requiredFields = [
      'typeID', 'newEx', 'name', 'modeId', 'gstno', 'panno', 'email', 'pmobile',
      'ccperson', 'cdesign', 'cmobile', 'cmail', 'tcperson', 'tdesign', 'tmobile', 'tmail'
    ];
    let valid = true;
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
        valid = false;
      }
    });

    if (formData.newEx === 'Ex' || formData.newEx === 'Repeat') {
      if (!formData.divId) {
        newErrors.divId = 'Division is required';
        valid = false;
      }
    } else {
      if (!formData.divname) {
        newErrors.divname = 'Division name is required';
        valid = false;
      }
    }

    // Mobile number validation
    if (formData.pmobile && !isCMobileValid(formData.pmobile)) {
      newErrors.pmobile = 'Please enter valid Mobile Number';
      valid = false;
    }
    if (formData.cmobile && !isCMobileValid(formData.cmobile)) {
      newErrors.cmobile = 'Please enter valid Mobile Number';
      valid = false;
    }
    if (formData.tmobile && !isCMobileValid(formData.tmobile)) {
      newErrors.tmobile = 'Invalid mobile number';
      valid = false;
    }

    // Email validation
    if (formData.email && !isEmailValid(formData.email)) {
      newErrors.email = 'Invalid email id';
      valid = false;
    }
    if (formData.cmail && !isEmailValid(formData.cmail)) {
      newErrors.cmail = 'Invalid email id';
      valid = false;
    }
    if (formData.tmail && !isEmailValid(formData.cmail)) {
      newErrors.cmail = 'Invalid email id';
      valid = false;
    }

    // GST number validation (India-specific format)
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9/A-Z]{1}$/;
    if (formData.gstno && !gstRegex.test(formData.gstno)) {
      newErrors.gstno = 'Invalid GST Number';
      valid = false;
    }

    //     GST Number Format
    // A valid GST number in India is a 15-character alphanumeric string, structured as follows:

    // First two characters: State code (numeric values from 01 to 35)
    // Next 10 characters: PAN number (permanent account number)
    // 13th character: Entity number (alphanumeric)
    // 14th character: Default "Z"
    // 15th character: Checksum character (alphanumeric)

    //     GST Number Structure:
    // First 2 digits: State code (e.g., 33).
    // Next 5 characters: PAN card holder's name (e.g., AAGCS).
    // Next 4 digits: Unique number (e.g., 0934).
    // Next character: Alphabetic (usually a checksum) (e.g., C).
    // Next digit: 1-digit code (e.g., 1).
    // Next character: Alphabetic (e.g., Z).
    // Last digit: Checksum (e.g., U).

    setErrors(newErrors);
    return valid;
  };

  const validateForm1 = () => {
    const requiredFields = [
      'statusId', 'nextfollow', 'followdesc'
    ];
    let valid = true;
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
        valid = false;
      }
    });

    if (formData.nextfollow) {
      const currentDate = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
      if (formData.nextfollow < currentDate || !formData.nextfollow) {
        newErrors.nextfollow = 'Next Follow Date cannot be in the past';
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };
  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isCMobileValid = (mobile: string) => {
    const mobileRegex = /^[0-9]{10,11}$/; // Example regex for 10-digit mobile numbers
    return mobileRegex.test(mobile);
  };


  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    console.log("emp id =>", localStorage.getItem('empid'));
    e.preventDefault();
    if (!validateForm1()) {
      const toastmsg = "Form is invalid. Please fill all required fields."
      errorToast(toastmsg)
      return;
    }


    try {
      console.log("formData", formData);
      const dataToSubmit = formData;
      console.log("dataToSubmit", dataToSubmit);
     const response = await apiService.postFormdata(dataToSubmit);
      successToast(response.data);
      navigate('/marketingcrm/LeadEnquiry', { state: { message: 'Enquiry added successfully!' } });


    } catch (error: any) {

      if (error.response) {
        // Server responded with a status other than 200 range
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data); // Log the error response data
        console.error('Error status:', error.response.status); // Log the error response status
        console.error('Error headers:', error.response.headers); // Log the error response headers
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something else happened while setting up the request
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  const validateMobile = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{10,11}$/;
    return mobileRegex.test(mobile);

  };
  const validateMobile1 = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{9}$/;
    return mobileRegex.test(mobile);

  };

  const validateMobile2 = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{6}$/;
    return mobileRegex.test(mobile);

  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[a-zA-Z]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email);
  };





  const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Mobile number validation
    if (['pmobile', 'cmobile', 'tmobile', 'caddr6', 'faddr6'].includes(name)) {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 11) return; // Prevent input if length exceeds 10 digits

      // Set validation states if necessary
      if (name === 'pmobile') {
        setIsPMobileValid(validateMobile(sanitizedValue));
      } else if (name === 'cmobile') {
        setIsTMobileValid(validateMobile(sanitizedValue));
      }
      else if (name === 'tmobile') {
        setIsTMobileValid(validateMobile(sanitizedValue));
      } else if (name === 'caddr6') {
        setIsCAMobileValid(validateMobile(sanitizedValue));
      } else if (name === 'faddr6') {
        setIsFAMobileValid(validateMobile(sanitizedValue));
      }
      else if (name === 'caddr5') {
        setIsCAMobileValid(validateMobile2(sanitizedValue));
      } else if (name === 'faddr5') {
        setIsFAMobileValid(validateMobile2(sanitizedValue));
      }



      // Update state
      if (selectedRow) {
        setSelectedRow(prevState => ({
          ...prevState,
          [name]: sanitizedValue
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          [name]: sanitizedValue
        }));
      }

      setErrors(prev => ({
        ...prev,
        [name]: '',
      }));

      return;
    }

    // Email validation
    if (['cmail', 'tmail', 'caddr7', 'faddr7'].includes(name)) {
      if (name === 'cmail') setIsCEmailValid(validateEmail(value));
      else if (name === 'tmail') setIsTEmailValid(validateEmail(value));
      else if (name === 'caddr7') setIsCAEmailValid(validateEmail(value));
      else if (name === 'faddr7') setIsFAEmailValid(validateEmail(value));
    }

    // Update state
    if (selectedRow) {
      setSelectedRow(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));

      setErrors(prev => ({
        ...prev,
        [name]: '',
      }));

    }

  };




  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (!validateForm()) {
      console.log("validattttt");
      return;

    } else {
      console.log("NEXTTTTTTTTT");

    setValue(newValue);
    }
  }
  const handleNext = () => {
    if (!validateForm()) {
      return;
    } else {
      setValue((prevValue) => Math.min(prevValue + 1, 3));
    }

  };

  // const handleAddRow = () => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     tableRows: [...prevState.tableRows, {
  //       description: '',
  //       quantity: 0,
  //       idenqdet: 0,
  //       essentialTableRows: [
  //         {
  //           requirements: 0,
  //           essdatid: 0,
  //           compliance: '',
  //           esattach: ""
  //         }
  //       ]
  //     }]
  //   }));
  // };

  const handleAddRow = () => {
    setFormData((prevState) => {
      // Validate that the last row's description is non-empty
      const lastRow = prevState.tableRows.at(-1);
  
      if (lastRow && lastRow.description.trim().length > 0) {
        const newIdenqdet = prevState.tableRows.length + 1; // Calculate new idenqdet
        return {
          ...prevState,
          tableRows: [
            ...prevState.tableRows,
            {
              description: '',
              quantity: '0',
              idenqdet: newIdenqdet, // Assign idenqdet based on the new row index
              eqipposition: '',
              printcolor: '',
              asreqfoam: '',
              foam: '',
              inputno: '',
              drawcatgid: 0,
              drawreqon: null,
              multiattach: [
                {
                  filename: '',
                  url: '',
                  index: newIdenqdet, // Assign an index or idenqdet to each multiattach entry
                },
              ],
              essentialTableRows: [
                {
                  requirements: 0,
                  essdatid: 0,
                  compliance: '',
                  esattach: '',
                },
              ],
            },
          ],
        };
      }
  
      console.warn('Last row must have a non-empty description before adding a new row.');
      return prevState; // No change if the validation fails
    });
  };
  

  const [values, setValues] = useState(0);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp') {
      setValues(values + 1);
    } else if (event.key === 'ArrowDown') {
      setValues(values - 1);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setFormData(prevState => ({
      ...prevState,
      priority: newValue
    }));
  };
  const handleTabChange1 = (event, newValue) => {
    setSelectedTab(newValue);

  };
  const handleTabChange2 = (event) => {
    const newValue = event.target.value; // Get the selected index from the dropdown
    setSelectedTab1(newValue); // Update the selected tab state

    const selectedRowId = reqType[newValue].id; // Get the selected row's id
    console.log("selectedRowId", selectedRowId);

    // Update formData with the selected row's reqtypeid
    setFormData((prevState) => ({
      ...prevState,
      reqtypeid: selectedRowId, // Update the reqtypeid
    }));

  };



  const handleTabChange3 = (event, newValue) => {
    setSelectedTab2(newValue);

  };




  const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const newTableRows = [...formData?.tableRows];
    newTableRows[index][name] = value;
    setFormData({ ...formData, tableRows: newTableRows });
    console.log("newTableRows", newTableRows);


  };




  const handleEssentialTableRowsChange = (
    rowid: number,
    index: number,
    tableRowindex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newEssentialTableRows = { ...formData };
    const { name, value } = event.target;
    let url = "";

    if (name === "esattach") {
      const formdata = new FormData();
      // Handle file uploads
      if (event.target instanceof HTMLInputElement && event.target.files) {
        const file = event.target.files[0];
        const formdata = new FormData();
        formdata.append('file', file);

        apiService.uploadFiles(formdata)
          .then(response => {
            url = response.data;

            let res = {
              requirements: 0,
              essdatid: rowid,
              name: response.data,
            };

            if (newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index] == undefined) {

              newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index] = res;
            } else {
              newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index][name] = response.data;
            }

            setFormData(newEssentialTableRows);
          })
          .catch(err => {
            console.log("err", err);
          });
      }
    } else {
      let res = {
        requirements: 0,
        essdatid: rowid,
        [name]: value,
      };

      if (!newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index]) {
        newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index] = res;
      } else {
        newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index][name] = value;
        newEssentialTableRows.tableRows[tableRowindex].essentialTableRows[index]["essdatid"] = rowid;
      }

      setFormData(newEssentialTableRows);
    }

  };


  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    tableRowIndex: number
  ) => {
    if (e.target.files) {
      const fileName = e.target.files[0].name;

      const newEssentialTableRows = { ...formData };
      newEssentialTableRows.tableRows[tableRowIndex].essentialTableRows[index].esattach = fileName;
      setFormData(newEssentialTableRows);
    }
  };

  const [rows1, setRows1] = useState<{ fileName: string }[]>([]);


  const handleFileUpload1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newRows = Array.from(e.target.files).map(file => ({ fileName: file.name }));
      setRows1(prevRows => [...prevRows, ...newRows]);
    }
  };

  // Handle row deletion
  const handleDeleteRow = (index: number) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      tableRows: prevFormData.tableRows.filter((_, i) => i !== index)
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClickOpen1 = (index: number) => {
    setSelectedRowdet(index);
    console.log("index => ", index);

    setOpen1(true);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setSelectedItem(null);
    setInputValue('');
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleCustomerTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerType(event.target.value);
    const { name, value } = event.target;
    if (selectedRow) {
      setSelectedRow(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };



  const handleCustomerClick = () => {
    if (customerType === 'Ex-New') {
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleRowClick = (row) => {
    setFormData(prevState => ({
      ...prevState,
      ["name"]: row.name,
      ["email"]: row.email,
      ["modeId"]: row.modeid,
      ["sname"]: row.sname,
      ["divId"]: row.divid,
      ["code"]: row.code,

      ["city"]: row.cityid,
      ["ceo"]: row.ceo,
      ["pmobile"]: row.mobile,
      ["gstno"]: row.gstno,
      ["panno"]: row.pan,
      ["tinno"]: row.tinno,
      ["rfxEnqNo"]: row.rfxenqno,
      ["cusid"]: row.cusid,
      ["ccperson"]: row.ccperson,
      ["cdesign"]: row.cdesign,
      ["cmobile"]: row.cmobile,
      ["cmail"]: row.cmail,
      ["tcperson"]: row.tcperson,
      ["tdesign"]: row.tdesign,
      ["tmobile"]: row.tmobile,
      ["tmail"]: row.tmail,
      ["bankName"]: row.bankname,
      ["branchname"]: row.branchname,
      ["accNo"]: row.accno,
      ["ifsc"]: row.ifsc,
      ["excusid"]: row.id,
      ["faddr1"]: row.add1,
      ["faddr2"]: row.add2,
      ["faddr3"]: row.add3,
      ["faddr4"]: row.add4,
      ["faddr5"]: row.add5,
      ["caddr1"]: row.cadd1,
      ["caddr2"]: row.cadd2,
      ["caddr3"]: row.cadd3,
      ["caddr4"]: row.cadd4,
      ["caddr5"]: row.cadd5,


    }));

    setOpen(false);
  };

  const [state, setState] = useState<{ id: number | null }>({ id: null });

  const handleRowClick1 = (id: number) => {

    setState(prevState => ({ ...prevState, id }));
    setOpen2(false);
  };

  useEffect(() => {

    if (state.id) {
      apiService.getquoteId(Number(state.id))
        .then(response => {
          const data = response.data;


          // Map the nested essentialTableRows if needed
          const mappedTableRows = data.tableRows.map(row => ({
            description: row.desp || '',
            quantity: row.qty || '0',
            idenqdet: row.idenqdet || 0,
            essentialTableRows: (row.essentialTableRows || []).map(essRow => ({
              requirements: essRow.requirements || 0,
              essdatid: essRow.essdatid || 0,
              compliance: essRow.compliance || '',
              esattach: essRow.attach || ''
            }))
          }));

          // Directly set the formData
          setFormData(prevFormData => ({
            ...prevFormData, // Spread the previous formData
            tableRows: mappedTableRows, // Set the mapped tableRows
            repeatenqid: state.id || 0, // Set repeatenqid
          }));



        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [state.id]);





  const handleMoveToFactory = () => {
    setFormData({
      ...formData,
      faddr1: formData.caddr1,
      faddr2: formData.caddr2,
      faddr3: formData.caddr3,
      faddr4: formData.caddr4,
      faddr5: formData.caddr5,
      faddr6: formData.caddr6,
      faddr7: formData.caddr7
    });
  };

  const handleCancelClick = () => {
    setFormData({
      ...formData,
      tableRows: [
        {
          description: '',
          quantity: 0,
          idenqdet: 0,
          eqipposition: "",
          printcolor: "",
          asreqfoam: "",
          foam: "",
          inputno: "",
          drawcatgid: 0,
          drawreqon: null,
          multiattach: [
            {
              filename: "",
              url: "",
              index:0
            }
          ],
          essentialTableRows: [
            {
              requirements: 0,
              essdatid: 0,
              compliance: '',
              esattach: '',
            }
          ]
        }
      ],
      updcheck: true,
      finYearId: 0,
      lenessrow: 0,
      empId: localStorage.getItem('empid'),
      typeID: 0,
      newEx: '',
      modeId: 0,
      divId: 0,
      rfxEnqNo: '',
      dueOn: null,
      statusId: '',
      desp: '',
      basicValue: 0,
      ccperson: '',
      cdesign: '',
      cmobile: '',
      cmail: '',
      tcperson: '',
      tdesign: '',
      tmobile: '',
      tmail: '',
      bankName: '',
      branchname: '',
      accNo: '',
      ifsc: '',
      repeatenqid: 0,
      isExcus: true,
      excusid: 0,
      sname: '',
      code: '',
      name: '',
      city: '',
      ceo: '',
      md: '',
      mp: '',
      caddr1: '',
      caddr2: '',
      caddr3: '',
      caddr4: '',
      caddr5: '',
      caddr6: '',
      caddr7: '',
      faddr1: '',
      faddr2: '',
      faddr3: '',
      faddr4: '',
      faddr5: '',
      faddr6: '',
      faddr7: '',
      panno: '',
      tinno: '',
      email: '',
      omobile: '',
      pmobile: '',
      gstno: '',
      nbus: '',
      ncomp: '',
      datestart: new Date(),
      cpname: '',
      cpdesign: '',
      cpmobile: '',
      cpmail: '',
      annualturnover: '',
      buildOR: '',
      followtype: 0,
      followdesc: '',
      isqul: true,
      nextfollow: new Date(),
      status: '',
      priority: 'High',
      nfteamid: '',
      nfempid: '',
      attch: string,
      divsname: '',
      divname: '',
      reqtypeid: 1,
    });

    setValue(0);
  };

  const handleDivname = (option) => {
    // setSelectedRow(row);

    setFormData(prevState => ({
      ...prevState,
      ["divname"]: option.name,

    }))
  };

  // const handleRadioChange = (index, rowId, value) => {
  //   setFormData((prevFormData) => {
  //     let updatedTestAccess = [...prevFormData.testaccess];

  //     if (value === 'Yes') {
  //       updatedTestAccess[index] = { testaccessid: rowId };
  //     } else if (value === 'No') {
  //       updatedTestAccess = updatedTestAccess.filter((_, i) => i !== index);
  //     }

  //     return {
  //       ...prevFormData,
  //       testaccess: updatedTestAccess,
  //     };
  //   });
  // };

  const handleRadioChange = (rowId, value) => {
    setFormData((prevFormData) => {
      let updatedTestAccess = [...prevFormData.testaccess];


      const entryIndex = updatedTestAccess.findIndex(entry => entry.testaccessid === rowId);

      if (value === 'Yes') {
        if (entryIndex === -1) {

          updatedTestAccess.push({ testaccessid: rowId });
        }
      } else if (value === 'No') {
        if (entryIndex !== -1) {

          updatedTestAccess.splice(entryIndex, 1);
        }
      }

      return {
        ...prevFormData,
        testaccess: updatedTestAccess,
      };
    });
  };
  const handleRemarksChange = (rowId, newValue) => {
    setFormData((prevFormData) => {
      const updatedTestAccess = prevFormData.testaccess.map((entry) =>
        entry.testaccessid === rowId ? { ...entry, remarks: newValue } : entry
      );
      return { ...prevFormData, testaccess: updatedTestAccess };
    });
  };


  useEffect(() => {
    if (customerType === 'New') {
      setFormData({
        ...formData,
        tableRows: [
          {
            description: '',
            quantity: 0,
            idenqdet: 0,
            eqipposition: "",
            printcolor: "",
            asreqfoam: "",
            foam: "",
            inputno: "",
            drawcatgid: 0,
            drawreqon: null,
            multiattach: [
              {
                filename: "",
                url: "",
                index:0
              }
            ],
            essentialTableRows: [
              {
                requirements: 0,
                essdatid: 0,
                compliance: '',
                esattach: '',
              }
            ]
          }
        ],
        updcheck: true,
        finYearId: 0,
        lenessrow: 0,
        empId: localStorage.getItem('empid'),
        modeId: 0,
        divId: 0,
        rfxEnqNo: '',
        dueOn: null,
        statusId: '',
        desp: '',
        basicValue: 0,
        ccperson: '',
        cdesign: '',
        cmobile: '',
        cmail: '',
        tcperson: '',
        tdesign: '',
        tmobile: '',
        tmail: '',
        bankName: '',
        branchname: '',
        accNo: '',
        ifsc: '',
        repeatenqid: 0,
        isExcus: true,
        excusid: 0,
        sname: '',
        code: '',
        name: '',
        city: 0,
        ceo: '',
        md: '',
        mp: '',
        caddr1: '',
        caddr2: '',
        caddr3: '',
        caddr4: '',
        caddr5: '',
        caddr6: '',
        caddr7: '',
        faddr1: '',
        faddr2: '',
        faddr3: '',
        faddr4: '',
        faddr5: '',
        faddr6: '',
        faddr7: '',
        panno: '',
        tinno: '',
        email: '',
        omobile: '',
        pmobile: '',
        gstno: '',
        nbus: '',
        ncomp: '',
        datestart: new Date(),
        cpname: '',
        cpdesign: '',
        cpmobile: '',
        cpmail: '',
        annualturnover: '',
        buildOR: '',
        followtype: 0,
        followdesc: '',
        isqul: true,
        nextfollow: new Date(),
        status: '',
        priority: 'High',
        nfteamid: '',
        nfempid: '',
        attch: string,
        divsname: '',
        divname: '',
        reqtypeid: 1,
      }); // Clear the name field
    }
  }, [customerType, setFormData]);

  useEffect(() => {
    console.log("Updated formData => ", formData);
  }, [formData]);

  const handleInputChange = (index, field, selectedRowdet, value) => {
    if (field === "url" && value instanceof File) {
      const formData = new FormData();
      formData.append("file", value);
  
      apiService
        .uploadFiles(formData)
        .then((response) => {
          const url = response.data;
  
          // Update the row with the uploaded file URL
          setFormData((prevState) => {
            const updatedTableRows = prevState.tableRows.map((row, rowIndex) => {
              if (rowIndex === selectedRowdet) {
                const updatedAttachments = row.multiattach.map((attachment, i) =>
                  i === index ? { ...attachment, [field]: url } : attachment
                );
                return { ...row, multiattach: updatedAttachments };
              }
              return row; // Other rows remain unchanged
            });
  
            return { ...prevState, tableRows: updatedTableRows };
          });
        })
        .catch((err) => {
          console.error("File upload failed:", err);
        });
    } else {
      // Handle other fields normally (e.g., filename)
      setFormData((prevState) => {
        const updatedTableRows = prevState.tableRows.map((row, rowIndex) => {
          if (rowIndex === selectedRowdet) {
            const updatedAttachments = row.multiattach.map((attachment, i) =>
              i === index ? { ...attachment, [field]: value } : attachment
            );
            return { ...row, multiattach: updatedAttachments };
          }
          return row; // Other rows remain unchanged
        });
  
        return { ...prevState, tableRows: updatedTableRows };
      });
    }
  };
  

  const handleAddAttach = (selectedRowdet: number) => {
    // Ensure selectedRowdet is valid
    if (selectedRowdet < 0 || selectedRowdet >= formData.tableRows.length) {
      console.error(`Invalid row index: ${selectedRowdet}`);
      return;
    }

    // Ensure multiattach is initialized
    const multiattach = formData.tableRows[selectedRowdet].multiattach || [];

    // Check if the last row has empty fields (both filename and url)
    const lastAttach = multiattach.at(-1);
    if (multiattach.length > 0 && (!lastAttach?.filename && !lastAttach?.url)) {
      console.warn('Last row has empty fields. Add operation aborted.');
      return;
    }

    // Create a new attachment row
    const newRow = {
      filename: '', // Initialize filename as an empty string
      url: '',      // Initialize url as an empty string
    };

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      tableRows: prevState.tableRows.map((row, idx) =>
        idx === selectedRowdet // Only update the selected row
          ? {
            ...row,
            multiattach: [...multiattach, newRow], // Add the new row
          }
          : row // Keep other rows unchanged
      ),
    }));
  };



  return (
    <>
      <Helmet>
        <title>Add New Enquiry</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Add New Enquiry"

        // docs="https://material-ui.com/components/tabs/"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg" >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Box sx={{ width: '100%' }}>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                      '& .MuiTab-root': {
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'blue',
                          color: '#fff',
                        },
                      },
                      '& .Mui-selected': {
                        backgroundColor: 'Blue',
                        color: '#fff',
                      },
                    }}
                  >
                    <Tab label="Basic Information" {...a11yProps(0)} />
                    <Tab label="Address Details" {...a11yProps(1)} />
                    <Tab label="Essential Data's" {...a11yProps(2)} />
                    <Tab label="Follow Up" {...a11yProps(2)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <form>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Card>
                            <Divider />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>
                                  <TextField
                                    required
                                    select
                                    autoFocus
                                    id="typeID"
                                    name="typeID"
                                    label="Type"
                                    value={formData.typeID}
                                    onChange={handleInputChanges}
                                    error={!!errors.typeID}
                                    helperText={errors.typeID ? "Please Select Type" : ''}
                                    inputProps={{ maxLength: 11 }}
                                  >
                                    {type.map((option) => (
                                      <MenuItem key={option?.id} value={option?.id}>
                                        {option?.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  <TextField
                                    required
                                    select
                                    id="newEx"
                                    label="Existing/New Customer"
                                    name="newEx"
                                    //autoFocus
                                    value={formData?.newEx}
                                    error={!!errors.newEx}
                                    helperText={errors.newEx ? "Please Select Ex /New/Repeat Customer" : ''}
                                    onChange={handleCustomerTypeChange}
                                    inputProps={{ maxLength: 20 }}
                                  >
                                    <MenuItem value="Ex">Ex-Customer</MenuItem>
                                    <MenuItem value="New">New-Customer</MenuItem>
                                    <MenuItem value="Repeat">Repeat-Customer</MenuItem>
                                  </TextField>

                                  {customerType === 'New' ? (

                                    <TextField
                                      required
                                      id="name"
                                      label="Customer"
                                      name='name'
                                      value={formData?.name}
                                      onChange={handleInputChanges}
                                      error={!!errors.name}
                                      helperText={errors.name ? "Please Enter Customer" : ''}
                                      inputProps={{ maxLength: 100 }}
                                    />

                                  ) : (
                                    <TextField
                                      required
                                      id="name"
                                      label="Customer"
                                      name="name"
                                      onClick={handleClickOpen}
                                      value={selectedRow ? selectedRow?.name : formData?.name}
                                      onChange={handleInputChanges}
                                      error={!!errors.name}
                                      helperText={errors.name ? "Please Enter Customer" : ''}
                                      inputProps={{ maxLength: 100 }}
                                    />

                                  )}


                                  <TextField
                                    required
                                    select
                                    id="modeId"
                                    label="Mode of Enquiry"
                                    name="modeId"
                                    value={selectedRow ? selectedRow?.modeId : formData?.modeId}
                                    onChange={handleInputChanges}
                                    error={!!errors.modeId}
                                    helperText={errors.modeId ? "Please Select Mode" : ''}
                                    inputProps={{ maxLength: 11 }}
                                  >
                                    {mode.map((option) => (
                                      <MenuItem key={option?.id} value={option?.id}>
                                        {option?.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  {formData?.newEx === 'Ex' || formData?.newEx === 'Repeat' ? (
                                    <TextField
                                      required
                                      select
                                      id="divId"
                                      label="Division"
                                      name="divId"
                                      value={selectedRow ? selectedRow?.divid : formData?.divId}
                                      onChange={handleInputChanges}
                                      inputProps={{ maxLength: 11 }}
                                      error={!!errors.divId}
                                      helperText={errors.divId ? "Please Select Division" : ''}
                                    >
                                      {division.map((option) => (
                                        <MenuItem key={option?.id} value={option?.id}>
                                          {option?.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  ) : (
                                    <TextField
                                      required
                                      id="divname"
                                      label="Division"
                                      name="divname"
                                      value={formData?.divname}
                                      onChange={handleInputChanges}
                                      inputProps={{ maxLength: 50 }}
                                      error={!!errors.modeId}
                                      helperText={errors.modeId ? "Please Enter Division" : ''}
                                    />
                                  )}
                                  {formData?.newEx === 'Ex' || formData?.newEx === 'Repeat' ? (
                                    <TextField
                                      id="code"
                                      label="Code"
                                      name="code"
                                      autoFocus
                                      value={selectedRow ? selectedRow?.code : formData?.code}
                                      onChange={handleInputChanges}
                                      InputProps={{ readOnly: true }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  {/* <TextField
                                    id="code"
                                    label="Code"
                                    name="code"
                                    autoFocus
                                    value={selectedRow ? selectedRow?.code : formData?.code}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 20 }}
                                  /> */}
                                  <TextField

                                    id="sname"
                                    label="Short Name"
                                    // autoFocus
                                    name="sname"
                                    value={selectedRow ? selectedRow?.sname : formData?.sname}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 20 }}
                                  >

                                  </TextField>
                                  <TextField
                                    select
                                    id="city"
                                    label="Place"
                                    name="city"
                                    value={selectedRow ? selectedRow?.cityid : formData?.city}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 11 }}

                                  >
                                    {city.map((option) => (
                                      <MenuItem key={option?.id} value={option?.id}>
                                        {option?.sname}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <TextField
                                    id="ceo"
                                    label="Managing Director"
                                    name="ceo"
                                    value={selectedRow ? selectedRow?.ceo : formData?.ceo}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 100 }}
                                  />
                                  <TextField
                                    required
                                    id="email"
                                    label="Email ID"
                                    name="email"
                                    value={selectedRow ? selectedRow?.email : formData?.email}
                                    onChange={handleInputChanges}
                                    error={!!errors.email}
                                    helperText={errors.email ? "Please valid Enter Email" : ''}
                                    inputProps={{ maxLength: 100 }}

                                  />

                                  {/* <TextField
                                    id="omobile"
                                    label="Official Mobile"
                                    name="omobile"
                                    value={selectedRow ? selectedRow?.omobile : formData?.omobile}
                                    onChange={handleInputChanges}
                                  /> */}
                                  <TextField
                                    required
                                    id="pmobile"
                                    label="Mobile Number"
                                    name="pmobile"
                                    value={selectedRow ? selectedRow?.pmobile : formData?.pmobile}
                                    onChange={handleInputChanges}
                                    error={!!errors.pmobile}
                                    helperText={errors.pmobile ? "Please Enter Valid Mobile No" : ''}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                  <TextField
                                    required
                                    id="gstno"
                                    label="GST No."
                                    autoFocus
                                    name="gstno"
                                    value={selectedRow ? selectedRow?.gstno : formData?.gstno}
                                    onChange={handleInputChanges}
                                    error={!!errors.gstno}
                                    helperText={errors.gstno ? 'Please enter valid Gst No' : ''}
                                    inputProps={{ maxLength: 100 }}
                                  // helperText={errors.gstno}
                                  />
                                  {/* {errors.newEx && (
                                    <span style={{ color: 'red', fontSize: '12px' }}>newEx is required</span>
                                  )} */}
                                  {/* <TextField
                                    id="nbuss"
                                    label="Nature of Bussiness"
                                    name="nbuss"
                                    value={selectedRow ? selectedRow?.nbuss : formData?.nbuss}
                                    onChange={handleInputChanges}
                                  />
                                  <TextField
                                    id="ncomp"
                                    label="Nature of Company"
                                    name="ncomp"
                                    value={selectedRow ? selectedRow?.ncomp : formData?.ncomp}
                                    onChange={handleInputChanges}
                                  />
                                  <TextField
                                    id="datestart"
                                    label="Date of Establishment"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    name="datestart"
                                    value={selectedRow ? selectedRow?.datestart : formData?.datestart}
                                    onChange={handleInputChanges}
                                  /> */}
                                  <TextField
                                    required
                                    id="panno"
                                    label="PAN No"
                                    autoFocus
                                    name="panno"
                                    value={selectedRow ? selectedRow?.panno : formData?.panno}
                                    onChange={handleInputChanges}
                                    error={!!errors.panno}
                                    helperText={errors.panno ? "Please Enter PAN No" : ''}
                                    inputProps={{ maxLength: 100 }}
                                  />

                                  <TextField
                                    id="tinno"
                                    label="TIN No"
                                    name="tinno"
                                    // autoFocus
                                    value={selectedRow ? selectedRow?.tinno : formData?.tinno}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 100 }}
                                  />
                                  {/* <TextField
                                    id="annualturnover"
                                    label="Annual Turnover"
                                    name="annualturnover"
                                    value={selectedRow ? selectedRow?.annualturnover : formData?.annualturnover}
                                    onChange={handleInputChanges}
                                  /> */}
                                  {/* <TextField
                                    select
                                    id="buildOR"
                                    label="Building"
                                    name="buildOR"
                                    value={selectedRow ? selectedRow?.buildOR : formData?.buildOR}
                                    onChange={handleInputChanges}
                                  > */}
                                  {/* <MenuItem value="Ex-Customer">Own</MenuItem>
                                    <MenuItem value="New-Customer">Rent</MenuItem>
                                  </TextField> */}
                                  <TextField
                                    id="rfxEnqNo"
                                    label="Customer Ref.No"
                                    name="rfxEnqNo"
                                    value={selectedRow ? selectedRow?.rfxEnqNo : formData?.rfxEnqNo}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 100 }}
                                  />
                                  <TextField
                                    id="dueon"
                                    label="Due On"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    name="dueOn"
                                    value={selectedRow ? selectedRow?.dueon : formData?.dueOn}
                                    onChange={handleInputChanges}
                                  />
                                </div>


                              </Box>
                            </CardContent>
                          </Card>
                        </Grid></Grid>
                      <br />

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Contact Person For Commercial" />
                            <Divider />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>
                                  <TextField
                                    required
                                    id="ccperson"
                                    label="Contact Person"
                                    name="ccperson"
                                    value={selectedRow ? selectedRow?.ccperson : formData?.ccperson}
                                    onChange={handleInputChanges}
                                    error={!!errors.ccperson}
                                    helperText={errors.ccperson ? "Please Enter Contact Person" : ''}
                                    inputProps={{ maxLength: 50 }}

                                  />

                                  <TextField
                                    required
                                    id="cdesign"
                                    label="Designation"
                                    name="cdesign"
                                    value={selectedRow ? selectedRow?.cdesign : formData?.cdesign}
                                    onChange={handleInputChanges}
                                    error={!!errors.cdesign}
                                    helperText={errors.cdesign ? "Please Enter Designation" : ''}
                                    inputProps={{ maxLength: 50 }}

                                  />

                                  <TextField
                                    required
                                    id="cmobile"
                                    label="Mobile No"
                                    name="cmobile"
                                    value={selectedRow ? selectedRow?.cmobile : formData?.cmobile}
                                    onChange={handleInputChanges}
                                    error={!!errors.cmobile}
                                    helperText={errors.cmobile ? "Please Enter valid Mobile No" : ''}

                                    inputProps={{ maxLength: 12 }}

                                  />

                                  <TextField
                                    required
                                    id="cmail"
                                    label="Email ID"
                                    name="cmail"
                                    value={selectedRow ? selectedRow?.cmail : formData?.cmail}
                                    onChange={handleInputChanges}
                                    error={!!errors.cmail}
                                    helperText={errors.cmail ? "Please Enter Email ID" : ''}
                                    inputProps={{ maxLength: 50 }}
                                  />

                                </div>


                              </Box>
                            </CardContent>
                          </Card>
                        </Grid></Grid>
                      <br />

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title=" Contact Person For Technical" />
                            <Divider />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>
                                  <TextField
                                    required
                                    id="tcperson"
                                    label="Contact Person"
                                    name="tcperson"
                                    value={selectedRow ? selectedRow?.tcperson : formData?.tcperson}
                                    onChange={handleInputChanges}
                                    error={!!errors.tcperson}
                                    helperText={errors.tcperson ? "Please Enter Contact Person" : ''}
                                    inputProps={{ maxLength: 50 }}
                                  />

                                  <TextField
                                    required
                                    id="tdesign"
                                    label="Designation"
                                    name="tdesign"
                                    value={selectedRow ? selectedRow?.tdesign : formData?.tdesign}
                                    onChange={handleInputChanges}
                                    error={!!errors.tdesign}
                                    helperText={errors.tdesign ? "Please Enter Designation" : ''}
                                    inputProps={{ maxLength: 50 }}
                                  />

                                  <TextField
                                    required
                                    id="tmobile"
                                    label="Mobile No"
                                    name="tmobile"
                                    value={selectedRow ? selectedRow?.tmobile : formData?.tmobile}
                                    onChange={handleInputChanges}
                                    error={!!errors.tmobile}
                                    helperText={errors.tmobile ? "Please Enter Valid Mobile No" : ''}
                                    inputProps={{ maxLength: 12 }}
                                  />

                                  <TextField
                                    required
                                    id="tmail"
                                    label="Email ID"
                                    name="tmail"
                                    value={selectedRow ? selectedRow?.tmail : formData?.tmail}
                                    onChange={handleInputChanges}
                                    error={!!errors.tmail}
                                    helperText={errors.tmail ? "Please Enter Email ID" : ''}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                </div>


                              </Box>
                            </CardContent>
                          </Card>
                        </Grid></Grid>
                      <br />
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Bank Information" />
                            <Divider />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>

                                  <TextField
                                    id="bankName"
                                    label="Bank Name"
                                    name="bankName"
                                    value={selectedRow ? selectedRow?.bankname : formData?.bankName}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 100 }}

                                  />
                                  <TextField
                                    id="branchname"
                                    label="Branch Name"
                                    name="branchname"
                                    value={selectedRow ? selectedRow?.branchname : formData?.branchname}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 100 }}

                                  />
                                  <TextField
                                    id="accNo"
                                    label="Account No"
                                    name="accNo"
                                    value={selectedRow ? selectedRow?.accno : formData?.accNo}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                  <TextField
                                    id="ifsc"
                                    label="IFSC Code"
                                    sx={{ height: '10ch' }}
                                    name="ifsc"
                                    value={selectedRow ? selectedRow?.ifsc : formData?.ifsc}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  />

                                </div>


                              </Box>
                              <Box display="flex" justifyContent="flex-end" p={2}><Button
                                sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}

                              >
                                Next
                              </Button></Box>

                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </form>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <form>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Contact Address" />
                            <Divider />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>

                                  <TextField
                                    id="caddr1"
                                    label="Plot No/Street"
                                    name="caddr1"
                                    value={formData?.caddr1}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                  <TextField

                                    id="caddr2"
                                    label="City"
                                    name="caddr2"
                                    value={formData?.caddr2}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  >
                                    {/* {city.map((option) => (
                                      <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                      </MenuItem>
                                    ))} */}
                                  </TextField>
                                  <TextField
                                    select
                                    id="caddr4"
                                    label="State"
                                    name="caddr4"
                                    value={formData?.caddr4}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  >
                                    {states.map((option) => (
                                      <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  <TextField
                                    select
                                    id="caddr3"
                                    label="District"
                                    name="caddr3"
                                    value={formData?.caddr3}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  >
                                    {dist.map((option) => (
                                      <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  <TextField
                                    id="caddr5"
                                    label="Pincode"
                                    name="caddr5"
                                    value={formData?.caddr5}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 6 }}
                                  />
                                  <TextField
                                    id="caddr6"
                                    label="Landline"
                                    name="caddr6"
                                    value={formData?.caddr6}
                                    onChange={handleInputChanges}
                                    error={!isCAMobileValid}
                                    helperText={!isCAMobileValid ? "Please Enter Valid Landline" : ''}
                                    inputProps={{ maxLength: 11 }}
                                  />

                                  <Button
                                    sx={{ margin: 1 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleMoveToFactory}
                                  >
                                    Copy as Factory Address
                                  </Button>

                                </div>


                              </Box>
                            </CardContent>
                          </Card>
                        </Grid></Grid>
                      <br />
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Factory Address" />
                            <Divider />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>

                                  <TextField
                                    id="faddr1"
                                    label="Plot No/Street"
                                    name="faddr1"
                                    value={formData?.faddr1}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                  <TextField
                                    id="faddr2"
                                    label="City"
                                    name="faddr2"
                                    value={formData?.faddr2}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  >
                                    {/* {city.map((option) => (
                                      <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                      </MenuItem>
                                    ))} */}
                                  </TextField>
                                  <TextField
                                    select
                                    id="faddr4"
                                    label="State"
                                    name="faddr4"
                                    value={formData?.faddr4}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  >
                                    {states1.map((option) => (
                                      <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  <TextField
                                    select
                                    id="faddr3"
                                    label="District"
                                    name="faddr3"
                                    value={formData?.faddr3}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 50 }}
                                  >
                                    {dist1.map((option) => (
                                      <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>



                                  <TextField
                                    id="faddr5"
                                    label="Pincode"
                                    name="faddr5"
                                    value={formData?.faddr5}
                                    onChange={handleInputChanges}
                                    inputProps={{ maxLength: 6 }}
                                  />
                                  <TextField
                                    id="faddr6"
                                    label="Landline"
                                    name="faddr6"
                                    value={formData?.faddr6}
                                    onChange={handleInputChanges}
                                    error={!isFAMobileValid}
                                    helperText={!isFAMobileValid ? "Please Enter Landline" : ''}
                                    inputProps={{ maxLength: 11 }}
                                  />
                                  {/* <TextField
                                    id="faddr7"
                                    label="Email"
                                    name="faddr7"
                                    value={formData?.faddr7}
                                    onChange={handleInputChanges}
                                    error={!isFAEmailValid}
                                    helperText={!isFAEmailValid ? "Please Enter Email" : ''}
                                    inputProps={{ maxLength: 45 }}
                                  /> */}

                                </div>
                              </Box>
                              <Box display="flex" justifyContent="flex-end" p={2}><Button
                                sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                              >
                                Next
                              </Button></Box>
                            </CardContent>
                          </Card>
                        </Grid>

                      </Grid>

                    </form>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <form action="#">
                          <Card>
                            <CardContent>
                              <TextField
                                select
                                label="Select Requirement Type"
                                value={selectedTab1}
                                onChange={handleTabChange2}
                                sx={{
                                  mb: '10px',
                                  '& .MuiSelect-root': {
                                    cursor: 'pointer',
                                    '&:hover': {
                                      backgroundColor: 'blue', // Changes background color on hover
                                      color: '#fff',
                                    },
                                  },
                                  '& .Mui-selected': {
                                    backgroundColor: 'blue', // Background color for selected option
                                    color: '#fff', // Keep the font color unchanged
                                  },
                                }}
                              >
                                {reqType.map((row, index) => (
                                  <MenuItem key={row.id || index} value={row.id}>
                                    {row.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </CardContent>

                          </Card>

                        </form></Grid>




                    </Grid>
                    <br />
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <form action="#">
                          <Card>
                            <CardHeader
                              title={
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                  <Typography variant="h6"><strong>Customer Requirements</strong></Typography>
                                  {customerType === 'Repeat' ? (
                                    <TextField
                                      label="Search Enquiry"
                                      onClick={handleClickOpen2} />
                                  ) : (
                                    <></>)}
                                </Box>
                              }
                            />
                            <Paper>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>S.No</TableCell>
                                      <TableCell>MPN No</TableCell>
                                      <TableCell>Description</TableCell>
                                      <TableCell>Qty</TableCell>
                                      <TableCell>  </TableCell>
                                      <TableCell>Delete</TableCell>


                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {formData?.tableRows?.map((row: any, index: number) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {/* Add MPN Field Here */}
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            id='description'
                                            value={row.description}
                                            name="description"
                                            onChange={(e) => handleTableRowsChange(index, e)}
                                            sx={{
                                              m: 1,
                                              width: '24ch',

                                            }}
                                            multiline

                                            inputProps={{ maxLength: 250 }}

                                          />

                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required
                                            id='quantity'
                                            type="number"
                                            name="quantity"
                                            value={row.quantity}
                                            onChange={(e) => handleTableRowsChange(index, e)}
                                            sx={{ m: 1, width: '24ch' }}
                                            inputProps={{ maxLength: 11 }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Button onClick={(e) => handleClickOpen1(index)} >Essential's Data</Button>
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            color="secondary"
                                            onClick={(e) => handleDeleteRow(index)}
                                          >
                                            <FontAwesomeIcon icon={faTrashCan} />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>

                                </Table>

                              </TableContainer>

                            </Paper>


                          </Card>

                        </form></Grid>
                      ` <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        p={2}
                      >
                        <Button sx={{ marginRight: 2 }} onClick={(e) => handleClickOpen3()} >Test Detail</Button>
                        <Button
                          variant='contained'
                          color="primary"
                          onClick={handleAddRow}
                          sx={{ marginRight: -6 }}
                          startIcon={<FontAwesomeIcon icon={faPlus} />}
                        >
                          Add More
                        </Button>
                      </Box>`

                      {/* <br /> */}
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                          sx={{ position: 'absolute', right: 40, bottom: 100, margin: 1 }}
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Box>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={value} index={3}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={4}
                    >
                      <Grid item xs={12}>
                        <form action="#">
                          <Card>
                            <CardHeader title="Next Step " />
                            <CardContent>
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, width: '24ch' }
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <div>
                                  <Tabs
                                    value={formData?.priority}
                                    onChange={handleTabChange}
                                    aria-label="priority tabs"
                                    variant="fullWidth"
                                    sx={{
                                      m: 1, width: '24ch',
                                      '& .MuiTab-root': {
                                        cursor: 'pointer',
                                        '&:hover': {
                                          backgroundColor: 'blue', // Changes background color on hover
                                          color: '#fff',
                                        },
                                      },
                                      '& .Mui-selected': {
                                        backgroundColor: 'Blue', // Background color for selected tab
                                        color: '#fff', // Keep the font color unchanged
                                      },
                                    }}
                                  >
                                    {priority.filter((item) => item.isactive === 1).map((item) => (
                                      <Tab
                                        key={item.id} // Assuming there's an `id` field in your priority data
                                        label={item.name} // Display the priority name
                                        value={item.name} // Set the tab value based on the name
                                        style={formData?.priority === item.name ? tabStyles[item.name.toLowerCase()] : undefined} // Use dynamic styling based on the name
                                      />
                                    ))}
                                  </Tabs>

                                  <TextField
                                    required
                                    id="followdesc"
                                    label="Follow Description"
                                    multiline
                                    name="followdesc"
                                    value={formData?.followdesc}
                                    onChange={handleInputChanges}
                                    sx={{ m: 1, width: '24ch' }}
                                    error={!!errors.followdesc}
                                    helperText={errors.followdesc ? "Please Enter Description" : ''}
                                    inputProps={{ maxLength: 250 }}
                                  />
                                  <TextField
                                    required
                                    id="nextfollow"
                                    label="Next Follow Date "
                                    type="date"
                                    name="nextfollow"
                                    value={formData?.nextfollow || ""}
                                    onChange={handleInputChanges}
                                    sx={{ m: 1, width: '24ch' }}
                                    error={!!errors.nextfollow}
                                    helperText={errors.nextfollow ? "Please Enter Next Follow Date" : ''}

                                    InputLabelProps={{ shrink: true }}

                                  />



                                  <TextField
                                    required
                                    select
                                    id="statusId"
                                    label="Status"
                                    name="statusId"
                                    value={formData?.statusId}
                                    onChange={handleInputChanges}
                                    sx={{ m: 1, width: '24ch' }}
                                    error={!!errors.statusId}
                                    helperText={errors.statusId ? "Please Select Status" : ''}
                                    inputProps={{ maxLength: 11 }}
                                  >
                                    {status
                                      .filter(option => option.islead != 1)
                                      .map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                  </TextField>

                                </div>

                              </Box>
                              <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                              />
                              <Box>
                                <Box display="flex" justifyContent="flex-end" p={2}>
                                  <Button sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }} onClick={handleSubmit} variant="contained" color="primary">
                                    Save
                                  </Button>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </form>

                      </Grid>
                    </Grid>
                    <br />
                    {/* <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <form action="#">
                          <Card>
                            <CardHeader title="Attach" />
                            <Paper>
                              <input
                                type="file"
                                onChange={handleFileUpload1}
                                value={formData?.attch}
                                style={{ margin: '10px' }}
                              />

                              <TableContainer>
                                <Table >
                                  <TableHead >
                                    <TableRow>
                                      <TableCell>File</TableCell>
                                      <TableCell>Delete</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows1.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>

                                          {row.fileName}
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            color="secondary"
                                            onClick={() => handleDeleteRow(index)}
                                          >
                                            <FontAwesomeIcon icon={faTrashCan} />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>

                          </Card>

                        </form></Grid>

                    </Grid> */}
                    <br />
                    {/* <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <form action="#">
                          <Card>
                            <CardHeader title=" Quotation History" />
                            <Paper>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>S.No</TableCell>
                                      <TableCell>Rivision No.</TableCell>
                                      <TableCell>Quote No</TableCell>
                                      <TableCell>Quote Date</TableCell>
                                      <TableCell> Status </TableCell>
                                      <TableCell> Action </TableCell>


                                    </TableRow>
                                  </TableHead>

                                </Table>

                              </TableContainer>
                            </Paper>
                          </Card>

                        </form></Grid>
                    </Grid> */}
                    <br />
                    {/* <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <form action="#">
                          <Card>
                            <CardHeader title="Follow History" />
                            <Paper>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>S.No</TableCell>
                                      <TableCell>Follow Type</TableCell>
                                      <TableCell>Follow Date</TableCell>
                                      <TableCell>	Description</TableCell>
                                      <TableCell> Priority</TableCell>
                                      <TableCell> Next Follow</TableCell>
                                    </TableRow>
                                  </TableHead>
                                </Table>
                              </TableContainer>
                            </Paper>

                          </Card>

                        </form></Grid>

                    </Grid> */}
                  </TabPanel>
                </Box>
                <Dialog className="dialog" open={open} onClose={handleClose}
                  maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                  fullWidth
                  sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                  <form>
                    <DialogTitle>Ex - Customer
                      <TextField
                        label="Search"
                        variant="outlined"
                        value={search1}
                        onChange={handleSearchChange1}

                        margin="normal"
                        sx={{ flexGrow: 1, marginLeft: '560px' }}
                      />
                    </DialogTitle>
                    <DialogContent>
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableHead" >S.No</TableCell>
                                <TableCell className="tableHead"> Code</TableCell>
                                <TableCell className="tableHead">Name</TableCell>
                                <TableCell className="tableHead">Place</TableCell>
                                <TableCell className="tableHead">Mobile	</TableCell>
                                <TableCell className="tableHead">Mail</TableCell>
                                <TableCell className="tableHead">Contact Person</TableCell>
                                <TableCell className="tableHead">Is Major</TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody >
                              {rowdata.length > 0 ? (
                                rowdata.slice().map((row, index) => (
                                  <TableRow key={row.id} onClick={() => handleRowClick(row)}>
                                    <TableCell className="tableBody">{page * rowsPerPage + index + 1}</TableCell>
                                    <TableCell className="tableBody">{row.code}</TableCell>
                                    <TableCell className="tableBody">{row.name}</TableCell>
                                    <TableCell className="tableBody">{row.city}</TableCell>
                                    <TableCell className="tableBody">{row.mobile}</TableCell>
                                    <TableCell className="tableBody">{row.email}</TableCell>
                                    <TableCell className="tableBody">{row.ccperson}</TableCell>
                                    <TableCell className="tableBody">{row.isMajor ? 'Yes' : 'No'}</TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={8} className="tableBody" align="center">
                                    No Existing / Repeating Customer
                                  </TableCell>
                                </TableRow>
                              )}

                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination

                          component="div"
                          count={rowdata.length === 0 ? 0 : rowdata[0]["count"]}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      </Paper>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      {/* <Button color="primary" >
                        {selectedItem ? 'Update' : 'Save'}
                      </Button> */}
                    </DialogActions>
                  </form>
                </Dialog>
                <Dialog className="dialog" open={open2} onClose={handleClose}
                  maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                  fullWidth
                  sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                  <form>
                    <DialogTitle
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-arround',
                        padding: '16px 24px'
                      }}
                    >
                      Ex - Customer
                      <TextField
                        label="Search"
                        variant="outlined"
                        value={search}
                        onChange={handleSearchChange}

                        margin="normal"
                        sx={{ flexGrow: 1, marginLeft: '560px' }}
                      />
                    </DialogTitle>
                    <DialogContent>
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableHead" >S.No</TableCell>
                                <TableCell className="tableHead"> Enquiry No</TableCell>
                                <TableCell className="tableHead">Enquiry Date</TableCell>
                                <TableCell className="tableHead">Customer</TableCell>
                                <TableCell className="tableHead">Status	</TableCell>


                              </TableRow>
                            </TableHead>
                            <TableBody >
                              {rowdata1.map((row, index) => (
                                <TableRow key={row.id} onClick={() => handleRowClick1(row.EnquiryId)}>
                                  <TableCell className="tableBody">{page * rowsPerPage + index + 1}</TableCell>
                                  <TableCell className="tableBody">{row.EnquiryNumber}</TableCell>
                                  <TableCell className="tableBody">{row.EnquiryDate}</TableCell>
                                  <TableCell className="tableBody">{row.CustomerName}</TableCell>
                                  <TableCell className="tableBody">{row.StatusName}</TableCell>

                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>

                    </DialogActions>
                  </form>
                </Dialog>
                <Dialog
                  className="dialog"
                  open={open1}
                  onClose={handleClose}
                  maxWidth="lg"
                  fullWidth
                  sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}
                >
                  <form>
                    <br />
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange1}
                      aria-label="essential and dns tabs"
                      sx={{
                        ml: '23px',
                        '& .MuiTab-root': {
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#f24646', // Changes background color on hover
                            color: '#fff',
                          },
                        },
                        '& .Mui-selected': {
                          backgroundColor: '#f24646', // Background color for selected tab
                          color: '#fff', // Keep the font color unchanged
                        },
                      }}
                    >
                      <Tab label="Customer Requirements" />
                      <Tab label="Drawing Requirements" />
                      <Tab label="Multi Attach" />
                    </Tabs>

                    <DialogContent>
                      {/* Content for Customer Requirements tab */}
                      {selectedTab === 0 && (
                        <>

                          {/* Small and Light Cases Table */}
                          {selectedTab1 === 2 && (
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.No</TableCell>
                                    <TableCell>SAARC Requirements</TableCell>
                                    <TableCell>Customer Compliance</TableCell>
                                    <TableCell>Attachments</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {essdata
                                    .filter((row) => row.reqtypeid === selectedTab1)
                                    .map((row, index) => (
                                      <TableRow key={row.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>
                                          <TextField
                                            id="compliance"
                                            label="Compliance"
                                            multiline
                                            name="compliance"
                                            value={formData.tableRows?.[selectedRowdet]?.essentialTableRows?.[index]?.compliance ?? ''}
                                            onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                            sx={{ m: 1, width: '24ch' }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          {row.isattach === 1 ? (
                                            <input
                                              type="file"
                                              name="esattach"
                                              onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                              style={{ margin: '10px' }}
                                            />
                                          ) : (
                                            <TableCell></TableCell>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}

                          {/* Heavy Applications Table */}
                          {selectedTab1 === 2 && (
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.No</TableCell>
                                    <TableCell>SAARC Requirements</TableCell>
                                    <TableCell>Customer Compliance</TableCell>
                                    <TableCell>Attachments</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {essdata
                                    .filter((row) => row.reqtypeid === selectedTab1) // Filter rows with reqtypeid = 2
                                    .map((row, index) => (
                                      <TableRow key={row.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>
                                          <TextField
                                            id="compliance"
                                            label="Compliance"
                                            multiline
                                            name="compliance"
                                            value={formData.tableRows?.[selectedRowdet]?.essentialTableRows?.[index]?.compliance ?? ''}
                                            onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                            sx={{ m: 1, width: '24ch' }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          {row.isattach === 1 ? (
                                            <input
                                              type="file"
                                              name="esattach"
                                              onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                              style={{ margin: '10px' }}
                                            />
                                          ) : (
                                            <TableCell></TableCell>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </>
                      )}

                      {/* Content for Drawing Requirements tab */}
                      {selectedTab === 1 && (
                        <>
                          <Box flexDirection="row" display="flex" gap={4}>
                            {/* Row 1 */}
                            <Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 9.5 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>Input Number:</strong>
                                </Typography>
                                <TextField
                                  id='inputno'
                                  name="inputno"
                                  variant="outlined"
                                  value={formData?.tableRows?.[selectedRowdet]?.inputno || ''}
                                  onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                  sx={{ width: '200px' }}
                                />
                              </Box>
                              <br />
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 6.5 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>Drawing Category:</strong>
                                </Typography>
                                <TextField
                                  select
                                  id='drawcatgid'
                                  name="drawcatgid"
                                  label="Select"
                                  value={formData?.tableRows?.[selectedRowdet]?.drawcatgid || ''}
                                  onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                  sx={{ width: '200px' }}>
                                  {drawing.map((option) => (
                                    <MenuItem key={option?.id} value={option?.id}>
                                      {option?.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                              <br />
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>Drawing Required On:</strong>
                                </Typography>
                                <TextField
                                  id='drawreqon'
                                  name="drawreqon"
                                  type="date"
                                  value={formData?.tableRows?.[selectedRowdet]?.drawreqon || ''}
                                  onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                  sx={{ width: '200px' }} />
                              </Box>
                            </Box>

                            {/* Row 2 */}
                            <Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>Equipment position:</strong>
                                </Typography>
                                <TextField
                                  select
                                  id='eqipposition'
                                  name="eqipposition"
                                  label="Select"
                                  variant="outlined"
                                  value={formData?.tableRows?.[selectedRowdet]?.eqipposition || ''}
                                  onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                  sx={{ width: '200px' }}
                                >
                                  <MenuItem value="H">Horizondal</MenuItem>
                                  <MenuItem value="V">Vertical</MenuItem>

                                </TextField>

                              </Box>
                              <br />
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>Printing Color:</strong>
                                </Typography>
                                <RadioGroup
                                  name="printcolor"
                                  value={formData?.tableRows?.[selectedRowdet]?.printcolor || ''}
                                  onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                  sx={{ display: 'flex', flexDirection: 'row', gap: -5 }} // Adjust spacing and layout
                                >
                                  <FormControlLabel value="Yellow" control={<Radio />} label="Yellow" />
                                  <FormControlLabel value="White" control={<Radio />} label="White" />
                                  <FormControlLabel value="Black" control={<Radio />} label="Black" />
                                </RadioGroup>
                              </Box>

                              <br />
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>As Req.foam:</strong>
                                </Typography>
                                <Tabs
                                  value={formData?.tableRows?.[selectedRowdet]?.asreqfoam || 'EPE'} // Bind to asreqfoam
                                  onChange={(event, newValue) => {
                                    const newTableRows = [...formData.tableRows];
                                    newTableRows[selectedRowdet].asreqfoam = newValue; // Update asreqfoam for the selected row
                                    setFormData({ ...formData, tableRows: newTableRows }); // Update the state
                                  }}
                                  aria-label="priority tabs"
                                  variant="fullWidth"
                                  sx={{
                                    m: 1,
                                    width: '24ch',
                                    '& .MuiTab-root': {
                                      cursor: 'pointer',
                                      '&:hover': {
                                        backgroundColor: 'blue',
                                        color: '#fff',
                                      },
                                    },
                                  }}
                                >
                                  <Tab label="EPE" value="EPE" />
                                  <Tab label="XLPE" value="XLPE" />
                                  <Tab label="PU" value="PU" />
                                </Tabs>
                              </Box>
                              <br />
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                  <strong>Foam:</strong>
                                </Typography>
                                <TextField
                                  id='foam'
                                  name="foam"
                                  value={formData?.tableRows?.[selectedRowdet]?.foam || ''}
                                  onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                  sx={{ width: '200px' }} />
                              </Box>
                            </Box>
                          </Box>

                        </>

                      )}
                      {selectedTab === 2 && (
                        <>

                          <div>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddAttach(selectedRowdet)}

                              >
                                Add Row
                              </Button>
                            </Box>

                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.No</TableCell>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Attachments</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {formData.tableRows[selectedRowdet]?.multiattach?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        <TextField
                                          value={row.filename}
                                          onChange={(e) =>
                                            handleInputChange(index, 'filename', selectedRowdet, e.target.value)
                                          }
                                          placeholder="Enter File Name"
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <input
                                          type="file"
                                          name="url"
                                          style={{ margin: '10px' }}
                                          onChange={(e) =>
                                            handleInputChange(index, 'url', selectedRowdet, e.target.files[0])
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>

                              </Table>
                            </TableContainer>
                          </div>

                        </>
                      )}
                    </DialogContent>


                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Close
                      </Button>

                    </DialogActions>
                  </form>
                </Dialog>
                <Dialog className="dialog" open={open3} onClose={handleClose} maxWidth="lg" fullWidth>
                  <form>
                    <DialogTitle>
                      <strong> Test Accessories</strong>
                    </DialogTitle>
                    <DialogContent>
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableHead"><strong>S.No</strong></TableCell>
                                <TableCell className="tableHead"><strong>Name</strong></TableCell>
                                <TableCell className="tableHead"><strong>Remarks</strong></TableCell>
                                <TableCell className="tableHead" width="25%"><strong>Yes / No</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            {/* <TableBody>
                              {test
                                .filter((row) => row.type === "test" && row.reqtypeid === 1)
                                .map((row, index) => {
                                  // Find the corresponding testaccess entry for the current row
                                  const testAccessEntry = formData.testaccess.find(entry => entry.testaccessid === row.id);
                                  const selectedValue = testAccessEntry ? (testAccessEntry.testaccessid !== null ? "Yes" : "No") : "No"; // Set default value if not found

                                  return (
                                    <TableRow key={row.id}>
                                      <TableCell className="tableBody">{index + 1}</TableCell>
                                      <TableCell className="tableBody">{row.name}</TableCell>
                                      <TableCell className="tableBody">
                                        <RadioGroup
                                          value={selectedValue} // Set the value of RadioGroup based on current selection
                                          onChange={(e) => handleRadioChange(index, row.id, e.target.value)} // Capture the value change
                                          sx={{ flexDirection: "row" }} // Correctly set flexDirection to row
                                        >
                                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                          <FormControlLabel value="No" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody> */}
                            <TableBody>
                              {test.filter((row) => row.type === "test" && row.isactive === 1 && row.reqtypeid === selectedTab1).length > 0 ? (
                                test
                                  .filter((row) => row.type === "test" && row.isactive === 1 && row.reqtypeid === selectedTab1)
                                  .map((row, index) => {
                                    // Find the corresponding testaccess entry for the current row
                                    const testAccessEntry = formData.testaccess.find(entry => entry.testaccessid === row.id);
                                    const selectedValue = testAccessEntry ? "Yes" : "No"; // Set default value if not found

                                    return (
                                      <TableRow key={row.id}>
                                        <TableCell className="tableBody">{index + 1}</TableCell>
                                        <TableCell className="tableBody">{row.name}</TableCell>
                                        <TableCell className="tableBody">
                                          <TextField
                                            id="outlined-basic"
                                            value={testAccessEntry?.remarks || ""} // Handle remarks properly
                                            onChange={(e) => handleRemarksChange(row.id, e.target.value)}
                                            variant="outlined"
                                            sx={{
                                              '.MuiOutlinedInput-root': {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none',
                                                },
                                              },
                                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell className="tableBody">
                                          <RadioGroup
                                            value={selectedValue} // Set the value of RadioGroup based on current selection
                                            onChange={(e) => handleRadioChange(row.id, e.target.value)} // Use rowId for tracking
                                            sx={{ flexDirection: "row" }}
                                          >
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio />} label="No" />
                                          </RadioGroup>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    No Test Accessories
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>

                          </Table>
                        </TableContainer>
                      </Paper>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">Cancel</Button>
                    </DialogActions>
                  </form>
                </Dialog>

                <Button sx={{ margin: 1 }} variant='contained' color="warning" onClick={handleCancelClick}>
                  Cancel
                </Button>

              </CardContent>
            </Card>

          </Grid>

        </Grid>

      </Container >
      <Footer />
    </>
  );
}

export default AddEnquiry;

const tabStyles = {
  hot: {
    backgroundColor: 'red',
    color: 'white',
  },
  warm: {
    backgroundColor: 'orange',
    color: 'white',
  },
  cold: {
    backgroundColor: 'green',
    color: 'white',
  },
};



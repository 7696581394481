import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridOptions,
  ValueGetterParams,
} from "ag-grid-community"; // Import proper types
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Define Row Data interface
interface RowData {
  id: number;
  name: string;
  age: number;
}

const MyGrid: React.FC = () => {
  const [rowData] = useState<RowData[]>([
    { id: 1, name: "John Doe", age: 30 },
    { id: 2, name: "Jane Smith", age: 25 },
    { id: 3, name: "Sam Brown", age: 40 },
  ]);

  // Define column definitions explicitly
  const [columnDefs] = useState<ColDef<RowData>[]>([
    { field: "id", headerName: "ID", sortable: true, filter: true },
    { field: "name", headerName: "Name", sortable: true, filter: true },
    { field: "age", headerName: "Age", sortable: true, filter: true },
  ]);

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
      />
    </div>
  );
};

export default MyGrid;

import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { ToastContainer } from "react-toastify";
import {
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Button,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    IconButton,
    MenuItem,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    Checkbox
} from '@mui/material';
import { useState, SyntheticEvent, useEffect, ChangeEvent, FormEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { number, string } from 'prop-types';
import apiService from 'src/services/api-service';
import axios from 'axios';
import { Attachment, Login } from '@mui/icons-material';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useNavigate } from 'react-router';
import { log } from 'console';
import { isTemplateExpression } from 'typescript';




interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}



function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}


interface DispatchDetail {
    qty: number;
}

interface Dispatch {
    dispatchdet: DispatchDetail[];
    dispatchid: number;
    dispatchdate: string;
}

interface QuoteDetail {
    dispatch: Dispatch[];
    desp: string;
    mpnno: string;
    qty: number;
    unitprice: number;
    taxableamt: number;
    taxid: number;
    taxamt: number;
    total: number;
    enqdetid: number;
}

interface ShipAddress {
    dispatchid: number;
    shiptypeid: number;
    gstno: string;
    pocname: string;
    mobile1: string;
    mobile2: string;
    email: string;
    add1: string;
    add2: string;
    add3: string;
    add4: string;
    add5: string;
    add6: string;
    add7: string;
}

interface File {
    docname: string;
    path: string;
}

interface FormData {
    shipaddress: ShipAddress[];
    file: File[];
    quotedet: QuoteDetail[];
    typeid: number;
    sname: string;
    name: string;
    cityid: string;
    cperson: string;
    mobile: string;
    email: string;
    gstno: string;
    pan: string;
    tinno: string;
    isexe: string | number;
    cusid: number;
    cusref: string;
    cuspocname: string;
    cuspocno: string;
    cusdesign: string;
    ismajor: number;
    add1: string;
    add2: string;
    add3: string;
    add4: string;
    add5: string;
    add6: string;
    add7: string;
    cadd1: string;
    cadd2: string;
    cadd3: string;
    cadd4: string;
    cadd5: string;
    cadd6: string;
    cadd7: string;
    ceo: string;
    isactive: number;
    cby: string;
    con: string;
    mon: string;
    mby: string;
    enqid: number;
    quoteid: number;
    cuspono: number;
    cuspodate: string;
    dispatchtypeid: number;
    deltypeid: number;
    poschdate: string;
    ourcommdate: string;
    paytermid: number;
    deltermid: number;
    pbgpsd: number;
    ld: number;
    rmclr: number;
    pdi: number;
    fai: number;
    warrantyid: number;
    daplno: number;
    currencyid: number;
    status: string;
    taxid: number;
    taxamt: number;
    total: number;
    packchangeper: number;
    packchange: number;
    freightper: number;
    freight: number;
    docchargeper: number;
    doccharge: number;
    designcostqty: number;
    designcostup: number;
    designcostamt: number;
    testchargeqty: number;
    testchargeup: number;
    testchargeamt: number;
    dummycostqty: number;
    dummycostup: number;
    dummycostamt: number;
    jigsfixqty: number;
    jigsfixup: number;
    jigsfixamt: number;
    subtotal: number;
    bid: number;
    bgstno: string;
    bpocname: string;
    bmobile1: string;
    bmobile2: string;
    bemail: string;
    badd1: string;
    badd2: string;
    badd3: string;
    badd4: string;
    badd5: string;
    badd6: string;
    badd7: string;
    divsname: string;
    divname: string;
}


interface Warrenty {
    id: number;
    name: string;
    isactive: number;
}

interface DeliveryTerm {
    id: number;
    name: string;
    isactive: number;
}

interface PayTerm {
    id: number;
    name: string;
    isactive: number;
}

interface ShipType {
    id: number;
    name: string;
    isactive: number;
}

interface Country {
    id: number;
    name: string;
    isactive: number;
}

interface State {
    id: number;
    name: string;
    countryid: number;
    isactive: number;
}

interface Dist {
    id: number;
    name: string;
    stateid: number
    isactive: number;
}

const OrderRegister: React.FC = () => {
    const [value, setValue] = useState(0);

    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('select Dispatch');
    const [selectedValue1, setSelectedValue1] = useState('0');
    const [dispatchColumns, setDispatchColumns] = useState([]);
    const [dispatchColumns1, setDispatchColumns1] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activeItem, setActiveItem] = useState<string>('Order');
    const [searchQuery, setSearchQuery] = useState('');
    const [warrenty, setWarrenty] = useState<Warrenty[]>([]);
    const [deliveryterm, setDeliveryterm] = useState<DeliveryTerm[]>([]);
    const [payterm, setPayterm] = useState<PayTerm[]>([]);
    const [shiptype, setShiptype] = useState<ShipType[]>([]);
    const [country, setCountry] = useState<Country[]>([]);
    const [state, setState] = useState<State[]>([]);
    const [dist, setDist] = useState<Dist[]>([]);
    const [state1, setState1] = useState<State[][]>([]);
    // const [dist1, setDist1] = useState<Dist[]>([]);
    const [dist1, setDist1] = useState<Dist[][]>([]);
    const [dispatchCount, setDispatchCount] = useState(1);
    const [selectedDispatchIndex, setSelectedDispatchIndex] = useState(0);
    const [billAdd, setBillAdd] = useState([]);
    const [formData, setFormData] = useState<any>(
        {
            enquiry: [
                {
                    tableRows: [
                        {
                            id: 0,
                            idenqdet: 0,
                            desp: "",
                            mpnno: null,
                            qty: 0,
                            changedet: null,
                            reasonchange: null,
                            spltestcon: null,
                            casemodel: null,
                            eqipposition: "",
                            printcolor: "",
                            asreqfoam: "",
                            foam: "",
                            inputno: "",
                            drawcatgid: 0,
                            drawreqon: ""
                        }
                    ],
                    enqfollow: [
                        {
                            id: 4,
                            fdate: "",
                            ftypeid: 1,
                            priority: "",
                            fby: 2,
                            desp: "",
                            nfdate: "",
                            statusid: 2,
                            ftypename: "",
                            fbyname: "",
                            statusname: "",
                            fdate_s: "",
                            nfdate_s: ""
                        }
                    ],
                    enqquote: null,
                    enqNo: "",
                    enqdate: "",
                    updcheck: false,
                    finYearId: 1,
                    lenessrow: null,
                    empId: 0,
                    enqID: 0,
                    typeID: 0,
                    newEx: "",
                    modeId: 0,
                    divId: 0,
                    rfxEnqNo: "",
                    dueOn: "",
                    statusId: 0,
                    desp: null,
                    basicValue: 0,
                    ccperson: "",
                    cdesign: "",
                    cmobile: "",
                    cmail: "",
                    tcperson: "",
                    tdesign: "",
                    tmobile: "",
                    tmail: "",
                    bankName: "",
                    branchname: "",
                    accNo: "",
                    ifsc: "",
                    isExcus: false,
                    excusid: 0,
                    sname: "",
                    code: "",
                    name: " ",
                    city: 0,
                    ceo: "",
                    md: null,
                    mp: null,
                    caddr1: "",
                    caddr2: "",
                    caddr3: "",
                    caddr4: " ",
                    caddr5: "",
                    caddr6: "",
                    caddr7: "",
                    faddr1: "",
                    faddr2: "",
                    faddr3: "",
                    faddr4: " ",
                    faddr5: "",
                    faddr6: "",
                    faddr7: "",
                    panno: "",
                    tinno: "",
                    email: "",
                    omobile: null,
                    pmobile: "",
                    gstno: "",
                    nbus: null,
                    ncomp: null,
                    datestart: null,
                    cpname: null,
                    cpdesign: null,
                    cpmobile: null,
                    cpmail: null,
                    annualturnover: null,
                    buildOR: null,
                    followtype: null,
                    followdesc: null,
                    isqul: null,
                    nextfollow: null,
                    priority: "",
                    nfteamid: null,
                    nfempid: null,
                    attch: null,
                    divname: "",
                    divsname: null,
                    reqtypeid: 0,
                    quoteid: 0,
                    qno: "",
                    qdate: "",
                    qval: "",
                    cusref: "",
                    taxid: 0,
                    cuspocname: "",
                    cuspocno: "",
                    cusdesign: "",
                    taxamt: "",
                    total: "",
                    appby: null,
                    appdate: null,
                    comments: null,
                    packchange: "",
                    freight: "",
                    docchargeper: "",
                    doccharge: "",
                    designcostqty: "",
                    designcostup: "",
                    designcostamt: "",
                    testchargeqty: "",
                    testchargeup: "",
                    testchargeamt: "",
                    dummycostqty: "",
                    dummycostup: "",
                    dummycostamt: "",
                    jigsfixqty: "",
                    jigsfixup: "",
                    jigsfixamt: "",
                    subtotal: "",
                    packchangeper: "",
                    freightper: "",
                    rejectreason: null,
                    quotationId: 0,
                    taxname: ''
                }
            ],
            follow: [
                {
                    id: 0,
                    fdate: "",
                    ftypei: 0,
                    riority: "Warm",
                    fby: 0,
                    desp: "",
                    nfdate: "",
                    statusid: 0,
                    ftypename: "",
                    fbyname: "",
                    statusname: "",
                    fdate_s: "",
                    nfdate_s: ""
                }
            ],
            enqdet: null,
            enqdet_essdata: null,
            quote: null,
            quotedet: [
                {
                    id: 0,
                    qid: 0,
                    desp: "",
                    mpnno: "",
                    qty: 0,
                    unitprice: 0,
                    Taxableamt: 0,
                    Taxid: 0,
                    taxamt: 0,
                    total: 0,
                    enqdetid: 0
                }
            ],
            testaccess: null
        }
    );



    const [soData, setSoData] = useState<FormData>({
        shipaddress: [
            {
                dispatchid: 0,
                shiptypeid: 0,
                gstno: "",
                pocname: "",
                mobile1: "",
                mobile2: "",
                email: "",
                add1: "",
                add2: "",
                add3: "",
                add4: "",
                add5: "",
                add6: "",
                add7: "",
            },
        ],
        file: [
            {
                docname: "",
                path: "",
            },
        ],
        quotedet: [
            {
                dispatch: [
                    {
                        dispatchdet: [{ qty: 0 }],
                        dispatchid: 0,
                        dispatchdate: "",
                    },
                ],
                desp: "",
                mpnno: "",
                qty: 0,
                unitprice: 0,
                taxableamt: 0,
                taxid: 0,
                taxamt: 0,
                total: 0,
                enqdetid: 0,
            },
        ],
        typeid: 0,
        sname: "",
        name: "",
        cityid: "",
        cperson: "",
        mobile: "",
        email: "",
        gstno: "",
        pan: "",
        tinno: "",
        isexe: "",
        cusid: 0,
        cusref: "",
        cuspocname: "",
        cuspocno: "",
        cusdesign: "",
        ismajor: 1,
        add1: "",
        add2: "",
        add3: "",
        add4: "",
        add5: "",
        add6: "",
        add7: "",
        cadd1: "",
        cadd2: "",
        cadd3: "",
        cadd4: "",
        cadd5: "",
        cadd6: "",
        cadd7: "",
        ceo: "",
        isactive: 1,
        cby: "",
        con: "",
        mon: "",
        mby: "",
        enqid: 0,
        quoteid: 0,
        cuspono: 0,
        cuspodate: "",
        dispatchtypeid: 0,
        deltypeid: 1,
        poschdate: "",
        ourcommdate: "",
        paytermid: 0,
        deltermid: 0,
        pbgpsd: 0,
        ld: 0,
        rmclr: 0,
        pdi: 0,
        fai: 0,
        warrantyid: 0,
        daplno: 0,
        currencyid: 0,
        status: "",
        taxid: 0,
        taxamt: 0,
        total: 0,
        packchangeper: 0,
        packchange: 0,
        freightper: 0,
        freight: 0,
        docchargeper: 0,
        doccharge: 0,
        designcostqty: 0,
        designcostup: 0,
        designcostamt: 0,
        testchargeqty: 0,
        testchargeup: 0,
        testchargeamt: 0,
        dummycostqty: 0,
        dummycostup: 0,
        dummycostamt: 0,
        jigsfixqty: 0,
        jigsfixup: 0,
        jigsfixamt: 0,
        subtotal: 0,
        bid: 0,
        bgstno: "",
        bpocname: "",
        bmobile1: "",
        bmobile2: "",
        bemail: "",
        badd1: "",
        badd2: "",
        badd3: "",
        badd4: "",
        badd5: "",
        badd6: "",
        badd7: "",
        divsname: "",
        divname: "",
    });


    const mapQuotedet = () => {
        if (!formData.enquiry || formData.enquiry.length === 0) {
            console.error("formData.enquiry is missing or empty.");
            return;
        }

        if (!formData.quotedet) {
            console.error("formData.quotedet is missing.");
            return;
        }

        setSoData(prev => ({
            ...prev,
            quotedet: formData.quotedet.map(item => ({
                desp: item.desp || "",
                mpnno: item.mpnno || "",
                qty: item.qty || 0,
                unitprice: item.unitprice || 0,
                taxableamt: item.taxableamt || 0,
                taxid: item.taxid || 0,
                taxamt: item.taxamt || 0,
                total: item.total || 0,
                enqdetid: item.enqdetid || 0,
                dispatch: item.dispatch?.map(d => ({
                    dispatchid: d.dispatchid || 0,
                    dispatchdate: d.dispatchdate || null,
                    dispatchdet: d.dispatchdet?.map(det => ({
                        qty: det.qty || 0,
                    })) || [],
                })) || [],
            })),
            typeid: formData.enquiry[0].typeID || 0,
            sname: formData.enquiry[0].sname || "",
            name: formData.enquiry[0].name || "",
            cityid: formData.enquiry[0].cityid || "",
            cperson: formData.enquiry[0].ccperson || "",
            mobile: formData.enquiry[0].pmobile || "",
            email: formData.enquiry[0].email || "",
            gstno: formData.enquiry[0].gstno || "",
            pan: formData.enquiry[0].panno || "",
            tinno: formData.enquiry[0].tinno || "",
            isexe: "0",
            cusid: formData.enquiry[0].excusid || 0,
            cusref: formData.enquiry[0].cusref || "",
            cuspocname: formData.enquiry[0].cuspocname || "",
            cuspocno: formData.enquiry[0].cuspocno || "",
            cusdesign: formData.enquiry[0].cusdesign || "",
            add1: formData.enquiry[0].faddr1 || "",
            add2: formData.enquiry[0].faddr2 || "",
            add3: formData.enquiry[0].faddr3 || "",
            add4: formData.enquiry[0].faddr4 || "",
            add5: formData.enquiry[0].faddr5 || "",
            add6: formData.enquiry[0].faddr6 || "",
            add7: formData.enquiry[0].faddr7 || "",
            cadd1: formData.enquiry[0].caddr1 || "",
            cadd2: formData.enquiry[0].caddr2 || "",
            cadd3: formData.enquiry[0].caddr3 || "",
            cadd4: formData.enquiry[0].caddr4 || "",
            cadd5: formData.enquiry[0].caddr5 || "",
            cadd6: formData.enquiry[0].caddr6 || "",
            cadd7: formData.enquiry[0].caddr7 || "",
            ceo: formData.enquiry[0].ceo || "",
            isactive: 1,
            cby: localStorage.getItem('empid'),
            con: "",
            mon: "",
            mby: "",
            enqid: formData.enquiry[0].enqID || 0,
            quoteid: formData.enquiry[0].quoteid || 0,
            cuspono: 0,
            cuspodate: null,
            dispatchtypeid: 0,
            deltypeid: 1,
            poschdate: null,
            ourcommdate: null,
            paytermid: 0,
            deltermid: 0,
            pbgpsd: 0,
            ld: 0,
            rmclr: 0,
            pdi: 0,
            fai: 0,
            warrantyid: 0,
            daplno: 0,
            currencyid: 0,
            status: "",
            taxid: formData.enquiry[0].taxid || 0,
            taxamt: formData.enquiry[0].taxamt || 0,
            total: formData.enquiry[0].total || 0,
            packchangeper: formData.enquiry[0].packchangeper || 0,
            packchange: formData.enquiry[0].packchange || 0,
            freightper: formData.enquiry[0].freightper || 0,
            freight: formData.enquiry[0].freight || 0,
            docchargeper: formData.enquiry[0].docchargeper || 0,
            doccharge: formData.enquiry[0].doccharge || 0,
            designcostqty: formData.enquiry[0].designcostqty || 0,
            designcostup: formData.enquiry[0].designcostup || 0,
            designcostamt: formData.enquiry[0].designcostamt || 0,
            testchargeqty: formData.enquiry[0].testchargeqty || 0,
            testchargeup: formData.enquiry[0].testchargeup || 0,
            testchargeamt: formData.enquiry[0].testchargeamt || 0,
            dummycostqty: formData.enquiry[0].dummycostqty || 0,
            dummycostup: formData.enquiry[0].dummycostup || 0,
            dummycostamt: formData.enquiry[0].dummycostamt || 0,
            jigsfixqty: formData.enquiry[0].jigsfixqty || 0,
            jigsfixup: formData.enquiry[0].jigsfixup || 0,
            jigsfixamt: formData.enquiry[0].jigsfixamt || 0,
            subtotal: formData.enquiry[0].subtotal || 0,
            bid: 0,
            bgstno: "",
            bpocname: "",
            bmobile1: "",
            bmobile2: "",
            bemail: "",
            badd1: "",
            badd2: "",
            badd3: "",
            badd4: "",
            badd5: "",
            badd6: "",
            badd7: "",
            divname: formData.enquiry[0].divname || "",
            divsname: formData.enquiry[0].divsname || "",
        }));
    };


    // useEffect(() => {
    //     mapQuotedet();
    // }, [formData.quotedet]);

    useEffect(() => {
        if (formData?.quotedet?.length > 0 && formData?.enquiry?.length > 0) {
            mapQuotedet();
        }
    }, [formData.quotedet, formData.enquiry]);





    const handleChange = (event: SyntheticEvent, newValue: number) => {

        if (soData.cusid === 0) {
            const toastmsg = "Form is invalid. Please Choose enquiry."
            errorToast(toastmsg)
            return;
        } else {
            console.log("NEXTTTTTTTTT");

            setValue(newValue);
        }

    }



    const [values, setValues] = useState(0);






    const handleRowClick = (id) => {
        console.log("id => ", id);

        apiService.sogetrow(Number(id))
            .then(response => {
                const data = response.data;
                setFormData(data)
                console.log("get row =>", data);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
        setOpen(false)

    };





    const handleMoveToFactory = () => {

    };

    const handleCancelClick = () => {
        setFormData(
            {
                enquiry: [
                    {
                        tableRows: [
                            {
                                id: 0,
                                idenqdet: 0,
                                desp: "",
                                mpnno: null,
                                qty: 0,
                                changedet: null,
                                reasonchange: null,
                                spltestcon: null,
                                casemodel: null,
                                eqipposition: "",
                                printcolor: "",
                                asreqfoam: "",
                                foam: "",
                                inputno: "",
                                drawcatgid: 0,
                                drawreqon: ""
                            }
                        ],
                        enqfollow: [
                            {
                                id: 4,
                                fdate: "",
                                ftypeid: 1,
                                priority: "",
                                fby: 2,
                                desp: "",
                                nfdate: "",
                                statusid: 2,
                                ftypename: "",
                                fbyname: "",
                                statusname: "",
                                fdate_s: "",
                                nfdate_s: ""
                            }
                        ],
                        enqquote: null,
                        enqNo: "",
                        enqdate: "",
                        updcheck: false,
                        finYearId: 1,
                        lenessrow: null,
                        empId: 0,
                        enqID: 0,
                        typeID: 0,
                        newEx: "",
                        modeId: 0,
                        divId: 0,
                        rfxEnqNo: "",
                        dueOn: "",
                        statusId: 0,
                        desp: null,
                        basicValue: 0,
                        ccperson: "",
                        cdesign: "",
                        cmobile: "",
                        cmail: "",
                        tcperson: "",
                        tdesign: "",
                        tmobile: "",
                        tmail: "",
                        bankName: "",
                        branchname: "",
                        accNo: "",
                        ifsc: "",
                        isExcus: false,
                        excusid: 0,
                        sname: "",
                        code: "",
                        name: " ",
                        city: 0,
                        ceo: "",
                        md: null,
                        mp: null,
                        caddr1: "",
                        caddr2: "",
                        caddr3: "",
                        caddr4: " ",
                        caddr5: "",
                        caddr6: "",
                        caddr7: "",
                        faddr1: "",
                        faddr2: "",
                        faddr3: "",
                        faddr4: " ",
                        faddr5: "",
                        faddr6: "",
                        faddr7: "",
                        panno: "",
                        tinno: "",
                        email: "",
                        omobile: null,
                        pmobile: "",
                        gstno: "",
                        nbus: null,
                        ncomp: null,
                        datestart: null,
                        cpname: null,
                        cpdesign: null,
                        cpmobile: null,
                        cpmail: null,
                        annualturnover: null,
                        buildOR: null,
                        followtype: null,
                        followdesc: null,
                        isqul: null,
                        nextfollow: null,
                        priority: "",
                        nfteamid: null,
                        nfempid: null,
                        attch: null,
                        divname: "",
                        divsname: null,
                        reqtypeid: 0,
                        quoteid: 0,
                        qno: "",
                        qdate: "",
                        qval: "",
                        cusref: "",
                        taxid: 0,
                        cuspocname: "",
                        cuspocno: "",
                        cusdesign: "",
                        taxamt: "",
                        total: "",
                        appby: null,
                        appdate: null,
                        comments: null,
                        packchange: "",
                        freight: "",
                        docchargeper: "",
                        doccharge: "",
                        designcostqty: "",
                        designcostup: "",
                        designcostamt: "",
                        testchargeqty: "",
                        testchargeup: "",
                        testchargeamt: "",
                        dummycostqty: "",
                        dummycostup: "",
                        dummycostamt: "",
                        jigsfixqty: "",
                        jigsfixup: "",
                        jigsfixamt: "",
                        subtotal: "",
                        packchangeper: "",
                        freightper: "",
                        rejectreason: null,
                        quotationId: 0,
                        taxname: ''
                    }
                ],
                follow: [
                    {
                        id: 0,
                        fdate: "",
                        ftypei: 0,
                        riority: "Warm",
                        fby: 0,
                        desp: "",
                        nfdate: "",
                        statusid: 0,
                        ftypename: "",
                        fbyname: "",
                        statusname: "",
                        fdate_s: "",
                        nfdate_s: ""
                    }
                ],
                enqdet: null,
                enqdet_essdata: null,
                quote: null,
                quotedet: [
                    {
                        id: 0,
                        qid: 0,
                        desp: "",
                        mpnno: "",
                        qty: 0,
                        unitprice: 0,
                        Taxableamt: 0,
                        Taxid: 0,
                        taxamt: 0,
                        total: 0,
                        enqdetid: 0
                    }
                ],
                testaccess: null
            }
        );
        
        setSoData({
            shipaddress: [
                {
                    dispatchid: 0,
                    shiptypeid: 0,
                    gstno: "",
                    pocname: "",
                    mobile1: "",
                    mobile2: "",
                    email: "",
                    add1: "",
                    add2: "",
                    add3: "",
                    add4: "",
                    add5: "",
                    add6: "",
                    add7: "",
                },
            ],
            file: [
                {
                    docname: "",
                    path: "",
                },
            ],
            quotedet: [
                {
                    dispatch: [
                        {
                            dispatchdet: [{ qty: 0 }],
                            dispatchid: 0,
                            dispatchdate: "",
                        },
                    ],
                    desp: "",
                    mpnno: "",
                    qty: 0,
                    unitprice: 0,
                    taxableamt: 0,
                    taxid: 0,
                    taxamt: 0,
                    total: 0,
                    enqdetid: 0,
                },
            ],
            typeid: 0,
            sname: "",
            name: "",
            cityid: "",
            cperson: "",
            mobile: "",
            email: "",
            gstno: "",
            pan: "",
            tinno: "",
            isexe: "",
            cusid: 0,
            cusref: "",
            cuspocname: "",
            cuspocno: "",
            cusdesign: "",
            ismajor: 1,
            add1: "",
            add2: "",
            add3: "",
            add4: "",
            add5: "",
            add6: "",
            add7: "",
            cadd1: "",
            cadd2: "",
            cadd3: "",
            cadd4: "",
            cadd5: "",
            cadd6: "",
            cadd7: "",
            ceo: "",
            isactive: 1,
            cby: "",
            con: "",
            mon: "",
            mby: "",
            enqid: 0,
            quoteid: 0,
            cuspono: 0,
            cuspodate: "",
            dispatchtypeid: 0,
            deltypeid: 1,
            poschdate: "",
            ourcommdate: "",
            paytermid: 0,
            deltermid: 0,
            pbgpsd: 0,
            ld: 0,
            rmclr: 0,
            pdi: 0,
            fai: 0,
            warrantyid: 0,
            daplno: 0,
            currencyid: 0,
            status: "",
            taxid: 0,
            taxamt: 0,
            total: 0,
            packchangeper: 0,
            packchange: 0,
            freightper: 0,
            freight: 0,
            docchargeper: 0,
            doccharge: 0,
            designcostqty: 0,
            designcostup: 0,
            designcostamt: 0,
            testchargeqty: 0,
            testchargeup: 0,
            testchargeamt: 0,
            dummycostqty: 0,
            dummycostup: 0,
            dummycostamt: 0,
            jigsfixqty: 0,
            jigsfixup: 0,
            jigsfixamt: 0,
            subtotal: 0,
            bid: 0,
            bgstno: "",
            bpocname: "",
            bmobile1: "",
            bmobile2: "",
            bemail: "",
            badd1: "",
            badd2: "",
            badd3: "",
            badd4: "",
            badd5: "",
            badd6: "",
            badd7: "",
            divsname: "",
            divname: ""
        });

        setValue(0);
        
    };



    const addRow = () => {
        // Check if both docname and path are non-empty before adding the row
        const hasEmptyFields = soData.file.some(row => row.docname === '' || row.path === '');

        if (!hasEmptyFields) {
            // Only add a new row if no row has empty docname or path
            setSoData((prevState) => ({
                ...prevState,
                file: [...prevState.file, { docname: '', path: '' }]
            }));
        } else {
            // Optionally show an alert or a message if you don't allow adding the row
            console.log("Both docname and path must be filled in before adding a new row.");
        }
    };




    // Handle file change
    // const handleFileChange = (e, index) => {
    //     const { value } = e.target;
    //     setSoData((prevState) => {
    //         const updatedFiles = [...prevState.file];
    //         updatedFiles[index].path = value;
    //         return {
    //             ...prevState,
    //             file: updatedFiles
    //         };
    //     });
    // };

    const handleFileChange = (e, index) => {
        const file = e.target.files[0]; // Get the file from the input
        const formData = new FormData();
        formData.append("file", file); // Append the file to the form data

        // Assuming apiService.uploadFile handles the file upload and returns the file URL
        apiService.uploadFiles(formData)
            .then(response => {
                console.log("Full response:", response);
                const fileUrl = response.data; // Safely access the URL from the response
                console.log("=>", fileUrl);

                // Update the soData state
                setSoData((prevState) => {
                    const updatedFiles = [...prevState.file]; // Clone the file array
                    updatedFiles[index] = { ...updatedFiles[index], path: fileUrl }; // Update the path of the specific file
                    return {
                        ...prevState,
                        file: updatedFiles, // Return the updated file array
                    };
                });
            })
            .catch((error) => {
                console.error("File upload error:", error);
                // Optionally handle error (e.g., show a message to the user)
            });
    };





    const handleSelectChange = (event) => {
        const count = parseInt(event.target.value, 10);
        setSelectedValue(event.target.value);

        // Update dispatch columns
        setDispatchColumns(Array.from({ length: count }, (_, i) => i));

        // Update dispatch array in soData
        setSoData((prev) => ({
            ...prev,
            quotedet: prev.quotedet.map((item) => ({
                ...item,
                dispatch: Array.from({ length: count }, (_, index) => ({
                    dispatchid: index + 1,
                    dispatchdate: "",
                    dispatchdet: [{ qty: 0 }],
                })),
            })),

        }));
    };




    const handleSelectChange1 = (event) => {
        setSelectedValue1(event.target.value);
    };

    useEffect(() => {
        // Create an array for the number of Dispatch columns based on the selected value
        const numOfColumns = parseInt(selectedValue, 10);
        if (!isNaN(numOfColumns) && numOfColumns > 0) {
            setDispatchColumns(Array.from({ length: numOfColumns }, (_, index) => index + 1));
        } else {
            setDispatchColumns([]);
        }
    }, [selectedValue]);
    useEffect(() => {
        if (soData.cusid > 0) {
            var id = soData.cusid

            apiService.sogetbilladdress(Number(id))
                .then(response => {
                    const data = response.data;
                    setBillAdd(data)
                    console.log("get row =>", data);
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
        }


    }, [soData.cusid]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen1 = (dispatchIndex) => {
        setSelectedDispatchIndex(dispatchIndex);
        setOpen1(true);
        // Keep track of which dispatch column is being edited
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);

    };

    useEffect(() => {


        apiService.getlist(rowsPerPage.toString(), activeItem, (page + 1).toString())
            .then(response => {
                setData(response.data);
                console.log("list=>", response.data);

            })
            .catch(err => console.error('Error fetching list data:', err));


    }, [activeItem, rowsPerPage, page]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const filtered = data.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);


    useEffect(() => {


        apiService.getDropdown("so_mas_warranty").then(response => {
            setWarrenty(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("so_mas_deliveryterm").then(response => {
            setDeliveryterm(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("so_mas_payterms").then(response => {
            setPayterm(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("so_mas_shiptype").then(response => {
            setShiptype(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("mas_country").then(response => {
            setCountry(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("mas_state").then(response => {
            setState(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("mas_district").then(response => {
            setDist(response.data);
        }).catch((err) => {
            console.log("err", err)
        });

    }, []);


    // const handleDispatchDateChange = (rowIndex, dispatchIndex, date) => {
    //     setSoData((prev) => {
    //         const updatedQuotedet = [...prev.quotedet];

    //         // Ensure the date is stored in ISO 8601 format
    //         const formattedDate = new Date(date).toISOString();

    //         updatedQuotedet[rowIndex].dispatch[dispatchIndex].dispatchdate = formattedDate;
    //         console.log("Updated Dispatch Dates:", updatedQuotedet[rowIndex].dispatch);
    //         return { ...prev, quotedet: updatedQuotedet };
    //     });
    // };

    const handleDispatchDateChange = (rowIndex, dispatchIndex, date) => {
        // Get the dispatchid of the changed dispatch to update other dispatches with the same dispatchid
        const dispatchIdToUpdate = soData.quotedet[rowIndex].dispatch[dispatchIndex].dispatchid;

        setSoData((prev) => {
            const updatedQuotedet = [...prev.quotedet];  // Clone the quotedet array to avoid mutating the original state

            // Ensure the date is stored in ISO 8601 format
            const formattedDate = new Date(date).toISOString();

            // Update the dispatch date for the specific dispatch in the given row and dispatch index
            updatedQuotedet[rowIndex].dispatch[dispatchIndex].dispatchdate = formattedDate;

            // Update all dispatches with the same dispatchid across all rows
            updatedQuotedet.forEach((row) => {
                row.dispatch.forEach((dispatch) => {
                    if (dispatch.dispatchid === dispatchIdToUpdate) {
                        dispatch.dispatchdate = formattedDate;  // Set the dispatchdate for matching dispatchid
                    }
                });
            });

            console.log("Updated Dispatch Dates:", updatedQuotedet);  // Debug log

            return { ...prev, quotedet: updatedQuotedet };  // Return updated state
        });
    };






    const handleDispatchQtyChange = (rowIndex, dispatchIndex, qty) => {
        setSoData((prev) => {
            const updatedQuotedet = [...prev.quotedet];

            // Check if dispatch and dispatchdet exist before modifying the qty
            if (updatedQuotedet[rowIndex]?.dispatch[dispatchIndex]?.dispatchdet?.[0]) {
                updatedQuotedet[rowIndex].dispatch[dispatchIndex].dispatchdet[0].qty = qty;
            } else {
                console.error('Invalid structure at rowIndex', rowIndex, 'dispatchIndex', dispatchIndex);
            }

            console.log("Updated Data => ", updatedQuotedet);
            return { ...prev, quotedet: updatedQuotedet };
        });
    };




    // const handleShipAddressChange = (dispatchIndex, field, value) => {
    //     setSoData((prev) => {
    //         const updatedShipAddress = [...prev.shipaddress]; // Create a shallow copy
    //         updatedShipAddress[dispatchIndex] = {
    //             ...updatedShipAddress[dispatchIndex], // Create a new object for the specific index
    //             [field]: value,
    //         };
    //         console.log("soData => ", updatedShipAddress);

    //         return { ...prev, shipaddress: updatedShipAddress }; // Return a new state object
    //     });
    // };

    const handleShipAddressChange = (index, field, value) => {
        setSoData(prevData => {
            const updatedShipaddress = [...prevData.shipaddress];
            updatedShipaddress[index] = {
                ...updatedShipaddress[index],
                [field]: value,
            };
            return { ...prevData, shipaddress: updatedShipaddress };
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSoData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };




    const handleSubmit = async (e: FormEvent) => {

        try {
            const dataToSubmit = soData;
            console.log("dataToSubmit", dataToSubmit);

           const response = await apiService.soinsert(dataToSubmit);

            var success = "Sale oreder Registered Successfully"
            successToast(success)
            navigate('/saleorder/saleorderlist');


        } catch (error: any) {
            errorToast(error.response)
            if (error.response) {
                // Server responded with a status other than 200 range
                console.error('Error response:', error.response);
                console.error('Error data:', error.response.data); // Log the error response data
                console.error('Error status:', error.response.status); // Log the error response status
                console.error('Error headers:', error.response.headers); // Log the error response headers
            } else if (error.request) {
                // Request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something else happened while setting up the request
                console.error('Error message:', error.message);
            }
        }
    };

    const validateAndSetQty = () => {
        let isValid = true;  // Initialize as true (valid)

        setSoData((prev) => {
            const updatedQuotedet = [...prev.quotedet];  // Clone the quotedet array

            updatedQuotedet.forEach((row, rowIndex) => {
                // Calculate the sum of dispatchdet.qty
                const totalDispatchQty = row.dispatch.reduce((sum, dispatch) => {
                    return sum + (Number(dispatch.dispatchdet[0]?.qty) || 0);
                }, 0);

                // Validate if the sum matches row.qty
                if (totalDispatchQty !== row.qty) {
                    isValid = false;  // If validation fails, set isValid to false
                    console.error(`Validation failed for row ${rowIndex + 1}: Dispatch quantity sum does not match row.qty`);
                    // Optionally show an error or prevent further actions
                } else {
                    // If valid, update qty (you can choose to update the state here if needed)
                    updatedQuotedet[rowIndex].qty = totalDispatchQty;
                }
            });

            // Return the updated state
            return { ...prev, quotedet: updatedQuotedet };
        });

        return isValid;
    };

    const handleNext1 = () => {
        if (!validateAndSetQty()) {  // If validation fails
            const toastmsg = "Please check Didpatch Quantity .";
            errorToast(toastmsg);  // Display error message
            return;
        } else {
            // If validation passes, increment the value and proceed
            setValue((prevValue) => Math.min(prevValue + 1, 4));
        }
    };
    const handleNext = () => {
        if (soData.cusid === 0) {
            const toastmsg = "Form is invalid. Please Choose enquiry."
            errorToast(toastmsg)
            return;
        }
        else {
            setValue((prevValue) => Math.min(prevValue + 1, 4));
        }


    };

    useEffect(() => {


        const caddr4ToFind = soData?.badd5;
        const selectedOption1 = country.find(option => option.name === caddr4ToFind);
        const stateid = selectedOption1 ? selectedOption1.id : null;



        // Fetch districts only if state IDs are available
        if (stateid !== null) {
            apiService.getDropdown("mas_state")
                .then(response => {
                    const filteredData = response.data.filter(item => item.countryid === stateid);
                    setState(filteredData);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }




    }, [soData?.badd5, country, state]);

    useEffect(() => {


        const finddist = soData?.badd4;
        const selectedOption = state.find(option => option.name === finddist);
        const disid = selectedOption ? selectedOption.id : null;


        if (disid !== null) {
            apiService.getDropdown("mas_district")
                .then(response => {
                    const filteredData = response.data.filter(item => item.stateid === disid);
                    setDist(filteredData);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }




    }, [soData?.badd4, state, dist]);

    // useEffect(() => {
    //     // Fetch states based on selected country (badd5)
    //     const caddr4ToFind = soData.shipaddress[0]?.add5; // Country name
    //     if (caddr4ToFind) {
    //         const selectedOption1 = country.find(option => option.name === caddr4ToFind);
    //         const stateid = selectedOption1?.id;

    //         if (stateid) {
    //             apiService
    //                 .getDropdown("mas_state")
    //                 .then(response => {
    //                     const filteredData = response.data.filter(item => item.countryid === stateid && item.isactive === 1);
    //                     setState1(filteredData);
    //                 })
    //                 .catch(err => {
    //                     console.error("Error fetching states:", err);
    //                 });
    //         } else {
    //             setState1([]); // Clear state options if no valid country is found
    //         }
    //     }
    // }, [soData.shipaddress[0]?.add5, country]);

    // useEffect(() => {
    //     // Fetch districts based on selected state (badd4)
    //     const finddist = soData.shipaddress[0]?.add4; // State name
    //     if (finddist) {
    //         const selectedOption = state1.find(option => option.name === finddist);
    //         const disid = selectedOption?.id;

    //         if (disid) {
    //             apiService
    //                 .getDropdown("mas_district")
    //                 .then(response => {
    //                     const filteredData = response.data.filter(item => item.stateid === disid && item.isactive === 1);
    //                     setDist1(filteredData);
    //                 })
    //                 .catch(err => {
    //                     console.error("Error fetching districts:", err);
    //                 });
    //         } else {
    //             setDist1([]); // Clear district options if no valid state is found
    //         }
    //     }
    // }, [soData.shipaddress[0]?.add4, state1]);

    useEffect(() => {
        // Fetch states based on selected country for all rows
        soData.shipaddress.forEach((address, index) => {
            const caddr4ToFind = address?.add5; // Country name
            if (caddr4ToFind) {
                const selectedOption1 = country.find(option => option.name === caddr4ToFind);
                const stateid = selectedOption1?.id;

                if (stateid) {
                    apiService
                        .getDropdown("mas_state")
                        .then(response => {
                            const filteredData = response.data.filter(item => item.countryid === stateid && item.isactive === 1);
                            setState1(prevState => {
                                const newState = [...prevState];
                                newState[index] = filteredData;
                                return newState;
                            });
                        })
                        .catch(err => {
                            console.error("Error fetching states:", err);
                        });
                } else {
                    setState1(prevState => {
                        const newState = [...prevState];
                        newState[index] = [];
                        return newState;
                    });
                }
            }
        });
    }, [soData.shipaddress, country]);

    useEffect(() => {
        // Fetch districts based on selected state for all rows
        soData.shipaddress.forEach((address, index) => {
            const finddist = address?.add4; // State name
            if (finddist) {
                const selectedOption = state1[index]?.find(option => option.name === finddist);
                const disid = selectedOption?.id;

                if (disid) {
                    apiService
                        .getDropdown("mas_district")
                        .then(response => {
                            const filteredData = response.data.filter(item => item.stateid === disid && item.isactive === 1);
                            setDist1(prevDist => {
                                const newDist = [...prevDist];
                                newDist[index] = filteredData;
                                return newDist;
                            });
                        })
                        .catch(err => {
                            console.error("Error fetching districts:", err);
                        });
                } else {
                    setDist1(prevDist => {
                        const newDist = [...prevDist];
                        newDist[index] = [];
                        return newDist;
                    });
                }
            }
        });
    }, [soData.shipaddress, state1]);



    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    //   const validateForm = () => {

    //     let valid = true;
    //     const newErrors: { [key: string]: string } = {};





    //     // GST number validation (India-specific format)
    //     const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9/A-Z]{1}$/;
    //     if (soData.gstno && !gstRegex.test(soData.gstno)) {
    //       newErrors.gstno = 'Invalid GST Number';
    //       valid = false;
    //     }

    //     setErrors(newErrors);
    //     return valid;
    //   };


    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const isMobileValid = (mobile: string) => {
        const mobileRegex = /^[0-9]{10,11}$/; // Example regex for 10-digit mobile numbers
        return mobileRegex.test(mobile);
    };

    const [selectedRow, setSelectedRow] = useState(null);

    const handleCheckboxChange = (event, row) => {
        if (event.target.checked) {
            // Set the selected row ID
            setSelectedRow(row.id);

            // Update the `soData` state with the selected row's address values
            setSoData((prevData) => ({
                ...prevData,
                bid: row.id,
                bgstno: row.gstno,
                bpocname: row.pocname,
                bmobile1: row.mobile1,
                bmobile2: row.mobile2,
                bemail: row.email,
                badd1: row.add1,
                badd2: row.add2,
                badd3: row.add3,
                badd4: row.add4,
                badd5: row.add5,
                badd6: row.add6,
                badd7: row.add7,
            }));

            // Close the modal or perform other actions if necessary
            setOpen2(false);
        }
    };


    return (
        <>
            <Helmet>
                <title>Sale Order Register</title>
            </Helmet>
            <PageTitleWrapper>
                <PageTitle
                    heading="Sale Order Register"
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Card>
                            <Divider />
                            <CardContent>
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        sx={{
                                            '& .MuiTab-root': {
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'blue', // Changes background color on hover
                                                    color: '#fff',
                                                },
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: 'Blue', // Background color for selected tab
                                                color: '#fff', // Keep the font color unchanged
                                            },
                                        }}
                                    >
                                        <Tab label="Basic Information" {...a11yProps(0)} />
                                        <Tab label="Scope of Supply" {...a11yProps(1)} />
                                        <Tab label="Dispatch Details" {...a11yProps(2)} />
                                        <Tab label="Address Details" {...a11yProps(3)} />
                                        <Tab label="Documents" {...a11yProps(4)} />

                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <TextField
                                            label="Choose Enquiry"
                                            variant="outlined"
                                            sx={{
                                                position: 'absolute',
                                                top: 130,
                                                right: 70,
                                            }}
                                            onClick={handleClickOpen}
                                        />

                                        <form>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Customer Detail" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        label="ENQ NO & DATE"
                                                                        value={`${formData.enquiry[0].enqNo} & ${formData.enquiry[0].enqdate}`}
                                                                        variant="outlined"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />

                                                                    <TextField
                                                                        label="QTN NO & DATE"
                                                                        value={`${formData.enquiry[0].qno} & ${formData.enquiry[0].qdate}`}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                    <TextField
                                                                        label="Customer Name"
                                                                        value={formData.enquiry[0].name}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    >

                                                                    </TextField>

                                                                    {/* <TextField
                                                                        label="Code"

                                                                    >

                                                                    </TextField> */}


                                                                    <TextField
                                                                        label="Sname"
                                                                        value={formData.enquiry[0].sname}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />



                                                                    <TextField
                                                                        label="Mobile"
                                                                        value={formData.enquiry[0].pmobile}
                                                                        InputLabelProps={{ shrink: true }}

                                                                    >

                                                                    </TextField>


                                                                    <TextField
                                                                        label="Mail"
                                                                        value={formData.enquiry[0].email}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />


                                                                    <TextField
                                                                        label="GST No"
                                                                        value={formData.enquiry[0].gstno}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />


                                                                    <TextField
                                                                        label="PAN No"
                                                                        value={formData.enquiry[0].panno}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    >

                                                                    </TextField>



                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Contact Person For Commercial" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        label="Contact Person"
                                                                        value={formData.enquiry[0].ccperson}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />

                                                                    <TextField
                                                                        label="Designation"
                                                                        value={formData.enquiry[0].cdesign}
                                                                        InputLabelProps={{ shrink: true }}


                                                                    />

                                                                    <TextField
                                                                        label="Mobile No"
                                                                        value={formData.enquiry[0].cmobile}
                                                                        InputLabelProps={{ shrink: true }}

                                                                    />

                                                                    <TextField
                                                                        label="Email"
                                                                        value={formData.enquiry[0].cmail}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />

                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title=" Terms and Conditions" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>


                                                                    <TextField
                                                                        id="cuspono"
                                                                        label="P.O. NO "
                                                                        name="cuspono"
                                                                        value={soData?.cuspono}
                                                                        onChange={handleInputChange}
                                                                    />

                                                                    <TextField
                                                                        id="cuspodate"
                                                                        label="P.O. DATE"
                                                                        name="cuspodate"
                                                                        type='date'
                                                                        value={soData?.cuspodate || null}
                                                                        onChange={handleInputChange}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                    {/* <TextField
                                                                        id="amendment"
                                                                        label="AMENDMENT"
                                                                        name="amendment"

                                                                    /> */}
                                                                    <TextField
                                                                        select
                                                                        id="deltypeid"
                                                                        label="DELIVERY TYPE"
                                                                        name="deltypeid"
                                                                        value={soData?.deltypeid}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {shiptype
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        id="poschdate"
                                                                        label="P.O.SCH. DATE"
                                                                        name="poschdate"
                                                                        type='date'
                                                                        value={soData?.poschdate || null}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        id="ourcommdate"
                                                                        label="OUR COMMITMENT DATE"
                                                                        name="ourcommdate"
                                                                        type='date'
                                                                        value={soData?.ourcommdate || null}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        select
                                                                        id="paytermid"
                                                                        label="PAYMENT TERM"
                                                                        name="paytermid"
                                                                        value={soData?.paytermid}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {payterm
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="deltermid"
                                                                        label="DELIVERY TERM"
                                                                        name="deltermid"
                                                                        value={soData?.deltermid}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {deliveryterm
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        id="gstno"
                                                                        label="IGST"
                                                                        name="gstno"
                                                                        value={soData?.gstno}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        select
                                                                        id="pbgpsd"
                                                                        label="PBG/PSD"
                                                                        name="pbgpsd"
                                                                        value={soData?.pbgpsd}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <MenuItem value='1' >Yes</MenuItem>
                                                                        <MenuItem value='0'>No</MenuItem>
                                                                    </TextField>


                                                                    <TextField
                                                                        select
                                                                        id="ld"
                                                                        label="LD"
                                                                        name="ld"
                                                                        value={soData?.ld}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <MenuItem value='1'>Yes</MenuItem>
                                                                        <MenuItem value='0'>No</MenuItem>
                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="rmclr"
                                                                        label="RM CLR"
                                                                        name="rmclr"
                                                                        value={soData?.rmclr}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <MenuItem value='1'>Yes</MenuItem>
                                                                        <MenuItem value='0'>No</MenuItem>
                                                                    </TextField>

                                                                    <TextField
                                                                        select
                                                                        id="pdi"
                                                                        label="PDI"
                                                                        name="pdi"
                                                                        value={soData?.pdi}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <MenuItem value='1'>Yes</MenuItem>
                                                                        <MenuItem value='0'>No</MenuItem>
                                                                    </TextField>

                                                                    <TextField
                                                                        select
                                                                        id="fai"
                                                                        label="FAI"
                                                                        name="fai"
                                                                        value={soData?.fai}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <MenuItem value='1'>Yes</MenuItem>
                                                                        <MenuItem value='0'>No</MenuItem>
                                                                    </TextField>

                                                                    <TextField
                                                                        select
                                                                        id="warrantyid"
                                                                        label="WARRANTY"
                                                                        name="warrantyid"
                                                                        value={soData?.warrantyid}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {warrenty
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        id="daplno"
                                                                        label="DAPL NO"
                                                                        type="number"
                                                                        name="daplno"
                                                                        value={soData?.daplno}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>

                                                                <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                    sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                >
                                                                    Next
                                                                </Button></Box>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                    <ToastContainer
                                                        position="top-right"
                                                        autoClose={5000}
                                                        hideProgressBar={false}
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        pauseOnHover
                                                        theme="light"
                                                    />
                                                </Grid></Grid>

                                        </form>
                                    </TabPanel>

                                    <TabPanel value={value} index={1}>
                                        <Grid item xs={12}>
                                            <Card>
                                                <Divider />
                                                <CardContent>
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <div
                                                            className="print-table"
                                                            style={{
                                                                width: '100%',

                                                                boxSizing: 'border-box',
                                                                overflowX: 'hidden',
                                                                overflowY: 'hidden',
                                                                marginTop: '4%'
                                                            }}
                                                        >
                                                            <table style={{ tableLayout: 'fixed', width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Sl. No.</th>
                                                                        <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '30%' }}>Description</th>
                                                                        <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>SAARC MPN No</th>
                                                                        <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '24%' }}>Quantity</th>
                                                                        <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Unit Price</th>

                                                                        <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Taxable Amount</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {formData?.quotedet?.map((row, index) => (
                                                                        <tr key={row.id} style={{ height: '30px' }}>
                                                                            <td align="center" style={{ fontSize: 11 }}>{index + 1}</td> {/* Sl. No. */}
                                                                            <td align="left" style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 11 }}>
                                                                                {row.desp || '-'}
                                                                            </td> {/* Description */}
                                                                            <td align="center" style={{ fontSize: 11 }}>
                                                                                {row.mpnno || '-'}
                                                                            </td> {/* SAARC MPN No */}
                                                                            <td align="center" style={{ fontSize: 11 }}>
                                                                                {row.qty}
                                                                            </td> {/* Quantity */}
                                                                            <td align="center" style={{ fontSize: 11 }}>
                                                                                ₹{row.unitprice.toFixed(2)}
                                                                            </td> {/* Unit Price */}
                                                                            <td align="center" style={{ fontSize: 11 }}>
                                                                                ₹{row.Taxableamt.toFixed(2)}
                                                                            </td> {/* Taxable Amount */}
                                                                        </tr>
                                                                    ))}

                                                                    <tr>
                                                                        <td colSpan={5} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>SUB-TOTAL AMOUNT:</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>₹ {formData?.enquiry[0]?.qval}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Packing Charges (from Basic Price)</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.packchangeper} {"%"}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            ₹ {formData?.enquiry[0]?.packchange}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Freight Charges (from Basic Price)</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.freightper} {"%"}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹ {formData?.enquiry[0]?.freight}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Documentation Charges (from Basic Price)</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.docchargeper} {"%"}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹  {formData?.enquiry[0]?.doccharge}

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Design & Development Cost</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.designcostqty}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹  {formData?.enquiry[0]?.designcostup}

                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            ₹  {formData?.enquiry[0]?.designcostamt}


                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Testing Charges</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.testchargeqty}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹  {formData?.enquiry[0]?.testchargeup}

                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹  {formData?.enquiry[0]?.testchargeamt}

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Dummmy Equipment Cost</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.dummycostqty}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹  {formData?.enquiry[0]?.dummycostup}

                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹  {formData?.enquiry[0]?.dummycostamt}


                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Mfg. of Jigs & Fixtures</td>
                                                                        <td align="center" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.jigsfixqty}
                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹   {formData?.enquiry[0]?.jigsfixup}

                                                                        </td>
                                                                        <td align="center" style={{ fontSize: 11 }}>

                                                                            ₹   {formData?.enquiry[0]?.jigsfixamt}

                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td colSpan={4} rowSpan={3} style={{ fontSize: 11 }}>
                                                                            <h4 >Note:</h4>
                                                                            <ul>
                                                                                <li>Actual Deliverables based on mutual discussion</li>
                                                                            </ul>
                                                                        </td>
                                                                        <td style={{ fontSize: 11 }}>
                                                                            TOTAL
                                                                        </td>
                                                                        <td align="right" style={{ fontSize: 11 }}>₹   {formData?.enquiry[0]?.subtotal}</td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td align="right" style={{ fontSize: 11 }}>
                                                                            {formData?.enquiry[0]?.taxname} </td>
                                                                        <td align="right" style={{ fontSize: 11 }}>₹ {formData?.enquiry[0]?.taxamt}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td align="right" style={{ fontSize: 11 }}>
                                                                            TOTAL
                                                                        </td>
                                                                        <td align="right" style={{ fontSize: 11 }}>₹ {formData?.enquiry[0]?.total}</td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                            sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                        >
                                                            Next
                                                        </Button></Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        <Grid item xs={12}>
                                            <form action="#">
                                                <Card>
                                                    <CardHeader />
                                                    <Box gap={4} display="flex" flexDirection="row">

                                                        <TextField label="select Delivery Type"
                                                            select value={selectedValue1}
                                                            onChange={handleSelectChange1}
                                                        >
                                                            <MenuItem value="0">Domestic</MenuItem>
                                                            <MenuItem value="1">Export</MenuItem>

                                                        </TextField>
                                                        <TextField label="Dispatch Count" select value={selectedValue} onChange={handleSelectChange}>
                                                            <MenuItem value="select Dispatch">select Dispatch</MenuItem>
                                                            <MenuItem value="1">Dispatch 1</MenuItem>
                                                            <MenuItem value="2">Dispatch 2</MenuItem>
                                                            <MenuItem value="3">Dispatch 3</MenuItem>
                                                        </TextField>
                                                    </Box>

                                                    <br />
                                                    <Paper>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>S.No</TableCell>
                                                                        <TableCell>Description</TableCell>
                                                                        <TableCell>Qty</TableCell>
                                                                        {dispatchColumns.map((_, index) => (
                                                                            <TableCell key={index}>Dispatch {index + 1}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {soData.quotedet.map((row, rowIndex) => (
                                                                        <TableRow key={rowIndex}>
                                                                            <TableCell>{rowIndex + 1}</TableCell>
                                                                            <TableCell>{row.desp}</TableCell>
                                                                            <TableCell>{row.qty}</TableCell>

                                                                            {/* Dispatch columns and input */}
                                                                            {dispatchColumns.map((_, dispatchIndex) => (
                                                                                <TableCell key={dispatchIndex}>
                                                                                    <TextField
                                                                                        type="number"
                                                                                        value={row.dispatch[dispatchIndex]?.dispatchdet[0]?.qty || 0}
                                                                                        onChange={(e) => handleDispatchQtyChange(rowIndex, dispatchIndex, e.target.value)}
                                                                                    // Disable logic applied here
                                                                                    />
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    ))}
                                                                    <TableRow>
                                                                        <TableCell colSpan={3}>Dispatch Date</TableCell>
                                                                        {dispatchColumns.map((_, dispatchIndex) => (
                                                                            <TableCell key={dispatchIndex}>
                                                                                <TextField
                                                                                    type="date"
                                                                                    value={
                                                                                        soData.quotedet[0]?.dispatch[dispatchIndex]?.dispatchdate
                                                                                            ? soData.quotedet[0].dispatch[dispatchIndex].dispatchdate.slice(0, 10) // Extract yyyy-MM-dd
                                                                                            : null
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDispatchDateChange(0, dispatchIndex, e.target.value)
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                    <TableRow>

                                                                        <TableCell colSpan={3}>Dispatch Address</TableCell>

                                                                        {dispatchColumns.map((_, index) => (
                                                                            <TableCell key={index}><TextField onClick={() => handleClickOpen1(index)} /></TableCell>
                                                                        ))}
                                                                    </TableRow>

                                                                </TableBody>

                                                            </Table>
                                                        </TableContainer>
                                                    </Paper>
                                                    <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                        sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext1}
                                                    >
                                                        Next
                                                    </Button></Box>
                                                    <ToastContainer
                                                        position="top-right"
                                                        autoClose={5000}
                                                        hideProgressBar={false}
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        pauseOnHover
                                                        theme="light"
                                                    />
                                                </Card>
                                            </form></Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>

                                        <form>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Billing Address"
                                                            action={
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => handleClickOpen2()}
                                                                >
                                                                    Choose Address
                                                                </Button>
                                                            } />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        label="POC NAME"
                                                                        name="bpocname"  // name matches the key in soData
                                                                        value={soData?.bpocname || ""}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        label="Plot No"
                                                                        name="badd1"  // name matches the key in soData
                                                                        value={soData?.badd1 || ""}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        label="City"
                                                                        name="badd2"  // name matches the key in soData
                                                                        value={soData?.badd2 || ""}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        select
                                                                        label="Country"
                                                                        name="badd5"  // name matches the key in soData
                                                                        value={soData?.badd5 || ""}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {country
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.name}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        label="State"
                                                                        name="badd4"  // name matches the key in soData
                                                                        value={soData?.badd4 || ""}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {state
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.name}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        label="District"
                                                                        name="badd3"  // name matches the key in soData
                                                                        value={soData?.badd3 || ""}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {dist
                                                                            .filter((item) => item.isactive === 1)
                                                                            .map((item) => (
                                                                                <MenuItem key={item.id} value={item.name}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        label="ZipCode"
                                                                        name="badd6"  // name matches the key in soData
                                                                        value={soData?.badd6 || ""}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        label="Gst No"
                                                                        name="bgstno"  // name matches the key in soData
                                                                        value={soData?.bgstno || ""}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <TextField
                                                                        label="Contact No 1"
                                                                        name="bmobile1"
                                                                        value={soData?.bmobile1 || ""}
                                                                        onChange={(e) => {
                                                                            const input = e.target.value;
                                                                            if (/^\d{0,10}$/.test(input)) { // Allow only numeric values and max 10 digits
                                                                                handleInputChange(e);
                                                                            }
                                                                        }}
                                                                    // error={!isMobileValid(soData?.bmobile1 || "")} // Highlight field if invalid
                                                                    // helperText={
                                                                    //     !isMobileValid(soData?.bmobile1 || "") && soData?.bmobile1
                                                                    //         ? "Invalid mobile number (10-11 digits required)"
                                                                    //         : ""
                                                                    // }
                                                                    />
                                                                    <TextField
                                                                        label="Contact No 2"
                                                                        name="bmobile2"
                                                                        value={soData?.bmobile2 || ""}
                                                                        onChange={(e) => {
                                                                            const input = e.target.value;
                                                                            if (/^\d{0,10}$/.test(input)) { // Allow only numeric values and max 10 digits
                                                                                handleInputChange(e);
                                                                            }
                                                                        }}
                                                                    // error={!isMobileValid(soData?.bmobile2 || "")} // Highlight field if invalid
                                                                    // helperText={
                                                                    //     !isMobileValid(soData?.bmobile2 || "") && soData?.bmobile2
                                                                    //         ? "Invalid mobile number (10-11 digits required)"
                                                                    //         : ""
                                                                    // }
                                                                    />
                                                                    <TextField
                                                                        label="Email Id"
                                                                        name="badd7"
                                                                        value={soData?.badd7 || ""}
                                                                        onChange={handleInputChange}
                                                                    // error={!isEmailValid(soData?.badd7 || "")} // Highlight field if invalid
                                                                    // helperText={
                                                                    //     !isEmailValid(soData?.badd7 || "") && soData?.badd7
                                                                    //         ? "Invalid email format"
                                                                    //         : ""
                                                                    // }
                                                                    />

                                                                    {/* //   <Button >CHOOSE ADDRESS</Button> */}
                                                                </div>
                                                                <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                    sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                >
                                                                    Next
                                                                </Button></Box>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                        </form>
                                    </TabPanel>

                                    <TabPanel value={value} index={4}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <Paper>

                                                    <div>
                                                        <Box display="flex" justifyContent="flex-end" p={2}>
                                                            <Button onClick={addRow} variant="contained" color="primary">
                                                                Add Row
                                                            </Button>
                                                        </Box>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>S.No</TableCell>
                                                                        <TableCell>File</TableCell>
                                                                        <TableCell>Path</TableCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                <TableBody>
                                                                    {soData?.file.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{index + 1}</TableCell>
                                                                            <TableCell>
                                                                                <TextField
                                                                                    value={row.docname}
                                                                                    onChange={(e) => {
                                                                                        const updatedDocname = e.target.value;
                                                                                        setSoData((prevState) => {
                                                                                            const updatedFiles = [...prevState.file];
                                                                                            updatedFiles[index].docname = updatedDocname;
                                                                                            return { ...prevState, file: updatedFiles };
                                                                                        });
                                                                                    }}
                                                                                    sx={{
                                                                                        '& .MuiOutlinedInput-root': {
                                                                                            '& fieldset': {
                                                                                                border: 'none', // Remove the border
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <TextField
                                                                                    type="file"
                                                                                    onChange={(e) => handleFileChange(e, index)}
                                                                                    sx={{
                                                                                        '& .MuiOutlinedInput-root': {
                                                                                            '& fieldset': {
                                                                                                border: 'none', // Remove the border
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    </div>

                                                </Paper>
                                                <Box>
                                                    <Box display="flex" justifyContent="flex-end" p={2}>
                                                        <Button
                                                            sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                            onClick={handleSubmit}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <ToastContainer
                                                    position="top-right"
                                                    autoClose={5000}
                                                    hideProgressBar={false}
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    pauseOnFocusLoss
                                                    draggable
                                                    pauseOnHover
                                                    theme="light"
                                                />
                                            </Grid>
                                        </Grid>
                                        <br />

                                    </TabPanel>

                                </Box>

                                <Button sx={{ margin: 1 }} variant='contained' color="warning" onClick={handleCancelClick}>
                                    Cancel
                                </Button>
                                {/* onClick={handleCancelClick} */}

                            </CardContent>
                        </Card>

                    </Grid>
                    <Dialog className="dialog" open={open} onClose={handleClose}
                        maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                        fullWidth
                        sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                        <form>
                            <DialogTitle
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-arround',
                                    padding: '16px 24px'
                                }}
                            >
                                Sale Order - Customer
                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    // value={search}
                                    // onChange={handleSearchChange}

                                    margin="normal"
                                    sx={{ flexGrow: 1, marginLeft: '560px' }}
                                />
                            </DialogTitle>
                            <DialogContent>
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="tableHead">S.No</TableCell>
                                                    <TableCell className="tableHead">Enquiry No</TableCell>
                                                    <TableCell className="tableHead">Date</TableCell>
                                                    <TableCell className="tableHead">Type</TableCell>
                                                    <TableCell className="tableHead">Customer</TableCell>
                                                    <TableCell className="tableHead">MKTG. Officer</TableCell>
                                                    <TableCell className="tableHead">Last Follow</TableCell>
                                                    <TableCell className="tableHead">Next Follow</TableCell>
                                                    <TableCell className="tableHead">Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell className="tableBody">{index + 1}</TableCell>
                                                        <TableCell style={{ color: '#00184a' }} className="tableBody"> <Button onClick={() => handleRowClick(row.id)}
                                                        >{row.enqno}</Button></TableCell>
                                                        <TableCell className="tableBody">{row.enqdate}</ TableCell>
                                                        <TableCell className="tableBody">{row.type}</TableCell>
                                                        <TableCell className="tableBody">{row.cusname}</TableCell>
                                                        <TableCell className="tableBody">{row.mktname}</TableCell>
                                                        <TableCell className="tableBody">{row.lastfdate}</TableCell>
                                                        <TableCell className="tableBody">{row.nffdate}</TableCell>
                                                        <TableCell className="tableBody">{row.status}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={filteredData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>

                            </DialogActions>
                        </form>
                    </Dialog>


                    <Dialog className="dialog" open={open1} onClose={handleClose}
                        maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                        fullWidth
                        sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                        <form>
                            <DialogTitle
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-arround',
                                    padding: '16px 24px'
                                }}
                            >
                                Sale Order - Address

                            </DialogTitle>
                            <DialogContent>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title={`Shipping Address for Dispatch ${selectedDispatchIndex + 1}`} />
                                        <Divider />
                                        <CardContent>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '24ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div>
                                                    <TextField
                                                        select
                                                        label="Delivery Type"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.shiptypeid || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "shiptypeid", e.target.value)
                                                        }
                                                    >
                                                        {shiptype
                                                            .filter((item) => item.isactive === 1)
                                                            .map((item) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    <TextField
                                                        label="POC NAME"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.pocname || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "pocname", e.target.value)
                                                        }
                                                    />
                                                    <TextField
                                                        label="Plot No"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add1 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add1", e.target.value)
                                                        }
                                                    />
                                                    <TextField
                                                        label="City"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add2 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add2", e.target.value)
                                                        }
                                                    />
                                                    <TextField
                                                        select
                                                        label="Country"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add5 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add5", e.target.value)
                                                        }
                                                    >
                                                        {country
                                                            .filter((item) => item.isactive === 1)
                                                            .map((item) => (
                                                                <MenuItem key={item.id} value={item.name}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    {/* <TextField
                                                        select
                                                        label="State"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add4 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add4", e.target.value)
                                                        }
                                                    >
                                                        {state1
                                                            .filter((item) => item.isactive === 1)
                                                            .map((item) => (
                                                                <MenuItem key={item.id} value={item.name}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    <TextField
                                                        select
                                                        label="District"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add3 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add3", e.target.value)
                                                        }
                                                    >
                                                        {dist1
                                                            .filter((item) => item.isactive === 1)
                                                            .map((item) => (
                                                                <MenuItem key={item.id} value={item.name}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField> */}
                                                    <TextField
                                                        select
                                                        label="State"
                                                        value={soData.shipaddress[selectedDispatchIndex]?.add4 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add4", e.target.value)
                                                        }
                                                    >
                                                        {(state1[selectedDispatchIndex] || [])
                                                            .filter(item => item.isactive === 1)
                                                            .map(item => (
                                                                <MenuItem key={item.id} value={item.name}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    <TextField
                                                        select
                                                        label="District"
                                                        value={soData.shipaddress[selectedDispatchIndex]?.add3 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add3", e.target.value)
                                                        }
                                                    >
                                                        {(dist1[selectedDispatchIndex] || [])
                                                            .filter(item => item.isactive === 1)
                                                            .map(item => (
                                                                <MenuItem key={item.id} value={item.name}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    <TextField
                                                        label="ZipCode"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add6 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add6", e.target.value)
                                                        }
                                                    />
                                                    <TextField
                                                        label="Gst No"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.gstno || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "gstno", e.target.value)
                                                        }
                                                    />
                                                    <TextField
                                                        label="Contact No 1"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.mobile1 || ""}
                                                        // onChange={(e) =>
                                                        //     handleShipAddressChange(selectedDispatchIndex, "mobile1", e.target.value)
                                                        // }
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            if (/^\d{0,10}$/.test(input)) { // Allow only numeric values and max 10 digits
                                                                handleShipAddressChange(selectedDispatchIndex, "mobile1", e.target.value)
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Contact No 2"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.mobile2 || ""}
                                                        // onChange={(e) =>
                                                        //     handleShipAddressChange(selectedDispatchIndex, "mobile2", e.target.value)
                                                        // }
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            if (/^\d{0,10}$/.test(input)) { // Allow only numeric values and max 10 digits
                                                                handleShipAddressChange(selectedDispatchIndex, "mobile2", e.target.value)
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Email Id"
                                                        value={soData?.shipaddress[selectedDispatchIndex]?.add7 || ""}
                                                        onChange={(e) =>
                                                            handleShipAddressChange(selectedDispatchIndex, "add7", e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>

                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog className="dialog" open={open2} onClose={handleClose}
                        maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                        fullWidth
                        sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                        <form>
                            <DialogTitle
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-arround',
                                    padding: '16px 24px'
                                }}
                            >
                                <strong>Sale Order - Customer Adress</strong>
                                {/* <TextField
                                    label="Search"
                                    variant="outlined"
                                    // value={search}
                                    // onChange={handleSearchChange}

                                    margin="normal"
                                    sx={{ flexGrow: 1, marginLeft: '560px' }}
                                /> */}
                            </DialogTitle>
                            <DialogContent>
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="tableHead">S.No</TableCell>
                                                    <TableCell className="tableHead">Address</TableCell>
                                                    <TableCell className="tableHead">Select Address</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {billAdd.length === 0 ? ( // Check if the billAdd array is empty
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center" style={{ fontStyle: 'italic' }}>
                                                            No Address Available
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    billAdd
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell className="tableBody">{index + 1}</TableCell>

                                                                <TableCell className="tableBody">
                                                                    {row.pocname || row.add1 || row.add2 || row.add3 || row.add4 || row.add5 || row.add6 || row.add7 || row.gstno || row.mobile1 || row.mobile2 ? (
                                                                        <>
                                                                            <strong>{row.pocname},</strong> <br />
                                                                            {row.add1}, {row.add2}, <br />
                                                                            {row.add3}, {row.add4}, {row.add5}, <br />
                                                                            {row.add6}, {row.add7}.<br />
                                                                            <strong>GST No :</strong> {row.gstno},<br />
                                                                            <strong>Contact No :</strong> {row.mobile1} / {row.mobile2}
                                                                        </>
                                                                    ) : (
                                                                        <span style={{ fontStyle: 'italic' }}>No Address Available</span> // Fallback if all fields are empty
                                                                    )}
                                                                </TableCell>
                                                                <TableCell className="tick" style={{ textAlign: 'center', padding: 0 }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedRow === row.id} // Mark as checked if the current row is selected
                                                                        onChange={(e) => handleCheckboxChange(e, row)} // Handle the change
                                                                        className="styled-checkbox"
                                                                    />

                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                )}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={filteredData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>

                            </DialogActions>
                        </form>
                    </Dialog>
                </Grid>

            </Container >
            <Footer />
        </>
    );
}

export default OrderRegister;

const tabStyles = {
    hot: {
        backgroundColor: 'red',
        color: 'white',
    },
    warm: {
        backgroundColor: 'orange',
        color: 'white',
    },
    cold: {
        backgroundColor: 'green',
        color: 'white',
    },
};



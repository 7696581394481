import React, { useEffect, useRef, useState } from "react";
import "./SalarySheet.css";
import { Box, Button, Card, CardContent, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import apiService from "src/services/api-service";
import { FaPencil } from "react-icons/fa6";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { errorToast, successToast } from "src/layouts/utile/toast";
import { useReactToPrint } from 'react-to-print';
import './printcss.css';
import { id } from "date-fns/locale";


interface Salary {
    id: number;
    name: string;
    isactive: number;
}
const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
];
const currentYear = new Date().getFullYear();
const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0'); // Adds leading zero if needed

const MusterReport: React.FC = () => {

    const [formData, setFormData] = useState([]);


    const [formData2, setFormData2] = useState<any>({
        id: '',
        sname: '',
        name: '',
        isactive: '',
        districtid: '',
        year: currentYear,
        month: currentMonth
    });
    const [salary, setSalary] = useState<Salary[]>([]);
    const [configid, setConfigid] = useState(1);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [data, setData] = useState<string>('enqdate');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(150);
    const [search1, setSearch1] = useState('');
    const tableRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });
    const handleSearchChange1 = (event) => {
        setSearch1(event.target.value);

    };

    useEffect(() => {
        // Fetch data from an GET API
        console.log(rowsPerPage.toString(), "size");
        console.log((page + 1).toString(), "page");

        apiService.musterreport(rowsPerPage.toString(), (page + 1).toString(), search1, formData2.year, formData2.month).then(response => {
            const data = response.data;
            console.log("Fetched data:", data);

            if (data.length > 0) {
                // Extract headers, excluding those with only null or undefined values
                const headers = Object.keys(data[0]).filter((key) =>
                    data.some((row) => row[key] !== null && row[key] !== undefined)
                );
                setTableHeaders(headers);
            }

            setFormData(data);
        }).catch((err) => {
            console.log("err", err)
        })


    }, [rowsPerPage, page, formData, search1, formData2]);

    useEffect(() => {
        console.log("=>", formData);
        console.log("=>", data);
    }, [configid, formData, data]);

    const handleExportToExcel = () => {
        if (!tableHeaders.length || !formData.length) {
            console.error("No data available for export");
            return;
        }

        // Adding the table title
        const title = [["MusterReport"]]; // Ensure `data` is wrapped in an array for a proper row
        const blankRow = [];

        // Map table headers for Excel
        const headers = ['S.No', ...tableHeaders];

        // Prepare rows for Excel, starting with S.No and the corresponding data
        const dataRows = formData.map((row, index) => {
            const rowData = { 'S.No': index + 1 };
            tableHeaders.forEach((header) => {
                rowData[header] = row[header] ?? "";
            });
            return rowData;
        });

        // Create a new worksheet and add the title, blank row, and headers
        const ws = XLSX.utils.json_to_sheet([]);

        // Adding title to the worksheet
        XLSX.utils.sheet_add_aoa(ws, title, { origin: 'A1' });

        // Adding a blank row
        XLSX.utils.sheet_add_aoa(ws, [blankRow], { origin: 'A2' });

        // Adding headers
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A3' });

        // Adding data rows starting from row 4
        XLSX.utils.sheet_add_json(ws, dataRows, { skipHeader: true, origin: 'A4' });

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SalaryDetail"); // Set the worksheet name using `data`

        // Save the file with the worksheet name as the filename
        const fileName = "SalaryDetail.xlsx"; // Dynamically create the file name
        XLSX.writeFile(wb, fileName);
    };

    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData2((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    return (
        <>
            <PageTitleWrapper>
                <PageTitle
                    heading="Muster Roll Report"
                    subHeading=""
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <form action="#">
                            <Card>
                                {/* Form Input Fields */}
                                <CardContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '25ch' }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                select
                                                margin="dense"
                                                label="Year"
                                                name='year'
                                                fullWidth
                                                value={formData2?.year === '' ? '' : Number(formData2?.year)}
                                                onChange={handleChange}
                                                error={!!errors?.year} // Highlight the field if there's an error
                                                helperText={errors?.year ? "Status is required" : ""}
                                            >
                                                <MenuItem value="2024">2024</MenuItem>
                                                <MenuItem value="2025">2025</MenuItem>
                                            </TextField>
                                            <TextField
                                                select
                                                margin="dense"
                                                label="Month"
                                                name='month'
                                                fullWidth
                                                value={formData2?.month === '' ? '' : Number(formData2?.month)}
                                                onChange={handleChange}
                                                error={!!errors?.month} // Highlight the field if there's an error
                                                helperText={errors?.month ? "Status is required" : ""}
                                            >
                                                {months.map((month) => (
                                                    <MenuItem key={month.value} value={month.value}>
                                                        {month.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            <TextField
                                                label="Search"
                                                variant="outlined"
                                                value={search1}
                                                onChange={handleSearchChange1}

                                                margin="normal"
                                            // sx={{ flexGrow: 1}}
                                            />
                                            <Button
                                                variant="contained"
                                                color="success"
                                                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                                                onClick={handleExportToExcel}
                                                sx={{
                                                    m: 1,
                                                    width: '10ch',
                                                    '& .MuiInputBase-root': {
                                                        height: '5%'  // Adjust this value as needed
                                                    }
                                                }}
                                            >
                                                Excel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    m: 1,
                                                    width: '10ch',
                                                    '& .MuiInputBase-root': {
                                                        height: '5%'  // Adjust this value as needed
                                                    }
                                                }}
                                                className=""
                                                onClick={handlePrint}
                                            >
                                                Print
                                            </Button>

                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </form>

                    </Grid>

                </Grid>

            </PageTitleWrapper>

            <Grid
                item
                xs={11}
                sx={{
                    mx: '10mm', // Margin left and right
                }}
            >
                <Paper>

                    <TableContainer style={{ height: "500px", overflowY: "auto" }}>
                        <div ref={tableRef}>
                            {/* <div className="printable-content" style={{ padding: '20px',width: '100%' }}>
                                <div className="printable-content" style={{ padding: '20px',textAlign:'center' }}>
                                    <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={require('../assets/logosaarc.png')}
                                            alt="Logo"
                                            style={{ width: '50px', height: '50px', marginRight: '20px' }}
                                        />
                                        <h1 style={{ margin: 0 }}>Muster Roll Report</h1>
                                    </header>
                                </div>
                            </div> */}

                            <Table stickyHeader>
                                
                                <TableHead className="Heading">
                                    <TableRow className="headlogo">
                                        <TableCell colSpan={45} style={{textAlign: 'center'}}><img 
                                            src={require('../assets/logosaarc.png')}
                                            alt="Logo"
                                            style={{ width: '50px', height: '50px', marginRight: '20px',float: 'left' }}
                                        />
                                        <h4 style={{ margin: 0 }}><br></br>SAARC TOOL TECH PRIVATE LIMITED</h4>
                                     </TableCell>

                                 

                                    </TableRow>
                                    <TableRow  className="headtitle">
                                        

                                    <TableCell colSpan={45} style={{textAlign: 'center'}}>
                                            <h4 style={{ margin: 0 }}>MUSTER ROLL & REGISTER OF COMPENSATORY HOLIDAYS – {months.find((x) => x.value === formData2.month)?.label} {formData2.year}</h4>
                                            </TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>S.No</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Name</TableCell>
                                        {tableHeaders.map((header, index) => (
                                            header === "Names" || header === "ID" ?
                                                null
                                                : <TableCell key={index}>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {/* <TableHead>
                                    
                                </TableHead> */}

                                <TableBody>
                                    {formData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.ID}</TableCell>
                                            <TableCell>{row.Names}</TableCell>
                                            {tableHeaders.map((header, i) => (
                                                header === "Names" || header === "ID" ?
                                                    null
                                                    : <TableCell key={i}>{row[header]}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>

                </Paper>
            </Grid>
        </>
    );
};

export default MusterReport;
